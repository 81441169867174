import { FallbackComponent } from "@/components/FallbackComponent";
import { LoadingAccess } from "@/components/LoadingAccess";
import { Unauthorized } from "@/components/Unauthorized";
import { useMetricsReports } from "@/hooks/usePages";
import reactLazyWithRetry from "@fatso83/retry-dynamic-import/react-lazy";
import Skeleton from "@mui/material/Skeleton";
import { ErrorBoundary } from "@sentry/react";
import { Suspense, StrictMode } from "react";

const Search = reactLazyWithRetry(async () => {
  return {
    default: (await import("@/components/MetricsReports/Search")).Search,
  };
});

const Report = reactLazyWithRetry(async () => {
  return {
    default: (await import("@/components/MetricsReports/Report")).Report,
  };
});

const Chart = reactLazyWithRetry(async () => {
  return {
    default: (await import("@/components/MetricsReports/Chart")).Chart,
  };
});
export function Component() {
  const {
    loadingData,
    loadingSelectsReports,
    visualizeButtonCounter,
    hasViewPermission,
    loadingPermission,
  } = useMetricsReports();

  if (loadingPermission) {
    return <LoadingAccess />;
  }

  if (!hasViewPermission) {
    return <Unauthorized />;
  }

  return (
    <>
      <StrictMode>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "search");
          }}
          fallback={FallbackComponent}
        >
          <Suspense fallback={<Skeleton height={91} />}>
            {!loadingSelectsReports && <Search />}
          </Suspense>
        </ErrorBoundary>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "report");
          }}
          fallback={FallbackComponent}
        >
          <Suspense fallback={<Skeleton height={283} />}>
            {visualizeButtonCounter > 0 && !loadingData && <Report />}
          </Suspense>
        </ErrorBoundary>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "chart");
          }}
          fallback={FallbackComponent}
        >
          <Suspense fallback={<Skeleton height={918} />}>
            {visualizeButtonCounter > 0 && !loadingData && <Chart />}
          </Suspense>
        </ErrorBoundary>
      </StrictMode>
    </>
  );
}

Component.displayName = "MetricsReports";
