import { FallbackComponent } from "@/components/FallbackComponent";
import { LoadingAccess } from "@/components/LoadingAccess";
import { Unauthorized } from "@/components/Unauthorized";
import { useFormatMaintenace } from "@/hooks/usePages";
import reactLazyWithRetry from "@fatso83/retry-dynamic-import/react-lazy";
import Modal from "@mui/material/Modal";
import Skeleton from "@mui/material/Skeleton";
import { ErrorBoundary } from "@sentry/react";
import { Suspense, StrictMode } from "react";

const DynamicFieldData = reactLazyWithRetry(async () => {
  return {
    default: (await import("@/components/DynamicDataFields")).DynamicDataFields,
  };
});
const Select = reactLazyWithRetry(async () => {
  return {
    default: (await import("@/components/FormatMaintenance/Select")).Select,
  };
});

const Details = reactLazyWithRetry(async () => {
  return {
    default: (await import("@/components/FormatMaintenance/Details")).Details,
  };
});

const Modification = reactLazyWithRetry(async () => {
  return {
    default: (await import("@/components/FormatMaintenance/Modification"))
      .Modification,
  };
});

const DownloadFileSelect = reactLazyWithRetry(async () => {
  return {
    default: (await import("@/components/FormatMaintenance/DownloadFileSelect"))
      .DownloadFileSelect,
  };
});

export const Component = () => {
  const {
    selectedChannel,
    showDynamicFieldDataGrid,
    hasViewPermission,
    loadingPermission,
  } = useFormatMaintenace();

  if (loadingPermission) {
    return <LoadingAccess />;
  }

  if (!hasViewPermission) {
    return <Unauthorized />;
  }

  return (
    <>
      <StrictMode>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "select");
          }}
          fallback={FallbackComponent}
        >
          <Suspense fallback={<Skeleton width="100%" height={90} />}>
            <Select />
          </Suspense>
        </ErrorBoundary>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "details");
          }}
          fallback={FallbackComponent}
        >
          <Suspense fallback={<Skeleton width="100%" height={283} />}>
            {selectedChannel !== "" && <Details />}
          </Suspense>
        </ErrorBoundary>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "modification");
          }}
          fallback={FallbackComponent}
        >
          <Suspense fallback={<Skeleton width="100%" height={555} />}>
            {selectedChannel !== "" && <Modification />}
          </Suspense>
        </ErrorBoundary>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "dynamicfielddata");
          }}
          fallback={FallbackComponent}
        >
          <Suspense
            fallback={
              <Modal open={true} sx={{ mx: 2 }}>
                <Skeleton
                  height={280}
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: {
                      xs: "calc(100% - 32px)",
                      md: "100%",
                    },
                    maxWidth: 671,
                  }}
                />
              </Modal>
            }
          >
            {showDynamicFieldDataGrid && <DynamicFieldData />}
          </Suspense>
        </ErrorBoundary>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "downloadfileselect");
          }}
          fallback={FallbackComponent}
        >
          <Suspense fallback={<Skeleton width="100%" height={90} />}>
            <DownloadFileSelect />
          </Suspense>
        </ErrorBoundary>
      </StrictMode>
    </>
  );
};

Component.displayName = "FormatMaintenance";
