import { FallbackComponent } from "@/components/FallbackComponent";
import { LoadingAccess } from "@/components/LoadingAccess";
import { Unauthorized } from "@/components/Unauthorized";
import { useCatalogMaintenance } from "@/hooks/usePages";
import type { CatalogsWithAction } from "@/types/index";
import { StatusType } from "@/types/requests";
import reactLazyWithRetry from "@fatso83/retry-dynamic-import/react-lazy";
import { zodResolver } from "@hookform/resolvers/zod";
import Skeleton from "@mui/material/Skeleton";
import { ErrorBoundary } from "@sentry/react";
import { Suspense } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";

const Select = reactLazyWithRetry(async () => {
  return {
    default: (await import("./components/Select")).Select,
  };
});

const CatalogDetails = reactLazyWithRetry(async () => {
  return {
    default: (await import("./components/CatalogDetails")).CatalogDetails,
  };
});

const CatalogManipulation = reactLazyWithRetry(async () => {
  return {
    default: (await import("./components/CatalogManipulation"))
      .CatalogManipulation,
  };
});

export const schema = z.object({
  sequential: z.number().int().nonnegative(),
  type: z.string().max(50),
  relation: z.number().nonnegative(),
  description: z.string().min(1).max(255),
  status: z.string().min(1).max(50),
  action: z.enum(["add", "edit", "delete"]),
});

export const Component = () => {
  const { selectedFilter, loadingPermission, hasViewPermission } =
    useCatalogMaintenance();
  const methods = useForm<CatalogsWithAction>({
    defaultValues: {
      sequential: 0,
      type: "",
      relation: 0,
      description: "",
      status: StatusType.INACTIVO,
      action: "add",
    },
    resolver: zodResolver(schema),
  });

  if (loadingPermission) {
    return <LoadingAccess />;
  }

  if (!hasViewPermission) {
    return <Unauthorized />;
  }

  return (
    <>
      <ErrorBoundary
        beforeCapture={(scope) => {
          scope.setTag("component", "select");
        }}
        fallback={FallbackComponent}
      >
        <Suspense
          fallback={<Skeleton variant="rounded" width="100%" height={90} />}
        >
          <Select />
        </Suspense>
      </ErrorBoundary>
      <FormProvider {...methods}>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "catalogdetails");
          }}
          fallback={FallbackComponent}
        >
          <Suspense
            fallback={<Skeleton variant="rounded" width="100%" height={125} />}
          >
            {selectedFilter !== "" && <CatalogDetails />}
          </Suspense>
        </ErrorBoundary>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "CatalogManipulation");
          }}
          fallback={FallbackComponent}
        >
          <Suspense
            fallback={<Skeleton variant="rounded" width="100%" height={175} />}
          >
            {selectedFilter !== "" && <CatalogManipulation />}
          </Suspense>
        </ErrorBoundary>
      </FormProvider>
    </>
  );
};

Component.displayName = "CatalogsMaintenance";
