import { apiPrefix, apiRoute } from "@/config/api";

export const sessionInfoUrl = () =>
  `${apiPrefix}${apiRoute}Session/SessionInfo`; //TODO: Replace
export const validateOtpUrl = () =>
  `${apiPrefix}${apiRoute}Verification/ValidateOTP`;
export const generateOtpUrl = () =>
  `${apiPrefix}${apiRoute}Verification/GenerateOTP`;

export const getClientPortfolioUrl = () =>
  `${apiPrefix}${apiRoute}reportes/GetClientPortfolio`; //TODO: Replace

export const searchClientFinderUrl = () =>
  `${apiPrefix}${apiRoute}GestionWeb/PostBusqueda`; //TODO: Replace
export const assignExecutiveUrl = () =>
  `${apiPrefix}${apiRoute}gestionweb/PostAssignExecutive`; //TODO: Replace

export const postClientDetailsUrl = () =>
  `${apiPrefix}${apiRoute}gestionweb/PostDatosCliente`; //TODO: Replace

export const contactDetailsUrl = () => `${apiPrefix}${apiRoute}ContactDetails`;

export const personalReferencesUrl = () =>
  `${apiPrefix}${apiRoute}PersonalReferences`;

export const demographicDataUrl = () =>
  `${apiPrefix}${apiRoute}DemographicData`;

export const employmentDataUrl = () => `${apiPrefix}${apiRoute}EmploymentData`;

export const postContractDetailsUrl = () =>
  `${apiPrefix}${apiRoute}gestionweb/PostContractDetails`; //TODO: Replace
export const postContractDataUrl = () =>
  `${apiPrefix}${apiRoute}gestionweb/PostDatosContract`; //TODO: Replace

export const managementUrl = () => `${apiPrefix}${apiRoute}Management`;

export const promisesUrl = () => `${apiPrefix}${apiRoute}Promises`;

export const paymentAgreementsUrl = () =>
  `${apiPrefix}${apiRoute}PaymentAgreements`;

export const postPaymentsUrl = () =>
  `${apiPrefix}${apiRoute}gestionweb/PostPayments`; //TODO: Replace

export const guaranteesUrl = () => `${apiPrefix}${apiRoute}Guarantees`;
export const guaranteeOwnersUrl = () =>
  `${apiPrefix}${apiRoute}GuaranteeOwners`;

export const searchStrategiesUrl = () =>
  `${apiPrefix}${apiRoute}gestionweb/PostSearchStrategies`; //TODO: Replace
export const assignStrategiesUrl = () =>
  `${apiPrefix}${apiRoute}gestionweb/PostAssignStrategies`; //TODO: Replace
export const postPromisesExecutiveUrl = () =>
  `${apiPrefix}${apiRoute}gestionweb/PostPromisesExecutive`; //TODO: Replace
export const postManagementExecutiveUrl = () =>
  `${apiPrefix}${apiRoute}gestionweb/PostManagementExecutive`; //TODO: Replace
export const postAmountExecutiveUrl = () =>
  `${apiPrefix}${apiRoute}gestionweb/PostAmountExecutive`; //TODO: Replace

export const searchStrategyListUrl = () =>
  `${apiPrefix}${apiRoute}gestionweb/PostSearchStrategyList`; //TODO: Replace
export const searchInformationStrategyListUrl = () =>
  `${apiPrefix}${apiRoute}gestionweb/PostSearchInformacionStrategyList`; //TODO: Replace

export const postMarkYesClienteUrl = () =>
  `${apiPrefix}${apiRoute}gestionweb/PostMarkYesCliente`; //TODO: Replace
export const postMarkNoClienteUrl = () =>
  `${apiPrefix}${apiRoute}gestionweb/PostMarkNoCliente`; //TODO: Replace

export const strategiesUrl = () => `${apiPrefix}${apiRoute}Strategies`;

export const postDynamicFieldDataUrl = () =>
  `${apiPrefix}${apiRoute}gestionweb/PostFieldsDinamicosData`; //TODO: Replace

export const formatsUrl = () => `${apiPrefix}${apiRoute}Formats`;

export const reschedulesUrl = () => `${apiPrefix}${apiRoute}Reschedules`;

export const postDebtDistributionUrl = () =>
  `${apiPrefix}${apiRoute}reportes/PostDebtDistribution`; //TODO: Replace

export const catalogsUrl = () => `${apiPrefix}${apiRoute}Catalogs`;

export const usersUrl = () => `${apiPrefix}${apiRoute}Users`;

export const groupsUrl = () => `${apiPrefix}${apiRoute}Groups`;

export const getSelectsMetricsReportUrl = () =>
  `${apiPrefix}${apiRoute}gestionweb/GetSelectsMetricsReport`; //TODO: Replace
export const postMetricsReportUrl = () =>
  `${apiPrefix}${apiRoute}reportes/PostMetricsReport`; //TODO: Replace

export const postMetricsDashboardCardsUrl = () =>
  `${apiPrefix}${apiRoute}reportes/PostMetricsDashboardCards`; //TODO: Replace
export const postMetricsDashboardGraphsUrl = () =>
  `${apiPrefix}${apiRoute}reportes/PostMetricsDashboardGraphs`; //TODO: Replace
export const getMetricsDashboardActiveStrategiesUrl = () =>
  `${apiPrefix}${apiRoute}reportes/GetMetricsDashboardActiveStrategies`; //TODO: Replace

export const addFlowUrl = () => `${apiPrefix}${apiRoute}Flujos/AddFlow`; //TODO: Replace
export const postFlujosUrl = () => `${apiPrefix}${apiRoute}Flujos/PostFlujos`; //TODO: Replace
export const postCompletedStagesUrl = () =>
  `${apiPrefix}${apiRoute}Flujos/PostCompletedStages`; //TODO: Replace

export const operatingFlowsUrl = () => `${apiPrefix}${apiRoute}OperatingFlows`;

export const flowDocumentsUrl = () => `${apiPrefix}${apiRoute}FlowDocuments`;

export const flowStagesUrl = () => `${apiPrefix}${apiRoute}FlowStages`;

export const postFlowContractDetailsUrl = () =>
  `${apiPrefix}${apiRoute}Flujos/PostFlowContractDetails`; //TODO: Replace
export const postFlowsByStateUrl = () =>
  `${apiPrefix}${apiRoute}Flujos/PostFlowsByState`; //TODO: Replace
export const postFlowListUrl = () =>
  `${apiPrefix}${apiRoute}Flujos/PostFlowList`; //TODO: Replace
export const assignFlowUrl = () => `${apiPrefix}${apiRoute}Flujos/AssignFlow`; //TODO: Replace

export const postStageObservationsUrl = () =>
  `${apiPrefix}${apiRoute}Flujos/PostStageObservations`; //TODO: Replace

export const postClientDataUrl = () =>
  `${apiPrefix}${apiRoute}gestionweb/PostClientData`; //TODO: Replace
export const postStagesOptionsUrl = () =>
  `${apiPrefix}${apiRoute}Flujos/PostStagesOptions`; //TODO: Replace
export const postFlowDataUrl = () =>
  `${apiPrefix}${apiRoute}Flujos/PostFlowData`; //TODO: Replace
export const addFlowDataUrl = () => `${apiPrefix}${apiRoute}Flujos/AddFlowData`; //TODO: Replace
export const changeStageUrl = () => `${apiPrefix}${apiRoute}Flujos/ChangeStage`; //TODO: Replace
export const endFlowUrl = () => `${apiPrefix}${apiRoute}Flujos/EndFlow`; //TODO: Replace

export const attachedFilesUrl = () => `${apiPrefix}${apiRoute}AttachedFiles`;

export const strategyFormatsUrl = () =>
  `${apiPrefix}${apiRoute}StrategyFormats`;
export const postAssignedClientUrl = () =>
  `${apiPrefix}${apiRoute}gestionweb/PostAssignedClient`; //TODO: Replace

export const fileUploadUrl = () => `${apiPrefix}${apiRoute}File/Upload`;

export const postImportUrl = () => `${apiPrefix}${apiRoute}Import`;

export const postAssignListsUrl = () =>
  `${apiPrefix}${apiRoute}gestionweb/PostAssignLists`; //TODO: Replace
export const postDeleteRecurrentListUrl = () =>
  `${apiPrefix}${apiRoute}gestionweb/PostDeleteRecurrentList`; //TODO: Replace
export const postDeleteListAssignmentUrl = () =>
  `${apiPrefix}${apiRoute}gestionweb/PostDeleteListAssignment`; //TODO: Replace
export const postActiveListsUrl = () =>
  `${apiPrefix}${apiRoute}gestionweb/PostActiveLists`; //TODO: Replace
export const postRecurrentListsUrl = () =>
  `${apiPrefix}${apiRoute}gestionweb/PostRecurrentLists`; //TODO: Replace
export const postExecutivesDistributionUrl = () =>
  `${apiPrefix}${apiRoute}gestionweb/PostExecutivesDistribution`; //TODO: Replace

export const rolesUrl = () => `${apiPrefix}${apiRoute}Roles`;
export const rolesPermissionsUrl = () =>
  `${apiPrefix}${apiRoute}RolesPermissions`;

export const urlsUrl = () => `${apiPrefix}${apiRoute}Urls`;

export const orderItemsUrl = () => `${apiPrefix}${apiRoute}OrderItems`;

export const postClientStrategyLetterCodeUrl = () =>
  `${apiPrefix}${apiRoute}gestionweb/PostClientStrategyLetterCode`; //TODO: Replace

export const generateLetterUrl = () => `${apiPrefix}${apiRoute}GenerateLetter`;

export const quotesUrl = () => `${apiPrefix}${apiRoute}Quotes`;

export const articlesUrl = () => `${apiPrefix}${apiRoute}Articles`;

export const monthlyPaymentsUrl = () =>
  `${apiPrefix}${apiRoute}MonthlyPayments`;

export const addendumUrl = () => `${apiPrefix}${apiRoute}PersonsAddendum`;

export const explanationsUrl = () => `${apiPrefix}${apiRoute}OtherPersons`;

export const liabilitiesUrl = () => `${apiPrefix}${apiRoute}Liabilities`;

export const accessUrl = () => `${apiPrefix}${apiRoute}Access`;

export const accountStatementsUrl = () =>
  `${apiPrefix}${apiRoute}AccountStatement`;

export const previewSegmentationUrl = () =>
  `${apiPrefix}${apiRoute}PreviewSegmentation`;
