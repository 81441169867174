import { FallbackComponent } from "@/components/FallbackComponent";
import { LoadingAccess } from "@/components/LoadingAccess";
import { Unauthorized } from "@/components/Unauthorized";
import { useCreateFlow } from "@/hooks/usePages";
import reactLazyWithRetry from "@fatso83/retry-dynamic-import/react-lazy";
import Skeleton from "@mui/material/Skeleton";
import { ErrorBoundary } from "@sentry/react";
import { Suspense, StrictMode } from "react";

const Search = reactLazyWithRetry(async () => {
  return {
    default: (await import("@/components/CreateFlow/Search")).Search,
  };
});

const SearchResults = reactLazyWithRetry(async () => {
  return {
    default: (await import("@/components/CreateFlow/SearchResults"))
      .SearchResults,
  };
});

const SelectedFlow = reactLazyWithRetry(async () => {
  return {
    default: (await import("@/components/CreateFlow/SelectedFlow"))
      .SelectedFlow,
  };
});

const FlowDetails = reactLazyWithRetry(async () => {
  return {
    default: (await import("@/components/CreateFlow/FlowDetails")).FlowDetails,
  };
});

const CompletedStages = reactLazyWithRetry(async () => {
  return {
    default: (await import("@/components/CreateFlow/CompletedStages"))
      .CompletedStages,
  };
});
export function Component() {
  const {
    selectedFlowDetailRow,
    selectedContractRow,
    contracts,
    hasViewPermission,
    loadingPermission,
  } = useCreateFlow();

  if (loadingPermission) {
    return <LoadingAccess />;
  }

  if (!hasViewPermission) {
    return <Unauthorized />;
  }
  return (
    <>
      <StrictMode>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "search");
          }}
          fallback={FallbackComponent}
        >
          <Suspense fallback={<Skeleton height={90} />}>
            <Search />
          </Suspense>
        </ErrorBoundary>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "searchresults");
          }}
          fallback={FallbackComponent}
        >
          <Suspense fallback={<Skeleton height={342} />}>
            {contracts.length > 0 && <SearchResults />}
          </Suspense>
        </ErrorBoundary>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "selectedflow");
          }}
          fallback={FallbackComponent}
        >
          <Suspense fallback={<Skeleton height={91} />}>
            {selectedContractRow.cliente !== "" && <SelectedFlow />}
          </Suspense>
        </ErrorBoundary>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "flowdetails");
          }}
          fallback={FallbackComponent}
        >
          <Suspense fallback={<Skeleton height={342} />}>
            {selectedContractRow.cliente !== "" && <FlowDetails />}
          </Suspense>
        </ErrorBoundary>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "completedstages");
          }}
          fallback={FallbackComponent}
        >
          <Suspense fallback={<Skeleton height={342} />}>
            {selectedFlowDetailRow.codigoFlujo !== "" && <CompletedStages />}
          </Suspense>
        </ErrorBoundary>
      </StrictMode>
    </>
  );
}

Component.displayName = "CreateFlow";
