// generated with @7nohe/openapi-react-query-codegen@1.1.0 
import { useQuery, useSuspenseQuery, useMutation, UseQueryResult, UseQueryOptions, UseMutationOptions, UseMutationResult } from "@tanstack/react-query";
import { AppVariablesService, ArticlesService, AttachedFilesService, CatalogsService, ContactDetailsService, DemographicDataService, DynamicNamesService, EmploymentDataService, FieldsService, FlowDocumentsService, FlowStagesService, FormatsService, GenerateLetterService, GroupsService, GuaranteeOwnersService, GuaranteesService, HolidaysService, ImportService, LiabilitiesService, ManagementService, MonthlyPaymentsService, OperatingFlowsService, OrderItemsService, OtherPersonsService, PaymentAgreementsService, PersonalReferencesService, PersonsAddendumService, PromisesService, QuotesService, ReschedulesService, RolesService, RolesPermissionsService, StrategiesService, StrategyFormatsService, UrlsService, UsersService, AppVariablesData, ArticlesData, AttachedFilesData, CatalogsData, ContactDetailsData, DemographicDataData, DynamicNamesData, EmploymentDataData, FieldsData, FlowDocumentsData, FlowStagesData, FormatsData, GenerateLetterData, GroupsData, GuaranteeOwnersData, GuaranteesData, HolidaysData, ImportData, LiabilitiesData, ManagementData, MonthlyPaymentsData, OperatingFlowsData, OrderItemsData, OtherPersonsData, PaymentAgreementsData, PersonalReferencesData, PersonsAddendumData, PromisesData, QuotesData, ReschedulesData, RolesData, RolesPermissionsData, StrategiesData, StrategyFormatsData, UrlsData, UsersData } from "../requests";
import { AppVariable, AppVariableWithoutId, Article, AttachedFiles, AttachedFilesWithoutSequential, Catalogs, CatalogsWithoutSequential, ContactDetails, ContactDetailsWithoutSequential, DemographicData, DemographicDataWithoutSequential, DynamicNames, DynamicNamesWithoutSequential, EmploymentData, EmploymentDataWithoutSequential, Fields, FieldsWithoutSequential, FlowDocuments, FlowDocumentsWithoutSequential, FlowStages, FlowStagesWithoutSequential, Formats, FormatsWithoutSequential, GenerateLetterB, Groups, GroupsWithoutSequential, GuaranteeOwners, GuaranteeOwnersWithoutSequential, Guarantees, GuaranteesWithoutSequential, Holidays, HolidaysWithoutSequential, Import, Liabilities, LiabilitiesWithoutNumber, Management, ManagementWithoutSequential, MonthlyPayments, MonthlyPaymentsWithoutClientCode, OperatingFlows, OperatingFlowsWithoutSequential, OrderItems, OrderItemsWithoutOrderID, OtherPersons, OtherPersonsWithoutClientCode, ParamData, PaymentAgreements, PaymentAgreementsWithoutSequential, PersonalReferences, PersonalReferencesWithoutSequential, PersonsAddendum, PersonsAddendumWithoutClientCode, Promises, PromisesWithoutSequential, Quotes, QuotesWithoutSequential, Reschedules, ReschedulesWithoutSequential, Roles, RolesPermissions, RolesPermissionsWithoutSequential, RolesWithoutSequential, Strategies, StrategiesWithoutSequential, StrategyFormats, StrategyFormatsWithoutSequential, Urls, UrlsBody, UrlsBodyWithoutUrlID, Users, UsersWithoutSequential } from "../requests/models";
export type AppVariablesServiceGetAppVariablesDefaultResponse = Awaited<ReturnType<typeof AppVariablesService.getAppVariables>>;
export type AppVariablesServiceGetAppVariablesQueryResult<TData = AppVariablesServiceGetAppVariablesDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useAppVariablesServiceGetAppVariablesKey = "AppVariablesServiceGetAppVariables";
export type AppVariablesServiceGetAppVariablesByTypeAndUserIdDefaultResponse = Awaited<ReturnType<typeof AppVariablesService.getAppVariablesByTypeAndUserId>>;
export type AppVariablesServiceGetAppVariablesByTypeAndUserIdQueryResult<TData = AppVariablesServiceGetAppVariablesByTypeAndUserIdDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useAppVariablesServiceGetAppVariablesByTypeAndUserIdKey = "AppVariablesServiceGetAppVariablesByTypeAndUserId";
export type ArticlesServiceGetArticlesDefaultResponse = Awaited<ReturnType<typeof ArticlesService.getArticles>>;
export type ArticlesServiceGetArticlesQueryResult<TData = ArticlesServiceGetArticlesDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useArticlesServiceGetArticlesKey = "ArticlesServiceGetArticles";
export type AttachedFilesServiceGetAttachedFilesDefaultResponse = Awaited<ReturnType<typeof AttachedFilesService.getAttachedFiles>>;
export type AttachedFilesServiceGetAttachedFilesQueryResult<TData = AttachedFilesServiceGetAttachedFilesDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useAttachedFilesServiceGetAttachedFilesKey = "AttachedFilesServiceGetAttachedFiles";
export type CatalogsServiceGetCatalogsByStatusDefaultResponse = Awaited<ReturnType<typeof CatalogsService.getCatalogsByStatus>>;
export type CatalogsServiceGetCatalogsByStatusQueryResult<TData = CatalogsServiceGetCatalogsByStatusDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useCatalogsServiceGetCatalogsByStatusKey = "CatalogsServiceGetCatalogsByStatus";
export type CatalogsServiceGetCatalogsByDescriptionDefaultResponse = Awaited<ReturnType<typeof CatalogsService.getCatalogsByDescription>>;
export type CatalogsServiceGetCatalogsByDescriptionQueryResult<TData = CatalogsServiceGetCatalogsByDescriptionDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useCatalogsServiceGetCatalogsByDescriptionKey = "CatalogsServiceGetCatalogsByDescription";
export type CatalogsServiceGetCatalogsGroupByColumnDefaultResponse = Awaited<ReturnType<typeof CatalogsService.getCatalogsGroupByColumn>>;
export type CatalogsServiceGetCatalogsGroupByColumnQueryResult<TData = CatalogsServiceGetCatalogsGroupByColumnDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useCatalogsServiceGetCatalogsGroupByColumnKey = "CatalogsServiceGetCatalogsGroupByColumn";
export type CatalogsServiceGetCatalogsDefaultResponse = Awaited<ReturnType<typeof CatalogsService.getCatalogs>>;
export type CatalogsServiceGetCatalogsQueryResult<TData = CatalogsServiceGetCatalogsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useCatalogsServiceGetCatalogsKey = "CatalogsServiceGetCatalogs";
export type ContactDetailsServiceGetContactDetailsDefaultResponse = Awaited<ReturnType<typeof ContactDetailsService.getContactDetails>>;
export type ContactDetailsServiceGetContactDetailsQueryResult<TData = ContactDetailsServiceGetContactDetailsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useContactDetailsServiceGetContactDetailsKey = "ContactDetailsServiceGetContactDetails";
export type DemographicDataServiceGetDemographicDataDefaultResponse = Awaited<ReturnType<typeof DemographicDataService.getDemographicData>>;
export type DemographicDataServiceGetDemographicDataQueryResult<TData = DemographicDataServiceGetDemographicDataDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useDemographicDataServiceGetDemographicDataKey = "DemographicDataServiceGetDemographicData";
export type DynamicNamesServiceGetDynamicNamesDefaultResponse = Awaited<ReturnType<typeof DynamicNamesService.getDynamicNames>>;
export type DynamicNamesServiceGetDynamicNamesQueryResult<TData = DynamicNamesServiceGetDynamicNamesDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useDynamicNamesServiceGetDynamicNamesKey = "DynamicNamesServiceGetDynamicNames";
export type EmploymentDataServiceGetEmploymentDataDefaultResponse = Awaited<ReturnType<typeof EmploymentDataService.getEmploymentData>>;
export type EmploymentDataServiceGetEmploymentDataQueryResult<TData = EmploymentDataServiceGetEmploymentDataDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useEmploymentDataServiceGetEmploymentDataKey = "EmploymentDataServiceGetEmploymentData";
export type FieldsServiceGetFieldsDefaultResponse = Awaited<ReturnType<typeof FieldsService.getFields>>;
export type FieldsServiceGetFieldsQueryResult<TData = FieldsServiceGetFieldsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useFieldsServiceGetFieldsKey = "FieldsServiceGetFields";
export type FlowDocumentsServiceGetFlowDocumentsDefaultResponse = Awaited<ReturnType<typeof FlowDocumentsService.getFlowDocuments>>;
export type FlowDocumentsServiceGetFlowDocumentsQueryResult<TData = FlowDocumentsServiceGetFlowDocumentsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useFlowDocumentsServiceGetFlowDocumentsKey = "FlowDocumentsServiceGetFlowDocuments";
export type FlowStagesServiceGetFlowStagesDefaultResponse = Awaited<ReturnType<typeof FlowStagesService.getFlowStages>>;
export type FlowStagesServiceGetFlowStagesQueryResult<TData = FlowStagesServiceGetFlowStagesDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useFlowStagesServiceGetFlowStagesKey = "FlowStagesServiceGetFlowStages";
export type FormatsServiceGetFormatsDefaultResponse = Awaited<ReturnType<typeof FormatsService.getFormats>>;
export type FormatsServiceGetFormatsQueryResult<TData = FormatsServiceGetFormatsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useFormatsServiceGetFormatsKey = "FormatsServiceGetFormats";
export type FormatsServiceGetFormatsByChannelDefaultResponse = Awaited<ReturnType<typeof FormatsService.getFormatsByChannel>>;
export type FormatsServiceGetFormatsByChannelQueryResult<TData = FormatsServiceGetFormatsByChannelDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useFormatsServiceGetFormatsByChannelKey = "FormatsServiceGetFormatsByChannel";
export type GroupsServiceGetGroupsDefaultResponse = Awaited<ReturnType<typeof GroupsService.getGroups>>;
export type GroupsServiceGetGroupsQueryResult<TData = GroupsServiceGetGroupsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useGroupsServiceGetGroupsKey = "GroupsServiceGetGroups";
export type GuaranteeOwnersServiceGetGuaranteeOwnersDefaultResponse = Awaited<ReturnType<typeof GuaranteeOwnersService.getGuaranteeOwners>>;
export type GuaranteeOwnersServiceGetGuaranteeOwnersQueryResult<TData = GuaranteeOwnersServiceGetGuaranteeOwnersDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useGuaranteeOwnersServiceGetGuaranteeOwnersKey = "GuaranteeOwnersServiceGetGuaranteeOwners";
export type GuaranteesServiceGetGuaranteesDefaultResponse = Awaited<ReturnType<typeof GuaranteesService.getGuarantees>>;
export type GuaranteesServiceGetGuaranteesQueryResult<TData = GuaranteesServiceGetGuaranteesDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useGuaranteesServiceGetGuaranteesKey = "GuaranteesServiceGetGuarantees";
export type HolidaysServiceGetHolidaysDefaultResponse = Awaited<ReturnType<typeof HolidaysService.getHolidays>>;
export type HolidaysServiceGetHolidaysQueryResult<TData = HolidaysServiceGetHolidaysDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useHolidaysServiceGetHolidaysKey = "HolidaysServiceGetHolidays";
export type LiabilitiesServiceGetLiabilitiesDefaultResponse = Awaited<ReturnType<typeof LiabilitiesService.getLiabilities>>;
export type LiabilitiesServiceGetLiabilitiesQueryResult<TData = LiabilitiesServiceGetLiabilitiesDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useLiabilitiesServiceGetLiabilitiesKey = "LiabilitiesServiceGetLiabilities";
export type ManagementServiceGetManagementDetailsDefaultResponse = Awaited<ReturnType<typeof ManagementService.getManagementDetails>>;
export type ManagementServiceGetManagementDetailsQueryResult<TData = ManagementServiceGetManagementDetailsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useManagementServiceGetManagementDetailsKey = "ManagementServiceGetManagementDetails";
export type MonthlyPaymentsServiceGetMonthlyPaymentsDefaultResponse = Awaited<ReturnType<typeof MonthlyPaymentsService.getMonthlyPayments>>;
export type MonthlyPaymentsServiceGetMonthlyPaymentsQueryResult<TData = MonthlyPaymentsServiceGetMonthlyPaymentsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useMonthlyPaymentsServiceGetMonthlyPaymentsKey = "MonthlyPaymentsServiceGetMonthlyPayments";
export type OperatingFlowsServiceGetOperatingFlowsDefaultResponse = Awaited<ReturnType<typeof OperatingFlowsService.getOperatingFlows>>;
export type OperatingFlowsServiceGetOperatingFlowsQueryResult<TData = OperatingFlowsServiceGetOperatingFlowsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useOperatingFlowsServiceGetOperatingFlowsKey = "OperatingFlowsServiceGetOperatingFlows";
export type OperatingFlowsServiceGetOperatingFlowsByFlowCodeDefaultResponse = Awaited<ReturnType<typeof OperatingFlowsService.getOperatingFlowsByFlowCode>>;
export type OperatingFlowsServiceGetOperatingFlowsByFlowCodeQueryResult<TData = OperatingFlowsServiceGetOperatingFlowsByFlowCodeDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useOperatingFlowsServiceGetOperatingFlowsByFlowCodeKey = "OperatingFlowsServiceGetOperatingFlowsByFlowCode";
export type OrderItemsServiceGetAllOrderItemsDefaultResponse = Awaited<ReturnType<typeof OrderItemsService.getAllOrderItems>>;
export type OrderItemsServiceGetAllOrderItemsQueryResult<TData = OrderItemsServiceGetAllOrderItemsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useOrderItemsServiceGetAllOrderItemsKey = "OrderItemsServiceGetAllOrderItems";
export type OrderItemsServiceGetOrderItemsByClientDefaultResponse = Awaited<ReturnType<typeof OrderItemsService.getOrderItemsByClient>>;
export type OrderItemsServiceGetOrderItemsByClientQueryResult<TData = OrderItemsServiceGetOrderItemsByClientDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useOrderItemsServiceGetOrderItemsByClientKey = "OrderItemsServiceGetOrderItemsByClient";
export type OtherPersonsServiceGetOtherPersonsDefaultResponse = Awaited<ReturnType<typeof OtherPersonsService.getOtherPersons>>;
export type OtherPersonsServiceGetOtherPersonsQueryResult<TData = OtherPersonsServiceGetOtherPersonsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useOtherPersonsServiceGetOtherPersonsKey = "OtherPersonsServiceGetOtherPersons";
export type PaymentAgreementsServiceGetPaymentAgreementsDefaultResponse = Awaited<ReturnType<typeof PaymentAgreementsService.getPaymentAgreements>>;
export type PaymentAgreementsServiceGetPaymentAgreementsQueryResult<TData = PaymentAgreementsServiceGetPaymentAgreementsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const usePaymentAgreementsServiceGetPaymentAgreementsKey = "PaymentAgreementsServiceGetPaymentAgreements";
export type PersonalReferencesServiceGetPersonalReferencesDefaultResponse = Awaited<ReturnType<typeof PersonalReferencesService.getPersonalReferences>>;
export type PersonalReferencesServiceGetPersonalReferencesQueryResult<TData = PersonalReferencesServiceGetPersonalReferencesDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const usePersonalReferencesServiceGetPersonalReferencesKey = "PersonalReferencesServiceGetPersonalReferences";
export type PersonsAddendumServiceGetPersonsAddendumDefaultResponse = Awaited<ReturnType<typeof PersonsAddendumService.getPersonsAddendum>>;
export type PersonsAddendumServiceGetPersonsAddendumQueryResult<TData = PersonsAddendumServiceGetPersonsAddendumDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const usePersonsAddendumServiceGetPersonsAddendumKey = "PersonsAddendumServiceGetPersonsAddendum";
export type PromisesServiceGetPromisesDefaultResponse = Awaited<ReturnType<typeof PromisesService.getPromises>>;
export type PromisesServiceGetPromisesQueryResult<TData = PromisesServiceGetPromisesDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const usePromisesServiceGetPromisesKey = "PromisesServiceGetPromises";
export type QuotesServiceGetQuotesDefaultResponse = Awaited<ReturnType<typeof QuotesService.getQuotes>>;
export type QuotesServiceGetQuotesQueryResult<TData = QuotesServiceGetQuotesDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useQuotesServiceGetQuotesKey = "QuotesServiceGetQuotes";
export type QuotesServiceGetQuotesByClientContractDefaultResponse = Awaited<ReturnType<typeof QuotesService.getQuotesByClientContract>>;
export type QuotesServiceGetQuotesByClientContractQueryResult<TData = QuotesServiceGetQuotesByClientContractDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useQuotesServiceGetQuotesByClientContractKey = "QuotesServiceGetQuotesByClientContract";
export type ReschedulesServiceGetReschedulesDefaultResponse = Awaited<ReturnType<typeof ReschedulesService.getReschedules>>;
export type ReschedulesServiceGetReschedulesQueryResult<TData = ReschedulesServiceGetReschedulesDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useReschedulesServiceGetReschedulesKey = "ReschedulesServiceGetReschedules";
export type RolesServiceGetAllRolesDefaultResponse = Awaited<ReturnType<typeof RolesService.getAllRoles>>;
export type RolesServiceGetAllRolesQueryResult<TData = RolesServiceGetAllRolesDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useRolesServiceGetAllRolesKey = "RolesServiceGetAllRoles";
export type RolesPermissionsServiceGetPermissionsByRoleDefaultResponse = Awaited<ReturnType<typeof RolesPermissionsService.getPermissionsByRole>>;
export type RolesPermissionsServiceGetPermissionsByRoleQueryResult<TData = RolesPermissionsServiceGetPermissionsByRoleDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useRolesPermissionsServiceGetPermissionsByRoleKey = "RolesPermissionsServiceGetPermissionsByRole";
export type StrategiesServiceGetStrategiesDefaultResponse = Awaited<ReturnType<typeof StrategiesService.getStrategies>>;
export type StrategiesServiceGetStrategiesQueryResult<TData = StrategiesServiceGetStrategiesDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useStrategiesServiceGetStrategiesKey = "StrategiesServiceGetStrategies";
export type StrategyFormatsServiceGetStrategyFormatsDefaultResponse = Awaited<ReturnType<typeof StrategyFormatsService.getStrategyFormats>>;
export type StrategyFormatsServiceGetStrategyFormatsQueryResult<TData = StrategyFormatsServiceGetStrategyFormatsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useStrategyFormatsServiceGetStrategyFormatsKey = "StrategyFormatsServiceGetStrategyFormats";
export type UrlsServiceGetUrlsDefaultResponse = Awaited<ReturnType<typeof UrlsService.getUrls>>;
export type UrlsServiceGetUrlsQueryResult<TData = UrlsServiceGetUrlsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useUrlsServiceGetUrlsKey = "UrlsServiceGetUrls";
export type UsersServiceGetAllUsersDefaultResponse = Awaited<ReturnType<typeof UsersService.getAllUsers>>;
export type UsersServiceGetAllUsersQueryResult<TData = UsersServiceGetAllUsersDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useUsersServiceGetAllUsersKey = "UsersServiceGetAllUsers";
export type UsersServiceGetUsersByRoleDefaultResponse = Awaited<ReturnType<typeof UsersService.getUsersByRole>>;
export type UsersServiceGetUsersByRoleQueryResult<TData = UsersServiceGetUsersByRoleDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useUsersServiceGetUsersByRoleKey = "UsersServiceGetUsersByRole";
export type AppVariablesServicePostAppVariableMutationResult = Awaited<ReturnType<typeof AppVariablesService.postAppVariable>>;
export type ArticlesServicePostArticleMutationResult = Awaited<ReturnType<typeof ArticlesService.postArticle>>;
export type AttachedFilesServicePostAttachedFileMutationResult = Awaited<ReturnType<typeof AttachedFilesService.postAttachedFile>>;
export type CatalogsServicePostCatalogsMutationResult = Awaited<ReturnType<typeof CatalogsService.postCatalogs>>;
export type ContactDetailsServicePostContactDetailsMutationResult = Awaited<ReturnType<typeof ContactDetailsService.postContactDetails>>;
export type DemographicDataServicePostDemographicDataMutationResult = Awaited<ReturnType<typeof DemographicDataService.postDemographicData>>;
export type DynamicNamesServicePostDynamicNamesMutationResult = Awaited<ReturnType<typeof DynamicNamesService.postDynamicNames>>;
export type EmploymentDataServicePostEmploymentDataMutationResult = Awaited<ReturnType<typeof EmploymentDataService.postEmploymentData>>;
export type FlowDocumentsServicePostFlowDocumentsMutationResult = Awaited<ReturnType<typeof FlowDocumentsService.postFlowDocuments>>;
export type FlowStagesServicePostFlowStagesMutationResult = Awaited<ReturnType<typeof FlowStagesService.postFlowStages>>;
export type FormatsServicePostFormatsMutationResult = Awaited<ReturnType<typeof FormatsService.postFormats>>;
export type GenerateLetterServicePostGenerateLetterMutationResult = Awaited<ReturnType<typeof GenerateLetterService.postGenerateLetter>>;
export type GroupsServicePostGroupsMutationResult = Awaited<ReturnType<typeof GroupsService.postGroups>>;
export type GuaranteeOwnersServicePostGuaranteeOwnersMutationResult = Awaited<ReturnType<typeof GuaranteeOwnersService.postGuaranteeOwners>>;
export type GuaranteesServicePostGuaranteesMutationResult = Awaited<ReturnType<typeof GuaranteesService.postGuarantees>>;
export type HolidaysServicePostHolidaysMutationResult = Awaited<ReturnType<typeof HolidaysService.postHolidays>>;
export type ImportServicePostImportMutationResult = Awaited<ReturnType<typeof ImportService.postImport>>;
export type LiabilitiesServicePostLiabilitiesMutationResult = Awaited<ReturnType<typeof LiabilitiesService.postLiabilities>>;
export type ManagementServicePostManagementDetailsMutationResult = Awaited<ReturnType<typeof ManagementService.postManagementDetails>>;
export type MonthlyPaymentsServicePostMonthlyPaymentsMutationResult = Awaited<ReturnType<typeof MonthlyPaymentsService.postMonthlyPayments>>;
export type OperatingFlowsServicePostOperatingFlowsMutationResult = Awaited<ReturnType<typeof OperatingFlowsService.postOperatingFlows>>;
export type OrderItemsServicePostOrderItemsMutationResult = Awaited<ReturnType<typeof OrderItemsService.postOrderItems>>;
export type OtherPersonsServicePostOtherPersonsMutationResult = Awaited<ReturnType<typeof OtherPersonsService.postOtherPersons>>;
export type PaymentAgreementsServicePostPaymentAgreementMutationResult = Awaited<ReturnType<typeof PaymentAgreementsService.postPaymentAgreement>>;
export type PersonalReferencesServicePostPersonalReferencesMutationResult = Awaited<ReturnType<typeof PersonalReferencesService.postPersonalReferences>>;
export type PersonsAddendumServicePostPersonsAddendumMutationResult = Awaited<ReturnType<typeof PersonsAddendumService.postPersonsAddendum>>;
export type PromisesServiceCreatePromiseMutationResult = Awaited<ReturnType<typeof PromisesService.createPromise>>;
export type QuotesServicePostQuotesMutationResult = Awaited<ReturnType<typeof QuotesService.postQuotes>>;
export type ReschedulesServicePostReschedulesMutationResult = Awaited<ReturnType<typeof ReschedulesService.postReschedules>>;
export type RolesServicePostRoleMutationResult = Awaited<ReturnType<typeof RolesService.postRole>>;
export type RolesPermissionsServicePostRolesPermissionMutationResult = Awaited<ReturnType<typeof RolesPermissionsService.postRolesPermission>>;
export type StrategiesServicePostStrategyMutationResult = Awaited<ReturnType<typeof StrategiesService.postStrategy>>;
export type StrategyFormatsServicePostStrategyFormatsMutationResult = Awaited<ReturnType<typeof StrategyFormatsService.postStrategyFormats>>;
export type UrlsServicePostUrlsMutationResult = Awaited<ReturnType<typeof UrlsService.postUrls>>;
export type UsersServicePostUsersAsyncMutationResult = Awaited<ReturnType<typeof UsersService.postUsersAsync>>;
export type AppVariablesServicePutAppVariableMutationResult = Awaited<ReturnType<typeof AppVariablesService.putAppVariable>>;
export type ArticlesServicePutArticleMutationResult = Awaited<ReturnType<typeof ArticlesService.putArticle>>;
export type AttachedFilesServicePutAttachedFileMutationResult = Awaited<ReturnType<typeof AttachedFilesService.putAttachedFile>>;
export type CatalogsServicePutCatalogsMutationResult = Awaited<ReturnType<typeof CatalogsService.putCatalogs>>;
export type ContactDetailsServicePutContactDetailsMutationResult = Awaited<ReturnType<typeof ContactDetailsService.putContactDetails>>;
export type DemographicDataServicePutDemographicDataMutationResult = Awaited<ReturnType<typeof DemographicDataService.putDemographicData>>;
export type DynamicNamesServicePutDynamicNamesMutationResult = Awaited<ReturnType<typeof DynamicNamesService.putDynamicNames>>;
export type EmploymentDataServicePutEmploymentDataMutationResult = Awaited<ReturnType<typeof EmploymentDataService.putEmploymentData>>;
export type FieldsServicePutFieldsMutationResult = Awaited<ReturnType<typeof FieldsService.putFields>>;
export type FlowDocumentsServicePutFlowDocumentsMutationResult = Awaited<ReturnType<typeof FlowDocumentsService.putFlowDocuments>>;
export type FlowStagesServicePutFlowStagesMutationResult = Awaited<ReturnType<typeof FlowStagesService.putFlowStages>>;
export type FormatsServicePutFormatsMutationResult = Awaited<ReturnType<typeof FormatsService.putFormats>>;
export type GroupsServicePutGroupsMutationResult = Awaited<ReturnType<typeof GroupsService.putGroups>>;
export type GuaranteeOwnersServicePutGuaranteeOwnersMutationResult = Awaited<ReturnType<typeof GuaranteeOwnersService.putGuaranteeOwners>>;
export type GuaranteesServicePutGuaranteesMutationResult = Awaited<ReturnType<typeof GuaranteesService.putGuarantees>>;
export type HolidaysServicePutHolidaysMutationResult = Awaited<ReturnType<typeof HolidaysService.putHolidays>>;
export type LiabilitiesServicePutLiabilitiesMutationResult = Awaited<ReturnType<typeof LiabilitiesService.putLiabilities>>;
export type ManagementServicePutManagementDetailsMutationResult = Awaited<ReturnType<typeof ManagementService.putManagementDetails>>;
export type MonthlyPaymentsServicePutMonthlyPaymentsMutationResult = Awaited<ReturnType<typeof MonthlyPaymentsService.putMonthlyPayments>>;
export type OperatingFlowsServicePutOperatingFlowsMutationResult = Awaited<ReturnType<typeof OperatingFlowsService.putOperatingFlows>>;
export type OrderItemsServicePutOrderItemsMutationResult = Awaited<ReturnType<typeof OrderItemsService.putOrderItems>>;
export type OtherPersonsServicePutOtherPersonsMutationResult = Awaited<ReturnType<typeof OtherPersonsService.putOtherPersons>>;
export type PaymentAgreementsServicePutPaymentAgreementMutationResult = Awaited<ReturnType<typeof PaymentAgreementsService.putPaymentAgreement>>;
export type PersonalReferencesServicePutPersonalReferencesMutationResult = Awaited<ReturnType<typeof PersonalReferencesService.putPersonalReferences>>;
export type PersonsAddendumServicePutPersonsAddendumMutationResult = Awaited<ReturnType<typeof PersonsAddendumService.putPersonsAddendum>>;
export type PromisesServiceUpdatePromiseMutationResult = Awaited<ReturnType<typeof PromisesService.updatePromise>>;
export type QuotesServicePutQuotesMutationResult = Awaited<ReturnType<typeof QuotesService.putQuotes>>;
export type ReschedulesServicePutReschedulesMutationResult = Awaited<ReturnType<typeof ReschedulesService.putReschedules>>;
export type RolesServicePutRoleMutationResult = Awaited<ReturnType<typeof RolesService.putRole>>;
export type RolesPermissionsServicePutRolesPermissionMutationResult = Awaited<ReturnType<typeof RolesPermissionsService.putRolesPermission>>;
export type StrategiesServicePutStrategyMutationResult = Awaited<ReturnType<typeof StrategiesService.putStrategy>>;
export type StrategyFormatsServicePutStrategyFormatsMutationResult = Awaited<ReturnType<typeof StrategyFormatsService.putStrategyFormats>>;
export type UrlsServicePutUrlsMutationResult = Awaited<ReturnType<typeof UrlsService.putUrls>>;
export type UsersServicePutUsersMutationResult = Awaited<ReturnType<typeof UsersService.putUsers>>;
export type AppVariablesServiceDeleteAppVariableMutationResult = Awaited<ReturnType<typeof AppVariablesService.deleteAppVariable>>;
export type ArticlesServiceDeleteArticleMutationResult = Awaited<ReturnType<typeof ArticlesService.deleteArticle>>;
export type AttachedFilesServiceDeleteAttachedFileMutationResult = Awaited<ReturnType<typeof AttachedFilesService.deleteAttachedFile>>;
export type CatalogsServiceDeleteCatalogsMutationResult = Awaited<ReturnType<typeof CatalogsService.deleteCatalogs>>;
export type ContactDetailsServiceDeleteContactDetailsMutationResult = Awaited<ReturnType<typeof ContactDetailsService.deleteContactDetails>>;
export type DemographicDataServiceDeleteDemographicDataMutationResult = Awaited<ReturnType<typeof DemographicDataService.deleteDemographicData>>;
export type DynamicNamesServiceDeleteDynamicNamesMutationResult = Awaited<ReturnType<typeof DynamicNamesService.deleteDynamicNames>>;
export type EmploymentDataServiceDeleteEmploymentDataMutationResult = Awaited<ReturnType<typeof EmploymentDataService.deleteEmploymentData>>;
export type FlowDocumentsServiceDeleteFlowDocumentsMutationResult = Awaited<ReturnType<typeof FlowDocumentsService.deleteFlowDocuments>>;
export type FlowStagesServiceDeleteFlowStagesMutationResult = Awaited<ReturnType<typeof FlowStagesService.deleteFlowStages>>;
export type FormatsServiceDeleteFormatsMutationResult = Awaited<ReturnType<typeof FormatsService.deleteFormats>>;
export type GroupsServiceDeleteGroupsMutationResult = Awaited<ReturnType<typeof GroupsService.deleteGroups>>;
export type GuaranteeOwnersServiceDeleteGuaranteeOwnersMutationResult = Awaited<ReturnType<typeof GuaranteeOwnersService.deleteGuaranteeOwners>>;
export type GuaranteesServiceDeleteGuaranteesMutationResult = Awaited<ReturnType<typeof GuaranteesService.deleteGuarantees>>;
export type HolidaysServiceDeleteHolidaysMutationResult = Awaited<ReturnType<typeof HolidaysService.deleteHolidays>>;
export type LiabilitiesServiceDeleteLiabilitiesMutationResult = Awaited<ReturnType<typeof LiabilitiesService.deleteLiabilities>>;
export type ManagementServiceDeleteManagementDetailsMutationResult = Awaited<ReturnType<typeof ManagementService.deleteManagementDetails>>;
export type MonthlyPaymentsServiceDeleteMonthlyPaymentsMutationResult = Awaited<ReturnType<typeof MonthlyPaymentsService.deleteMonthlyPayments>>;
export type OperatingFlowsServiceDeleteOperatingFlowsMutationResult = Awaited<ReturnType<typeof OperatingFlowsService.deleteOperatingFlows>>;
export type OrderItemsServiceDeleteOrderItemsMutationResult = Awaited<ReturnType<typeof OrderItemsService.deleteOrderItems>>;
export type OtherPersonsServiceDeleteOtherPersonsMutationResult = Awaited<ReturnType<typeof OtherPersonsService.deleteOtherPersons>>;
export type PaymentAgreementsServiceDeletePaymentAgreementMutationResult = Awaited<ReturnType<typeof PaymentAgreementsService.deletePaymentAgreement>>;
export type PersonalReferencesServiceDeletePersonalReferencesMutationResult = Awaited<ReturnType<typeof PersonalReferencesService.deletePersonalReferences>>;
export type PersonsAddendumServiceDeletePersonsAddendumMutationResult = Awaited<ReturnType<typeof PersonsAddendumService.deletePersonsAddendum>>;
export type PromisesServiceDeletePromiseMutationResult = Awaited<ReturnType<typeof PromisesService.deletePromise>>;
export type QuotesServiceDeleteQuotesMutationResult = Awaited<ReturnType<typeof QuotesService.deleteQuotes>>;
export type ReschedulesServiceDeleteReschedulesMutationResult = Awaited<ReturnType<typeof ReschedulesService.deleteReschedules>>;
export type RolesServiceDeleteRoleMutationResult = Awaited<ReturnType<typeof RolesService.deleteRole>>;
export type RolesPermissionsServiceDeleteRolesPermissionMutationResult = Awaited<ReturnType<typeof RolesPermissionsService.deleteRolesPermission>>;
export type StrategiesServiceDeleteStrategyMutationResult = Awaited<ReturnType<typeof StrategiesService.deleteStrategy>>;
export type StrategyFormatsServiceDeleteStrategyFormatsMutationResult = Awaited<ReturnType<typeof StrategyFormatsService.deleteStrategyFormats>>;
export type UrlsServiceDeleteUrlsMutationResult = Awaited<ReturnType<typeof UrlsService.deleteUrls>>;
export type UsersServiceDeleteUsersMutationResult = Awaited<ReturnType<typeof UsersService.deleteUsers>>;
