import { FallbackComponent } from "@/components/FallbackComponent";
import { LoadingAccess } from "@/components/LoadingAccess";
import { Unauthorized } from "@/components/Unauthorized";
import { useHolidayMaintenance } from "@/hooks/usePages";
import type { HolidaysWithAction } from "@/types/index";
import reactLazyWithRetry from "@fatso83/retry-dynamic-import/react-lazy";
import { zodResolver } from "@hookform/resolvers/zod";
import Skeleton from "@mui/material/Skeleton";
import { ErrorBoundary } from "@sentry/react";
import dayjs, { type Dayjs } from "dayjs";
import { Suspense } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";

const Holidays = reactLazyWithRetry(async () => {
  return {
    default: (await import("./components/Holidays")).Holidays,
  };
});

const HolidayManipulation = reactLazyWithRetry(async () => {
  return {
    default: (await import("./components/HolidayManipulation"))
      .HolidayManipulation,
  };
});

const schema = z.object({
  sequential: z.number().int().nonnegative(),
  date: z.instanceof(dayjs as unknown as typeof Dayjs),
  action: z.enum(["add", "edit", "delete"]),
});

export const Component = () => {
  const { hasViewPermission, loadingPermission } = useHolidayMaintenance();
  const methods = useForm<HolidaysWithAction>({
    defaultValues: {
      sequential: 0,
      date: null,
      action: "add",
    },
    resolver: zodResolver(schema),
  });

  if (loadingPermission) {
    return <LoadingAccess />;
  }

  if (!hasViewPermission) {
    return <Unauthorized />;
  }
  return (
    <FormProvider {...methods}>
      <ErrorBoundary
        beforeCapture={(scope) => {
          scope.setTag("component", "holidays");
        }}
        fallback={FallbackComponent}
      >
        <Suspense
          fallback={<Skeleton variant="rounded" width="100%" height={341} />}
        >
          <Holidays />
        </Suspense>
      </ErrorBoundary>
      <ErrorBoundary
        beforeCapture={(scope) => {
          scope.setTag("component", "HolidayManipulation");
        }}
        fallback={FallbackComponent}
      >
        <Suspense
          fallback={<Skeleton variant="rounded" width="100%" height={125} />}
        >
          <HolidayManipulation />
        </Suspense>
      </ErrorBoundary>
    </FormProvider>
  );
};

Component.displayName = "HolidayMaintenance";
