import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { withProfiler } from "@sentry/react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function Component() {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  return (
    <Card
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        p: 1,
      }}
    >
      <Typography variant="h4" fontWeight="bold">
        {t("unauthorizedHeading")}
      </Typography>
      <Typography variant="h6">{t("unauthorizedMessage")}</Typography>
      <Button onClick={() => navigate(-1)}>{t("unauthorizedButton")}</Button>
    </Card>
  );
}

Component.displayName = "Unauthorized";
export const Unauthorized = withProfiler(Component);
