import type { ClientFinderSearchR } from "@/types";
import { atom } from "recoil";

export const selectedRowState = atom<ClientFinderSearchR>({
  key: "selectedRowClientFinder",
  default: {
    contrato: "",
    cliente: "",
    nombreCliente: "",
    identificacion: "",
    saldoActual: 0,
    saldoMoroso: 0,
    diasAtraso: "",
    producto: "",
    estado1: "",
    estado2: "",
    tipoCliente: "",
    telefono: "",
    activa: false,
  },
});

export const openFiltersMenuState = atom<boolean>({
  key: "openFiltersMenuClientFinder",
  default: false,
});

export const anchorFiltersMenuState = atom<null | HTMLElement>({
  key: "anchorFiltersMenuClientFinder",
  default: null,
});
