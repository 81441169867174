import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { withProfiler } from "@sentry/react";
import { useTranslation } from "react-i18next";

function Component() {
  const { t } = useTranslation("common");
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="fill-available"
      gap={5}
      flexDirection="column"
    >
      <CircularProgress color="secondary" />
      <Typography variant="h6">{t("loadingAccessLabel")}</Typography>
    </Box>
  );
}

Component.displayName = "LoadingAccess";
export const LoadingAccess = withProfiler(Component);
