import { FallbackComponent } from "@/components/FallbackComponent";
import { LoadingAccess } from "@/components/LoadingAccess";
import { Unauthorized } from "@/components/Unauthorized";
import { useStrategies } from "@/hooks/usePages";
import reactLazyWithRetry from "@fatso83/retry-dynamic-import/react-lazy";
import Skeleton from "@mui/material/Skeleton";
import { ErrorBoundary } from "@sentry/react";
import { Suspense, StrictMode } from "react";

const Groups = reactLazyWithRetry(async () => {
  return {
    default: (await import("@/components/Strategies/Groups")).Groups,
  };
});
export function Component() {
  const { hasViewPermission, loadingPermission } = useStrategies();

  if (loadingPermission) {
    return <LoadingAccess />;
  }

  if (!hasViewPermission) {
    return <Unauthorized />;
  }
  return (
    <StrictMode>
      <ErrorBoundary
        beforeCapture={(scope) => {
          scope.setTag("component", "groups");
        }}
        fallback={FallbackComponent}
      >
        <Suspense fallback={<Skeleton height={81} />}>
          <Groups />
        </Suspense>
      </ErrorBoundary>
    </StrictMode>
  );
}

Component.displayName = "Strategies";
