import type { CancelablePromise } from './core/CancelablePromise';
import { OpenAPI } from './core/OpenAPI';
import { request as __request } from './core/request';
import type { AppVariable, AppVariableWithoutId, Article, AttachedFiles, AttachedFilesWithoutSequential, Catalogs, CatalogsWithoutSequential, ContactDetails, ContactDetailsWithoutSequential, DemographicData, DemographicDataWithoutSequential, DynamicNames, DynamicNamesWithoutSequential, EmploymentData, EmploymentDataWithoutSequential, Fields, FieldsWithoutSequential, FlowDocuments, FlowDocumentsWithoutSequential, FlowStages, FlowStagesWithoutSequential, Formats, FormatsWithoutSequential, GenerateLetterB, Groups, GroupsWithoutSequential, GuaranteeOwners, GuaranteeOwnersWithoutSequential, Guarantees, GuaranteesWithoutSequential, Holidays, HolidaysWithoutSequential, Import, Liabilities, LiabilitiesWithoutNumber, Management, ManagementWithoutSequential, MonthlyPayments, MonthlyPaymentsWithoutClientCode, OperatingFlows, OperatingFlowsWithoutSequential, OrderItems, OrderItemsWithoutOrderID, OtherPersons, OtherPersonsWithoutClientCode, PaymentAgreements, PaymentAgreementsWithoutSequential, PersonalReferences, PersonalReferencesWithoutSequential, PersonsAddendum, PersonsAddendumWithoutClientCode, Promises, PromisesWithoutSequential, Quotes, QuotesWithoutSequential, Reschedules, ReschedulesWithoutSequential, Roles, RolesWithoutSequential, RolesPermissions, RolesPermissionsWithoutSequential, Strategies, StrategiesWithoutSequential, StrategyFormats, StrategyFormatsWithoutSequential, Urls, UrlsBody, UrlsBodyWithoutUrlID, Users, UsersWithoutSequential } from './models';

export type AppVariablesData = {
        GetAppVariables: {
                    id: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
PutAppVariable: {
                    id: number
requestBody?: AppVariableWithoutId
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
DeleteAppVariable: {
                    id: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
GetAppVariablesByTypeAndUserId: {
                    type: string
userid: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
PostAppVariable: {
                    requestBody?: AppVariableWithoutId
/**
 * Database catalog
 */
xDbCatalog: string
/**
 * Unique operation ID
 */
xOperationId: string
                    
                };
    }

export type ArticlesData = {
        GetArticles: {
                    clientCode: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
PostArticle: {
                    requestBody?: Article
/**
 * Database catalog
 */
xDbCatalog: string
/**
 * Unique operation ID
 */
xOperationId: string
                    
                };
PutArticle: {
                    articleCode: string
requestBody?: Article
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
DeleteArticle: {
                    articleCode: string
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
    }

export type AttachedFilesData = {
        GetAttachedFiles: {
                    stage: string
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
PostAttachedFile: {
                    requestBody?: AttachedFilesWithoutSequential
/**
 * Database catalog
 */
xDbCatalog: string
/**
 * Unique operation ID
 */
xOperationId: string
                    
                };
PutAttachedFile: {
                    requestBody?: AttachedFilesWithoutSequential
sequential: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
DeleteAttachedFile: {
                    sequential: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
    }

export type CatalogsData = {
        GetCatalogsByStatus: {
                    status: string
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
GetCatalogsByDescription: {
                    description: string
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
GetCatalogsGroupByColumn: {
                    column: string
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
GetCatalogs: {
                    type: string
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
PostCatalogs: {
                    requestBody?: CatalogsWithoutSequential
/**
 * Database catalog
 */
xDbCatalog: string
/**
 * Unique operation ID
 */
xOperationId: string
                    
                };
PutCatalogs: {
                    requestBody?: CatalogsWithoutSequential
sequential: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
DeleteCatalogs: {
                    sequential: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
    }

export type ContactDetailsData = {
        GetContactDetails: {
                    client: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
PostContactDetails: {
                    requestBody?: ContactDetailsWithoutSequential
/**
 * Database catalog
 */
xDbCatalog: string
/**
 * Unique operation ID
 */
xOperationId: string
                    
                };
PutContactDetails: {
                    requestBody?: ContactDetailsWithoutSequential
sequential: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
DeleteContactDetails: {
                    sequential: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
    }

export type DemographicDataData = {
        GetDemographicData: {
                    client: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
PostDemographicData: {
                    requestBody?: DemographicDataWithoutSequential
/**
 * Database catalog
 */
xDbCatalog: string
/**
 * Unique operation ID
 */
xOperationId: string
                    
                };
PutDemographicData: {
                    requestBody?: DemographicDataWithoutSequential
sequential: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
DeleteDemographicData: {
                    sequential: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
    }

export type DynamicNamesData = {
        GetDynamicNames: {
                    key: string
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
PostDynamicNames: {
                    requestBody?: DynamicNamesWithoutSequential
/**
 * Database catalog
 */
xDbCatalog: string
/**
 * Unique operation ID
 */
xOperationId: string
                    
                };
PutDynamicNames: {
                    requestBody?: DynamicNamesWithoutSequential
sequential: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
DeleteDynamicNames: {
                    sequential: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
    }

export type EmploymentDataData = {
        GetEmploymentData: {
                    client: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
PostEmploymentData: {
                    requestBody?: EmploymentDataWithoutSequential
/**
 * Database catalog
 */
xDbCatalog: string
/**
 * Unique operation ID
 */
xOperationId: string
                    
                };
PutEmploymentData: {
                    requestBody?: EmploymentDataWithoutSequential
sequential: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
DeleteEmploymentData: {
                    sequential: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
    }

export type FieldsData = {
        GetFields: {
                    /**
 * Database catalog
 */
xDbCatalog: string
                    
                };
PutFields: {
                    requestBody?: FieldsWithoutSequential
sequential: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
    }

export type FlowDocumentsData = {
        GetFlowDocuments: {
                    flow: string
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
PostFlowDocuments: {
                    requestBody?: FlowDocumentsWithoutSequential
/**
 * Database catalog
 */
xDbCatalog: string
/**
 * Unique operation ID
 */
xOperationId: string
                    
                };
PutFlowDocuments: {
                    requestBody?: FlowDocumentsWithoutSequential
sequential: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
DeleteFlowDocuments: {
                    sequential: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
    }

export type FlowStagesData = {
        GetFlowStages: {
                    flowCode: string
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
PostFlowStages: {
                    requestBody?: FlowStagesWithoutSequential
/**
 * Database catalog
 */
xDbCatalog: string
/**
 * Unique operation ID
 */
xOperationId: string
                    
                };
PutFlowStages: {
                    requestBody?: FlowStagesWithoutSequential
sequential: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
DeleteFlowStages: {
                    sequential: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
    }

export type FormatsData = {
        GetFormats: {
                    /**
 * Database catalog
 */
xDbCatalog: string
                    
                };
PostFormats: {
                    requestBody?: FormatsWithoutSequential
/**
 * Database catalog
 */
xDbCatalog: string
/**
 * Unique operation ID
 */
xOperationId: string
                    
                };
GetFormatsByChannel: {
                    channel: string
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
PutFormats: {
                    requestBody?: FormatsWithoutSequential
sequential: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
DeleteFormats: {
                    sequential: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
    }

export type GenerateLetterData = {
        PostGenerateLetter: {
                    requestBody?: GenerateLetterB
/**
 * Database catalog
 */
xDbCatalog: string
/**
 * Unique operation ID
 */
xOperationId: string
                    
                };
    }

export type GroupsData = {
        GetGroups: {
                    /**
 * Database catalog
 */
xDbCatalog: string
                    
                };
PostGroups: {
                    requestBody?: GroupsWithoutSequential
/**
 * Database catalog
 */
xDbCatalog: string
/**
 * Unique operation ID
 */
xOperationId: string
                    
                };
PutGroups: {
                    requestBody?: GroupsWithoutSequential
sequential: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
DeleteGroups: {
                    sequential: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
    }

export type GuaranteeOwnersData = {
        GetGuaranteeOwners: {
                    guaranteeNumber: string
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
PostGuaranteeOwners: {
                    requestBody?: GuaranteeOwnersWithoutSequential
/**
 * Database catalog
 */
xDbCatalog: string
/**
 * Unique operation ID
 */
xOperationId: string
                    
                };
PutGuaranteeOwners: {
                    requestBody?: GuaranteeOwnersWithoutSequential
sequential: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
DeleteGuaranteeOwners: {
                    sequential: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
    }

export type GuaranteesData = {
        GetGuarantees: {
                    contract: string
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
PostGuarantees: {
                    requestBody?: GuaranteesWithoutSequential
/**
 * Database catalog
 */
xDbCatalog: string
/**
 * Unique operation ID
 */
xOperationId: string
                    
                };
PutGuarantees: {
                    requestBody?: GuaranteesWithoutSequential
sequential: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
DeleteGuarantees: {
                    sequential: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
    }

export type HolidaysData = {
        GetHolidays: {
                    /**
 * Database catalog
 */
xDbCatalog: string
                    
                };
PostHolidays: {
                    requestBody?: HolidaysWithoutSequential
/**
 * Database catalog
 */
xDbCatalog: string
/**
 * Unique operation ID
 */
xOperationId: string
                    
                };
PutHolidays: {
                    requestBody?: HolidaysWithoutSequential
sequential: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
DeleteHolidays: {
                    sequential: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
    }

export type ImportData = {
        PostImport: {
                    requestBody?: Import
/**
 * Database catalog
 */
xDbCatalog: string
/**
 * Unique operation ID
 */
xOperationId: string
                    
                };
    }

export type LiabilitiesData = {
        GetLiabilities: {
                    clientCode: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
PostLiabilities: {
                    requestBody?: Liabilities
/**
 * Database catalog
 */
xDbCatalog: string
/**
 * Unique operation ID
 */
xOperationId: string
                    
                };
PutLiabilities: {
                    number: number
requestBody?: LiabilitiesWithoutNumber
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
DeleteLiabilities: {
                    number: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
    }

export type ManagementData = {
        GetManagementDetails: {
                    client: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
PostManagementDetails: {
                    requestBody?: ManagementWithoutSequential
/**
 * Database catalog
 */
xDbCatalog: string
/**
 * Unique operation ID
 */
xOperationId: string
                    
                };
PutManagementDetails: {
                    requestBody?: ManagementWithoutSequential
sequential: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
DeleteManagementDetails: {
                    sequential: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
    }

export type MonthlyPaymentsData = {
        GetMonthlyPayments: {
                    clientCode: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
PutMonthlyPayments: {
                    clientCode: number
requestBody?: MonthlyPaymentsWithoutClientCode
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
DeleteMonthlyPayments: {
                    clientCode: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
PostMonthlyPayments: {
                    requestBody?: MonthlyPayments
/**
 * Database catalog
 */
xDbCatalog: string
/**
 * Unique operation ID
 */
xOperationId: string
                    
                };
    }

export type OperatingFlowsData = {
        GetOperatingFlows: {
                    /**
 * Database catalog
 */
xDbCatalog: string
                    
                };
PostOperatingFlows: {
                    requestBody?: OperatingFlowsWithoutSequential
/**
 * Database catalog
 */
xDbCatalog: string
/**
 * Unique operation ID
 */
xOperationId: string
                    
                };
GetOperatingFlowsByFlowCode: {
                    flowCode: string
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
PutOperatingFlows: {
                    requestBody?: OperatingFlowsWithoutSequential
sequential: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
DeleteOperatingFlows: {
                    sequential: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
    }

export type OrderItemsData = {
        GetAllOrderItems: {
                    /**
 * Database catalog
 */
xDbCatalog: string
                    
                };
PostOrderItems: {
                    requestBody?: OrderItems
/**
 * Database catalog
 */
xDbCatalog: string
/**
 * Unique operation ID
 */
xOperationId: string
                    
                };
GetOrderItemsByClient: {
                    client: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
PutOrderItems: {
                    orderId: number
requestBody?: OrderItemsWithoutOrderID
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
DeleteOrderItems: {
                    orderId: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
    }

export type OtherPersonsData = {
        GetOtherPersons: {
                    clientCode: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
PutOtherPersons: {
                    clientCode: number
requestBody?: OtherPersonsWithoutClientCode
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
DeleteOtherPersons: {
                    clientCode: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
PostOtherPersons: {
                    requestBody?: OtherPersons
/**
 * Database catalog
 */
xDbCatalog: string
/**
 * Unique operation ID
 */
xOperationId: string
                    
                };
    }

export type PaymentAgreementsData = {
        GetPaymentAgreements: {
                    client: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
PostPaymentAgreement: {
                    requestBody?: PaymentAgreementsWithoutSequential
/**
 * Database catalog
 */
xDbCatalog: string
/**
 * Unique operation ID
 */
xOperationId: string
                    
                };
PutPaymentAgreement: {
                    requestBody?: PaymentAgreementsWithoutSequential
sequential: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
DeletePaymentAgreement: {
                    sequential: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
    }

export type PersonalReferencesData = {
        GetPersonalReferences: {
                    client: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
PostPersonalReferences: {
                    requestBody?: PersonalReferencesWithoutSequential
/**
 * Database catalog
 */
xDbCatalog: string
/**
 * Unique operation ID
 */
xOperationId: string
                    
                };
PutPersonalReferences: {
                    requestBody?: PersonalReferencesWithoutSequential
sequential: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
DeletePersonalReferences: {
                    sequential: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
    }

export type PersonsAddendumData = {
        GetPersonsAddendum: {
                    clientCode: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
PutPersonsAddendum: {
                    clientCode: number
requestBody?: PersonsAddendumWithoutClientCode
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
DeletePersonsAddendum: {
                    clientCode: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
PostPersonsAddendum: {
                    requestBody?: PersonsAddendum
/**
 * Database catalog
 */
xDbCatalog: string
/**
 * Unique operation ID
 */
xOperationId: string
                    
                };
    }

export type PromisesData = {
        GetPromises: {
                    client: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
CreatePromise: {
                    requestBody?: PromisesWithoutSequential
/**
 * Database catalog
 */
xDbCatalog: string
/**
 * Unique operation ID
 */
xOperationId: string
                    
                };
UpdatePromise: {
                    requestBody?: PromisesWithoutSequential
sequential: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
DeletePromise: {
                    sequential: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
    }

export type QuotesData = {
        GetQuotes: {
                    client: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
GetQuotesByClientContract: {
                    client: number
contract: string
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
PostQuotes: {
                    requestBody?: QuotesWithoutSequential
/**
 * Database catalog
 */
xDbCatalog: string
/**
 * Unique operation ID
 */
xOperationId: string
                    
                };
PutQuotes: {
                    requestBody?: QuotesWithoutSequential
sequential: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
DeleteQuotes: {
                    sequential: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
    }

export type ReschedulesData = {
        GetReschedules: {
                    client: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
PostReschedules: {
                    requestBody?: ReschedulesWithoutSequential
/**
 * Database catalog
 */
xDbCatalog: string
/**
 * Unique operation ID
 */
xOperationId: string
                    
                };
PutReschedules: {
                    requestBody?: ReschedulesWithoutSequential
sequential: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
DeleteReschedules: {
                    sequential: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
    }

export type RolesData = {
        GetAllRoles: {
                    /**
 * Database catalog
 */
xDbCatalog: string
                    
                };
PostRole: {
                    requestBody?: RolesWithoutSequential
/**
 * Database catalog
 */
xDbCatalog: string
/**
 * Unique operation ID
 */
xOperationId: string
                    
                };
PutRole: {
                    requestBody?: RolesWithoutSequential
sequential: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
DeleteRole: {
                    sequential: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
    }

export type RolesPermissionsData = {
        GetPermissionsByRole: {
                    roleId: string
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
PostRolesPermission: {
                    requestBody?: RolesPermissionsWithoutSequential
/**
 * Database catalog
 */
xDbCatalog: string
/**
 * Unique operation ID
 */
xOperationId: string
                    
                };
PutRolesPermission: {
                    requestBody?: RolesPermissionsWithoutSequential
sequential: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
DeleteRolesPermission: {
                    sequential: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
    }

export type StrategiesData = {
        GetStrategies: {
                    /**
 * Database catalog
 */
xDbCatalog: string
                    
                };
PostStrategy: {
                    requestBody?: StrategiesWithoutSequential
/**
 * Database catalog
 */
xDbCatalog: string
/**
 * Unique operation ID
 */
xOperationId: string
                    
                };
PutStrategy: {
                    requestBody?: StrategiesWithoutSequential
sequential: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
DeleteStrategy: {
                    sequential: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
    }

export type StrategyFormatsData = {
        GetStrategyFormats: {
                    codeStrategy: string
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
PostStrategyFormats: {
                    requestBody?: StrategyFormatsWithoutSequential
/**
 * Database catalog
 */
xDbCatalog: string
/**
 * Unique operation ID
 */
xOperationId: string
                    
                };
PutStrategyFormats: {
                    requestBody?: StrategyFormatsWithoutSequential
sequential: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
DeleteStrategyFormats: {
                    sequential: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
    }

export type UrlsData = {
        GetUrls: {
                    /**
 * Database catalog
 */
xDbCatalog: string
                    
                };
PostUrls: {
                    requestBody?: UrlsBody
/**
 * Database catalog
 */
xDbCatalog: string
/**
 * Unique operation ID
 */
xOperationId: string
                    
                };
PutUrls: {
                    requestBody?: UrlsBodyWithoutUrlID
urlId: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
DeleteUrls: {
                    urlId: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
    }

export type UsersData = {
        GetAllUsers: {
                    /**
 * Database catalog
 */
xDbCatalog: string
                    
                };
PostUsersAsync: {
                    requestBody?: UsersWithoutSequential
/**
 * Database catalog
 */
xDbCatalog: string
/**
 * Unique operation ID
 */
xOperationId: string
                    
                };
GetUsersByRole: {
                    userRole: string
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
PutUsers: {
                    requestBody?: UsersWithoutSequential
sequential: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
DeleteUsers: {
                    sequential: number
/**
 * Database catalog
 */
xDbCatalog: string
                    
                };
    }

export class AppVariablesService {

	/**
	 * Returns app variables by id. (Auth)
	 * @returns AppVariable Success
	 * @throws ApiError
	 */
	public static getAppVariables(data: AppVariablesData['GetAppVariables']): CancelablePromise<Array<AppVariable>> {
		const {
                        
                        id,
xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v1/AppVariables/{id}',
			path: {
				id
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing GetAppVariables`,
			},
		});
	}

	/**
	 * Update an existing app variable. (Auth)
	 * @returns any App variable updated succesfully.
	 * @throws ApiError
	 */
	public static putAppVariable(data: AppVariablesData['PutAppVariable']): CancelablePromise<any> {
		const {
                        
                        id,
xDbCatalog,
requestBody
                    } = data;
		return __request(OpenAPI, {
			method: 'PUT',
			url: '/api/v1/AppVariables/{id}',
			path: {
				id
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing PutAppVariable`,
			},
		});
	}

	/**
	 * Delete an existing app variable. (Auth)
	 * @returns any App variable deleted succesfully.
	 * @throws ApiError
	 */
	public static deleteAppVariable(data: AppVariablesData['DeleteAppVariable']): CancelablePromise<any> {
		const {
                        
                        id,
xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'DELETE',
			url: '/api/v1/AppVariables/{id}',
			path: {
				id
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing DeleteAppVariable`,
			},
		});
	}

	/**
	 * Returns app variables by type and userid. (Auth)
	 * @returns AppVariable Success
	 * @throws ApiError
	 */
	public static getAppVariablesByTypeAndUserId(data: AppVariablesData['GetAppVariablesByTypeAndUserId']): CancelablePromise<Array<AppVariable>> {
		const {
                        
                        type,
userid,
xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v1/AppVariables/GetByTypeAndUserId/{type}/{userid}',
			path: {
				type, userid
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing GetAppVariablesByTypeAndUserId`,
			},
		});
	}

	/**
	 * Create a new app variable. (Auth)
	 * @returns any App variable created succesfully.
	 * @throws ApiError
	 */
	public static postAppVariable(data: AppVariablesData['PostAppVariable']): CancelablePromise<any> {
		const {
                        
                        xDbCatalog,
xOperationId,
requestBody
                    } = data;
		return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v1/AppVariables',
			headers: {
				'X-DB-Catalog': xDbCatalog, 'X-OPERATION-ID': xOperationId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing PostAppVariable`,
			},
		});
	}

}

export class ArticlesService {

	/**
	 * Returns articles by client code. (Auth)
	 * @returns Article Success
	 * @throws ApiError
	 */
	public static getArticles(data: ArticlesData['GetArticles']): CancelablePromise<Array<Article>> {
		const {
                        
                        clientCode,
xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v1/Articles/{clientCode}',
			path: {
				clientCode
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing GetArticles`,
			},
		});
	}

	/**
	 * Create a new article. (Auth)
	 * @returns any Article created succesfully.
	 * @throws ApiError
	 */
	public static postArticle(data: ArticlesData['PostArticle']): CancelablePromise<any> {
		const {
                        
                        xDbCatalog,
xOperationId,
requestBody
                    } = data;
		return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v1/Articles',
			headers: {
				'X-DB-Catalog': xDbCatalog, 'X-OPERATION-ID': xOperationId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing PostArticle`,
			},
		});
	}

	/**
	 * Update an existing article. (Auth)
	 * @returns any Article updated succesfully.
	 * @throws ApiError
	 */
	public static putArticle(data: ArticlesData['PutArticle']): CancelablePromise<any> {
		const {
                        
                        articleCode,
xDbCatalog,
requestBody
                    } = data;
		return __request(OpenAPI, {
			method: 'PUT',
			url: '/api/v1/Articles/{articleCode}',
			path: {
				articleCode
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing PutArticle`,
			},
		});
	}

	/**
	 * Delete an existing article. (Auth)
	 * @returns any Article deleted succesfully.
	 * @throws ApiError
	 */
	public static deleteArticle(data: ArticlesData['DeleteArticle']): CancelablePromise<any> {
		const {
                        
                        articleCode,
xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'DELETE',
			url: '/api/v1/Articles/{articleCode}',
			path: {
				articleCode
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing DeleteArticle`,
			},
		});
	}

}

export class AttachedFilesService {

	/**
	 * Returns attached files by stage. (Auth)
	 * @returns AttachedFiles Success
	 * @throws ApiError
	 */
	public static getAttachedFiles(data: AttachedFilesData['GetAttachedFiles']): CancelablePromise<Array<AttachedFiles>> {
		const {
                        
                        stage,
xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v1/AttachedFiles/{stage}',
			path: {
				stage
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing GetAttachedFiles`,
			},
		});
	}

	/**
	 * Create a new attached file. (Auth)
	 * @returns any Attached file created succesfully.
	 * @throws ApiError
	 */
	public static postAttachedFile(data: AttachedFilesData['PostAttachedFile']): CancelablePromise<any> {
		const {
                        
                        xDbCatalog,
xOperationId,
requestBody
                    } = data;
		return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v1/AttachedFiles',
			headers: {
				'X-DB-Catalog': xDbCatalog, 'X-OPERATION-ID': xOperationId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing PostAttachedFile`,
			},
		});
	}

	/**
	 * Update an existing attached file. (Auth)
	 * @returns any Attached file updated succesfully.
	 * @throws ApiError
	 */
	public static putAttachedFile(data: AttachedFilesData['PutAttachedFile']): CancelablePromise<any> {
		const {
                        
                        sequential,
xDbCatalog,
requestBody
                    } = data;
		return __request(OpenAPI, {
			method: 'PUT',
			url: '/api/v1/AttachedFiles/{sequential}',
			path: {
				sequential
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing PutAttachedFile`,
			},
		});
	}

	/**
	 * Delete an existing attached file. (Auth)
	 * @returns any Attached file deleted succesfully.
	 * @throws ApiError
	 */
	public static deleteAttachedFile(data: AttachedFilesData['DeleteAttachedFile']): CancelablePromise<any> {
		const {
                        
                        sequential,
xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'DELETE',
			url: '/api/v1/AttachedFiles/{sequential}',
			path: {
				sequential
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing DeleteAttachedFile`,
			},
		});
	}

}

export class CatalogsService {

	/**
	 * Returns catalogs by status. (Auth)
	 * @returns Catalogs Success
	 * @throws ApiError
	 */
	public static getCatalogsByStatus(data: CatalogsData['GetCatalogsByStatus']): CancelablePromise<Array<Catalogs>> {
		const {
                        
                        status,
xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v1/Catalogs/GetByStatus/{status}',
			path: {
				status
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing GetByStatus`,
			},
		});
	}

	/**
	 * Returns catalogs by description. (Auth)
	 * @returns Catalogs Success
	 * @throws ApiError
	 */
	public static getCatalogsByDescription(data: CatalogsData['GetCatalogsByDescription']): CancelablePromise<Array<Catalogs>> {
		const {
                        
                        description,
xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v1/Catalogs/GetByDescription/{description}',
			path: {
				description
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing GetByStatus`,
			},
		});
	}

	/**
	 * Returns catalogs by column. (Auth)
	 * @returns Catalogs Success
	 * @throws ApiError
	 */
	public static getCatalogsGroupByColumn(data: CatalogsData['GetCatalogsGroupByColumn']): CancelablePromise<Array<Catalogs>> {
		const {
                        
                        column,
xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v1/Catalogs/GroupByColumn/{column}',
			path: {
				column
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing GroupByColumn`,
			},
		});
	}

	/**
	 * Returns catalogs by type. (Auth)
	 * @returns Catalogs Success
	 * @throws ApiError
	 */
	public static getCatalogs(data: CatalogsData['GetCatalogs']): CancelablePromise<Array<Catalogs>> {
		const {
                        
                        type,
xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v1/Catalogs/{type}',
			path: {
				type
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing GetCatalogs`,
			},
		});
	}

	/**
	 * Create a new catalog. (Auth)
	 * @returns any Catalog created succesfully.
	 * @throws ApiError
	 */
	public static postCatalogs(data: CatalogsData['PostCatalogs']): CancelablePromise<any> {
		const {
                        
                        xDbCatalog,
xOperationId,
requestBody
                    } = data;
		return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v1/Catalogs',
			headers: {
				'X-DB-Catalog': xDbCatalog, 'X-OPERATION-ID': xOperationId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing PostCatalogs`,
			},
		});
	}

	/**
	 * Update an existing catalog. (Auth)
	 * @returns any Catalog updated succesfully.
	 * @throws ApiError
	 */
	public static putCatalogs(data: CatalogsData['PutCatalogs']): CancelablePromise<any> {
		const {
                        
                        sequential,
xDbCatalog,
requestBody
                    } = data;
		return __request(OpenAPI, {
			method: 'PUT',
			url: '/api/v1/Catalogs/{sequential}',
			path: {
				sequential
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing PutCatalogs`,
			},
		});
	}

	/**
	 * Delete an existing catalog. (Auth)
	 * @returns any Catalog deleted succesfully.
	 * @throws ApiError
	 */
	public static deleteCatalogs(data: CatalogsData['DeleteCatalogs']): CancelablePromise<any> {
		const {
                        
                        sequential,
xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'DELETE',
			url: '/api/v1/Catalogs/{sequential}',
			path: {
				sequential
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing DeleteCatalogs`,
			},
		});
	}

}

export class ContactDetailsService {

	/**
	 * Returns contact details by client. (Auth)
	 * @returns ContactDetails Success
	 * @throws ApiError
	 */
	public static getContactDetails(data: ContactDetailsData['GetContactDetails']): CancelablePromise<Array<ContactDetails>> {
		const {
                        
                        client,
xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v1/ContactDetails/{client}',
			path: {
				client
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing GetContactDetails`,
			},
		});
	}

	/**
	 * Create a new contact detail (Auth)
	 * @returns any Contact detail created succesfully.
	 * @throws ApiError
	 */
	public static postContactDetails(data: ContactDetailsData['PostContactDetails']): CancelablePromise<any> {
		const {
                        
                        xDbCatalog,
xOperationId,
requestBody
                    } = data;
		return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v1/ContactDetails',
			headers: {
				'X-DB-Catalog': xDbCatalog, 'X-OPERATION-ID': xOperationId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing PostContactDetails`,
			},
		});
	}

	/**
	 * Update an existing contact detail (Auth)
	 * @returns any Contact detail updated succesfully.
	 * @throws ApiError
	 */
	public static putContactDetails(data: ContactDetailsData['PutContactDetails']): CancelablePromise<any> {
		const {
                        
                        sequential,
xDbCatalog,
requestBody
                    } = data;
		return __request(OpenAPI, {
			method: 'PUT',
			url: '/api/v1/ContactDetails/{sequential}',
			path: {
				sequential
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing PutContactDetails`,
			},
		});
	}

	/**
	 * Delete an existing contact detail (Auth)
	 * @returns any Contact detail deleted succesfully.
	 * @throws ApiError
	 */
	public static deleteContactDetails(data: ContactDetailsData['DeleteContactDetails']): CancelablePromise<any> {
		const {
                        
                        sequential,
xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'DELETE',
			url: '/api/v1/ContactDetails/{sequential}',
			path: {
				sequential
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing DeleteContactDetails`,
			},
		});
	}

}

export class DemographicDataService {

	/**
	 * Returns demographic data by client. (Auth)
	 * @returns DemographicData Success
	 * @throws ApiError
	 */
	public static getDemographicData(data: DemographicDataData['GetDemographicData']): CancelablePromise<Array<DemographicData>> {
		const {
                        
                        client,
xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v1/DemographicData/{client}',
			path: {
				client
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing GetDemographicData`,
			},
		});
	}

	/**
	 * Create a new demographic data (Auth)
	 * @returns any Demographic data created succesfully.
	 * @throws ApiError
	 */
	public static postDemographicData(data: DemographicDataData['PostDemographicData']): CancelablePromise<any> {
		const {
                        
                        xDbCatalog,
xOperationId,
requestBody
                    } = data;
		return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v1/DemographicData',
			headers: {
				'X-DB-Catalog': xDbCatalog, 'X-OPERATION-ID': xOperationId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing PostDemographicData`,
			},
		});
	}

	/**
	 * Update an exisiting demographic data. (Auth)
	 * @returns any Demographic data updated succesfully.
	 * @throws ApiError
	 */
	public static putDemographicData(data: DemographicDataData['PutDemographicData']): CancelablePromise<any> {
		const {
                        
                        sequential,
xDbCatalog,
requestBody
                    } = data;
		return __request(OpenAPI, {
			method: 'PUT',
			url: '/api/v1/DemographicData/{sequential}',
			path: {
				sequential
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing PutDemographicData`,
			},
		});
	}

	/**
	 * Delete an existing demographic data. (Auth)
	 * @returns any Demographic data deleted succesfully.
	 * @throws ApiError
	 */
	public static deleteDemographicData(data: DemographicDataData['DeleteDemographicData']): CancelablePromise<any> {
		const {
                        
                        sequential,
xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'DELETE',
			url: '/api/v1/DemographicData/{sequential}',
			path: {
				sequential
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing DeleteDemographicData`,
			},
		});
	}

}

export class DynamicNamesService {

	/**
	 * Returns dynamic names by key. (Auth)
	 * @returns DynamicNames Success
	 * @throws ApiError
	 */
	public static getDynamicNames(data: DynamicNamesData['GetDynamicNames']): CancelablePromise<Array<DynamicNames>> {
		const {
                        
                        key,
xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v1/DynamicNames/{key}',
			path: {
				key
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing GetDynamicNames`,
			},
		});
	}

	/**
	 * Create a new dynamic name. (Auth)
	 * @returns any Dynamic names created succesfully.
	 * @throws ApiError
	 */
	public static postDynamicNames(data: DynamicNamesData['PostDynamicNames']): CancelablePromise<any> {
		const {
                        
                        xDbCatalog,
xOperationId,
requestBody
                    } = data;
		return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v1/DynamicNames',
			headers: {
				'X-DB-Catalog': xDbCatalog, 'X-OPERATION-ID': xOperationId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing PostDynamicNames`,
			},
		});
	}

	/**
	 * Update an existing dynamic name. (Auth)
	 * @returns any Dynamic names updated succesfully.
	 * @throws ApiError
	 */
	public static putDynamicNames(data: DynamicNamesData['PutDynamicNames']): CancelablePromise<any> {
		const {
                        
                        sequential,
xDbCatalog,
requestBody
                    } = data;
		return __request(OpenAPI, {
			method: 'PUT',
			url: '/api/v1/DynamicNames/{sequential}',
			path: {
				sequential
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing PutDynamicNames`,
			},
		});
	}

	/**
	 * Delete an existing dynamic name. (Auth)
	 * @returns any Dynamic names deleted succesfully.
	 * @throws ApiError
	 */
	public static deleteDynamicNames(data: DynamicNamesData['DeleteDynamicNames']): CancelablePromise<any> {
		const {
                        
                        sequential,
xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'DELETE',
			url: '/api/v1/DynamicNames/{sequential}',
			path: {
				sequential
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing DeleteDynamicNames`,
			},
		});
	}

}

export class EmploymentDataService {

	/**
	 * Returns employment data by client. (Auth)
	 * @returns EmploymentData Success
	 * @throws ApiError
	 */
	public static getEmploymentData(data: EmploymentDataData['GetEmploymentData']): CancelablePromise<Array<EmploymentData>> {
		const {
                        
                        client,
xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v1/EmploymentData/{client}',
			path: {
				client
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing GetEmploymentData`,
			},
		});
	}

	/**
	 * Create a new employment data (Auth)
	 * @returns any Employment data created successfully.
	 * @throws ApiError
	 */
	public static postEmploymentData(data: EmploymentDataData['PostEmploymentData']): CancelablePromise<any> {
		const {
                        
                        xDbCatalog,
xOperationId,
requestBody
                    } = data;
		return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v1/EmploymentData',
			headers: {
				'X-DB-Catalog': xDbCatalog, 'X-OPERATION-ID': xOperationId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing PostEmploymentData`,
			},
		});
	}

	/**
	 * Update an existing employment data. (Auth)
	 * @returns any Employment data updated successfully.
	 * @throws ApiError
	 */
	public static putEmploymentData(data: EmploymentDataData['PutEmploymentData']): CancelablePromise<any> {
		const {
                        
                        sequential,
xDbCatalog,
requestBody
                    } = data;
		return __request(OpenAPI, {
			method: 'PUT',
			url: '/api/v1/EmploymentData/{sequential}',
			path: {
				sequential
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing PutEmploymentData`,
			},
		});
	}

	/**
	 * Delete an existing employment data. (Auth)
	 * @returns any Employment data deleted successfully.
	 * @throws ApiError
	 */
	public static deleteEmploymentData(data: EmploymentDataData['DeleteEmploymentData']): CancelablePromise<any> {
		const {
                        
                        sequential,
xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'DELETE',
			url: '/api/v1/EmploymentData/{sequential}',
			path: {
				sequential
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing DeleteEmploymentData`,
			},
		});
	}

}

export class FieldsService {

	/**
	 * Returns all fields. (Auth)
	 * @returns Fields Success
	 * @throws ApiError
	 */
	public static getFields(data: FieldsData['GetFields']): CancelablePromise<Array<Fields>> {
		const {
                        
                        xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v1/Fields',
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing GetFields`,
			},
		});
	}

	/**
	 * Update an existing field. (Auth)
	 * @returns any Fields updated successfully.
	 * @throws ApiError
	 */
	public static putFields(data: FieldsData['PutFields']): CancelablePromise<any> {
		const {
                        
                        sequential,
xDbCatalog,
requestBody
                    } = data;
		return __request(OpenAPI, {
			method: 'PUT',
			url: '/api/v1/Fields/{sequential}',
			path: {
				sequential
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing PutFields`,
			},
		});
	}

}

export class FlowDocumentsService {

	/**
	 * Returns flow documents by flow code. (Auth)
	 * @returns FlowDocuments Success
	 * @throws ApiError
	 */
	public static getFlowDocuments(data: FlowDocumentsData['GetFlowDocuments']): CancelablePromise<Array<FlowDocuments>> {
		const {
                        
                        flow,
xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v1/FlowDocuments/{flow}',
			path: {
				flow
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing GetFlowDocuments`,
			},
		});
	}

	/**
	 * Create a new flow document (Auth)
	 * @returns any Flow document created successfully.
	 * @throws ApiError
	 */
	public static postFlowDocuments(data: FlowDocumentsData['PostFlowDocuments']): CancelablePromise<any> {
		const {
                        
                        xDbCatalog,
xOperationId,
requestBody
                    } = data;
		return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v1/FlowDocuments',
			headers: {
				'X-DB-Catalog': xDbCatalog, 'X-OPERATION-ID': xOperationId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing PostFlowDocuments`,
			},
		});
	}

	/**
	 * Update an existing flow document. (Auth)
	 * @returns any Flow document updated successfully.
	 * @throws ApiError
	 */
	public static putFlowDocuments(data: FlowDocumentsData['PutFlowDocuments']): CancelablePromise<any> {
		const {
                        
                        sequential,
xDbCatalog,
requestBody
                    } = data;
		return __request(OpenAPI, {
			method: 'PUT',
			url: '/api/v1/FlowDocuments/{sequential}',
			path: {
				sequential
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing PutFlowDocuments`,
			},
		});
	}

	/**
	 * Delete an existing flow document. (Auth)
	 * @returns any Flow document deleted successfully.
	 * @throws ApiError
	 */
	public static deleteFlowDocuments(data: FlowDocumentsData['DeleteFlowDocuments']): CancelablePromise<any> {
		const {
                        
                        sequential,
xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'DELETE',
			url: '/api/v1/FlowDocuments/{sequential}',
			path: {
				sequential
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing DeleteFlowDocuments`,
			},
		});
	}

}

export class FlowStagesService {

	/**
	 * Returns flow stages by flow code. (Auth)
	 * @returns FlowStages Success
	 * @throws ApiError
	 */
	public static getFlowStages(data: FlowStagesData['GetFlowStages']): CancelablePromise<Array<FlowStages>> {
		const {
                        
                        flowCode,
xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v1/FlowStages/{flowCode}',
			path: {
				flowCode
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing GetFlowStages`,
			},
		});
	}

	/**
	 * Create a new flow stage. (Auth)
	 * @returns any Flow stage created succesfully.
	 * @throws ApiError
	 */
	public static postFlowStages(data: FlowStagesData['PostFlowStages']): CancelablePromise<any> {
		const {
                        
                        xDbCatalog,
xOperationId,
requestBody
                    } = data;
		return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v1/FlowStages',
			headers: {
				'X-DB-Catalog': xDbCatalog, 'X-OPERATION-ID': xOperationId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing PostFlowStages`,
			},
		});
	}

	/**
	 * Update an existing flow stage. (Auth)
	 * @returns any Flow stage updated succesfully.
	 * @throws ApiError
	 */
	public static putFlowStages(data: FlowStagesData['PutFlowStages']): CancelablePromise<any> {
		const {
                        
                        sequential,
xDbCatalog,
requestBody
                    } = data;
		return __request(OpenAPI, {
			method: 'PUT',
			url: '/api/v1/FlowStages/{sequential}',
			path: {
				sequential
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing PutFlowStages`,
			},
		});
	}

	/**
	 * Delete an existing flow stage. (Auth)
	 * @returns any Flow stage deleted succesfully.
	 * @throws ApiError
	 */
	public static deleteFlowStages(data: FlowStagesData['DeleteFlowStages']): CancelablePromise<any> {
		const {
                        
                        sequential,
xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'DELETE',
			url: '/api/v1/FlowStages/{sequential}',
			path: {
				sequential
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing DeleteFlowStages`,
			},
		});
	}

}

export class FormatsService {

	/**
	 * Returns all formats. (Auth)
	 * @returns Formats Success
	 * @throws ApiError
	 */
	public static getFormats(data: FormatsData['GetFormats']): CancelablePromise<Array<Formats>> {
		const {
                        
                        xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v1/Formats',
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing GetFormats`,
			},
		});
	}

	/**
	 * Create a new format (Auth)
	 * @returns any Format created succesfully.
	 * @throws ApiError
	 */
	public static postFormats(data: FormatsData['PostFormats']): CancelablePromise<any> {
		const {
                        
                        xDbCatalog,
xOperationId,
requestBody
                    } = data;
		return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v1/Formats',
			headers: {
				'X-DB-Catalog': xDbCatalog, 'X-OPERATION-ID': xOperationId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing PostFormats`,
			},
		});
	}

	/**
	 * Returns formats by channel. (Auth)
	 * @returns Formats Success
	 * @throws ApiError
	 */
	public static getFormatsByChannel(data: FormatsData['GetFormatsByChannel']): CancelablePromise<Array<Formats>> {
		const {
                        
                        channel,
xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v1/Formats/GetByChannel/{channel}',
			path: {
				channel
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing GetFormats`,
			},
		});
	}

	/**
	 * Update an existing format. (Auth)
	 * @returns any Format updated succesfully.
	 * @throws ApiError
	 */
	public static putFormats(data: FormatsData['PutFormats']): CancelablePromise<any> {
		const {
                        
                        sequential,
xDbCatalog,
requestBody
                    } = data;
		return __request(OpenAPI, {
			method: 'PUT',
			url: '/api/v1/Formats/{sequential}',
			path: {
				sequential
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing PutFormats`,
			},
		});
	}

	/**
	 * Delete an existing format. (Auth)
	 * @returns any Format deleted succesfully.
	 * @throws ApiError
	 */
	public static deleteFormats(data: FormatsData['DeleteFormats']): CancelablePromise<any> {
		const {
                        
                        sequential,
xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'DELETE',
			url: '/api/v1/Formats/{sequential}',
			path: {
				sequential
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing DeleteFormats`,
			},
		});
	}

}

export class GenerateLetterService {

	/**
	 * Generates a letter. (Auth)
	 * @returns any GenerateLetter done successfully.
	 * @throws ApiError
	 */
	public static postGenerateLetter(data: GenerateLetterData['PostGenerateLetter']): CancelablePromise<any> {
		const {
                        
                        xDbCatalog,
xOperationId,
requestBody
                    } = data;
		return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v1/GenerateLetter',
			headers: {
				'X-DB-Catalog': xDbCatalog, 'X-OPERATION-ID': xOperationId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing PostGenerateLetter`,
			},
		});
	}

}

export class GroupsService {

	/**
	 * Returns all groups (Auth)
	 * @returns Groups Success
	 * @throws ApiError
	 */
	public static getGroups(data: GroupsData['GetGroups']): CancelablePromise<Array<Groups>> {
		const {
                        
                        xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v1/Groups',
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing GetGroups`,
			},
		});
	}

	/**
	 * Createa new group (Auth)
	 * @returns any Group created succesfully.
	 * @throws ApiError
	 */
	public static postGroups(data: GroupsData['PostGroups']): CancelablePromise<any> {
		const {
                        
                        xDbCatalog,
xOperationId,
requestBody
                    } = data;
		return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v1/Groups',
			headers: {
				'X-DB-Catalog': xDbCatalog, 'X-OPERATION-ID': xOperationId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing PostGroups`,
			},
		});
	}

	/**
	 * Update an existing group. (Auth)
	 * @returns any Group updated succesfully.
	 * @throws ApiError
	 */
	public static putGroups(data: GroupsData['PutGroups']): CancelablePromise<any> {
		const {
                        
                        sequential,
xDbCatalog,
requestBody
                    } = data;
		return __request(OpenAPI, {
			method: 'PUT',
			url: '/api/v1/Groups/{sequential}',
			path: {
				sequential
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing PutGroups`,
			},
		});
	}

	/**
	 * Delete an existing group. (Auth)
	 * @returns any Group deleted succesfully.
	 * @throws ApiError
	 */
	public static deleteGroups(data: GroupsData['DeleteGroups']): CancelablePromise<any> {
		const {
                        
                        sequential,
xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'DELETE',
			url: '/api/v1/Groups/{sequential}',
			path: {
				sequential
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing DeleteGroups`,
			},
		});
	}

}

export class GuaranteeOwnersService {

	/**
	 * Returns guarantee owners based on the guarantee number. (Auth)
	 * @returns GuaranteeOwners Success
	 * @throws ApiError
	 */
	public static getGuaranteeOwners(data: GuaranteeOwnersData['GetGuaranteeOwners']): CancelablePromise<Array<GuaranteeOwners>> {
		const {
                        
                        guaranteeNumber,
xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v1/GuaranteeOwners/{guaranteeNumber}',
			path: {
				guaranteeNumber
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing GetGuaranteeOwners`,
			},
		});
	}

	/**
	 * Create a new guarantee owner. (Auth)
	 * @returns any Guarantee owner created successfully.
	 * @throws ApiError
	 */
	public static postGuaranteeOwners(data: GuaranteeOwnersData['PostGuaranteeOwners']): CancelablePromise<any> {
		const {
                        
                        xDbCatalog,
xOperationId,
requestBody
                    } = data;
		return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v1/GuaranteeOwners',
			headers: {
				'X-DB-Catalog': xDbCatalog, 'X-OPERATION-ID': xOperationId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing PostGuaranteeOwners`,
			},
		});
	}

	/**
	 * Update an existing guarantee owner. (Auth)
	 * @returns any Guarantee owner updated successfully.
	 * @throws ApiError
	 */
	public static putGuaranteeOwners(data: GuaranteeOwnersData['PutGuaranteeOwners']): CancelablePromise<any> {
		const {
                        
                        sequential,
xDbCatalog,
requestBody
                    } = data;
		return __request(OpenAPI, {
			method: 'PUT',
			url: '/api/v1/GuaranteeOwners/{sequential}',
			path: {
				sequential
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing PutGuaranteeOwners`,
			},
		});
	}

	/**
	 * Delete an existing guarantee owner. (Auth)
	 * @returns any Guarantee owner deleted successfully.
	 * @throws ApiError
	 */
	public static deleteGuaranteeOwners(data: GuaranteeOwnersData['DeleteGuaranteeOwners']): CancelablePromise<any> {
		const {
                        
                        sequential,
xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'DELETE',
			url: '/api/v1/GuaranteeOwners/{sequential}',
			path: {
				sequential
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing DeleteGuaranteeOwners`,
			},
		});
	}

}

export class GuaranteesService {

	/**
	 * Returns guarantees based on the contract. (Auth)
	 * @returns Guarantees Success
	 * @throws ApiError
	 */
	public static getGuarantees(data: GuaranteesData['GetGuarantees']): CancelablePromise<Array<Guarantees>> {
		const {
                        
                        contract,
xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v1/Guarantees/{contract}',
			path: {
				contract
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing GetGuarantees`,
			},
		});
	}

	/**
	 * Create a new guarantee. (Auth)
	 * @returns any Guarantee created successfully.
	 * @throws ApiError
	 */
	public static postGuarantees(data: GuaranteesData['PostGuarantees']): CancelablePromise<any> {
		const {
                        
                        xDbCatalog,
xOperationId,
requestBody
                    } = data;
		return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v1/Guarantees',
			headers: {
				'X-DB-Catalog': xDbCatalog, 'X-OPERATION-ID': xOperationId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing PostGuarantees`,
			},
		});
	}

	/**
	 * Update an existing guarantee. (Auth)
	 * @returns any Guarantee updated successfully.
	 * @throws ApiError
	 */
	public static putGuarantees(data: GuaranteesData['PutGuarantees']): CancelablePromise<any> {
		const {
                        
                        sequential,
xDbCatalog,
requestBody
                    } = data;
		return __request(OpenAPI, {
			method: 'PUT',
			url: '/api/v1/Guarantees/{sequential}',
			path: {
				sequential
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing PutGuarantees`,
			},
		});
	}

	/**
	 * Delete an existing guarantee. (Auth)
	 * @returns any Guarantee deleted successfully.
	 * @throws ApiError
	 */
	public static deleteGuarantees(data: GuaranteesData['DeleteGuarantees']): CancelablePromise<any> {
		const {
                        
                        sequential,
xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'DELETE',
			url: '/api/v1/Guarantees/{sequential}',
			path: {
				sequential
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing DeleteGuarantees`,
			},
		});
	}

}

export class HolidaysService {

	/**
	 * Returns all holidays. (Auth)
	 * @returns Holidays Success
	 * @throws ApiError
	 */
	public static getHolidays(data: HolidaysData['GetHolidays']): CancelablePromise<Array<Holidays>> {
		const {
                        
                        xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v1/Holidays',
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing GetHolidays`,
			},
		});
	}

	/**
	 * Creates a new holiday. (Auth)
	 * @returns any Holiday created succesfully.
	 * @throws ApiError
	 */
	public static postHolidays(data: HolidaysData['PostHolidays']): CancelablePromise<any> {
		const {
                        
                        xDbCatalog,
xOperationId,
requestBody
                    } = data;
		return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v1/Holidays',
			headers: {
				'X-DB-Catalog': xDbCatalog, 'X-OPERATION-ID': xOperationId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing PostHolidays`,
			},
		});
	}

	/**
	 * Update an existing holiday. (Auth)
	 * @returns any Holiday updated succesfully.
	 * @throws ApiError
	 */
	public static putHolidays(data: HolidaysData['PutHolidays']): CancelablePromise<any> {
		const {
                        
                        sequential,
xDbCatalog,
requestBody
                    } = data;
		return __request(OpenAPI, {
			method: 'PUT',
			url: '/api/v1/Holidays/{sequential}',
			path: {
				sequential
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing PutHolidays`,
			},
		});
	}

	/**
	 * Delete an existing holiday. (Auth)
	 * @returns any Holiday deleted succesfully.
	 * @throws ApiError
	 */
	public static deleteHolidays(data: HolidaysData['DeleteHolidays']): CancelablePromise<any> {
		const {
                        
                        sequential,
xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'DELETE',
			url: '/api/v1/Holidays/{sequential}',
			path: {
				sequential
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing DeleteHolidays`,
			},
		});
	}

}

export class ImportService {

	/**
	 * Import data (Auth)
	 * @returns any Import done successfully.
	 * @throws ApiError
	 */
	public static postImport(data: ImportData['PostImport']): CancelablePromise<any> {
		const {
                        
                        xDbCatalog,
xOperationId,
requestBody
                    } = data;
		return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v1/Import',
			headers: {
				'X-DB-Catalog': xDbCatalog, 'X-OPERATION-ID': xOperationId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing PostImport`,
			},
		});
	}

}

export class LiabilitiesService {

	/**
	 * Returns liabilities by client code. (Auth)
	 * @returns Liabilities Success
	 * @throws ApiError
	 */
	public static getLiabilities(data: LiabilitiesData['GetLiabilities']): CancelablePromise<Array<Liabilities>> {
		const {
                        
                        clientCode,
xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v1/Liabilities/{clientCode}',
			path: {
				clientCode
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing GetLiabilities`,
			},
		});
	}

	/**
	 * Create a new liabilities. (Auth)
	 * @returns any Liabilities created succesfully.
	 * @throws ApiError
	 */
	public static postLiabilities(data: LiabilitiesData['PostLiabilities']): CancelablePromise<any> {
		const {
                        
                        xDbCatalog,
xOperationId,
requestBody
                    } = data;
		return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v1/Liabilities',
			headers: {
				'X-DB-Catalog': xDbCatalog, 'X-OPERATION-ID': xOperationId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing PostLiabilities`,
			},
		});
	}

	/**
	 * Update a liabilities. (Auth)
	 * @returns any Liabilities updated succesfully.
	 * @throws ApiError
	 */
	public static putLiabilities(data: LiabilitiesData['PutLiabilities']): CancelablePromise<any> {
		const {
                        
                        number,
xDbCatalog,
requestBody
                    } = data;
		return __request(OpenAPI, {
			method: 'PUT',
			url: '/api/v1/Liabilities/{number}',
			path: {
				number
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing PutLiabilities`,
			},
		});
	}

	/**
	 * Delete a liabilities. (Auth)
	 * @returns any Liabilities deleted succesfully.
	 * @throws ApiError
	 */
	public static deleteLiabilities(data: LiabilitiesData['DeleteLiabilities']): CancelablePromise<any> {
		const {
                        
                        number,
xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'DELETE',
			url: '/api/v1/Liabilities/{number}',
			path: {
				number
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing DeleteLiabilities`,
			},
		});
	}

}

export class ManagementService {

	/**
	 * Returns managements by client. (Auth)
	 * @returns Management Success
	 * @throws ApiError
	 */
	public static getManagementDetails(data: ManagementData['GetManagementDetails']): CancelablePromise<Array<Management>> {
		const {
                        
                        client,
xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v1/Management/{client}',
			path: {
				client
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing GetManagementDetails`,
			},
		});
	}

	/**
	 * Create a new management. (Auth)
	 * @returns any Management created succesfully.
	 * @throws ApiError
	 */
	public static postManagementDetails(data: ManagementData['PostManagementDetails']): CancelablePromise<any> {
		const {
                        
                        xDbCatalog,
xOperationId,
requestBody
                    } = data;
		return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v1/Management',
			headers: {
				'X-DB-Catalog': xDbCatalog, 'X-OPERATION-ID': xOperationId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing PostManagementDetails`,
			},
		});
	}

	/**
	 * Update an existing management. (Auth)
	 * @returns any Management updated succesfully.
	 * @throws ApiError
	 */
	public static putManagementDetails(data: ManagementData['PutManagementDetails']): CancelablePromise<any> {
		const {
                        
                        sequential,
xDbCatalog,
requestBody
                    } = data;
		return __request(OpenAPI, {
			method: 'PUT',
			url: '/api/v1/Management/{sequential}',
			path: {
				sequential
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing PutManagementDetails`,
			},
		});
	}

	/**
	 * Delete an existing management. (Auth)
	 * @returns any Management deleted succesfully.
	 * @throws ApiError
	 */
	public static deleteManagementDetails(data: ManagementData['DeleteManagementDetails']): CancelablePromise<any> {
		const {
                        
                        sequential,
xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'DELETE',
			url: '/api/v1/Management/{sequential}',
			path: {
				sequential
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing DeleteManagementDetails`,
			},
		});
	}

}

export class MonthlyPaymentsService {

	/**
	 * Returns montly payments by client code. (Auth)
	 * @returns MonthlyPayments Success
	 * @throws ApiError
	 */
	public static getMonthlyPayments(data: MonthlyPaymentsData['GetMonthlyPayments']): CancelablePromise<Array<MonthlyPayments>> {
		const {
                        
                        clientCode,
xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v1/MonthlyPayments/{clientCode}',
			path: {
				clientCode
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing GetMonthlyPayments`,
			},
		});
	}

	/**
	 * Update a monthly payment. (Auth)
	 * @returns any Article updated succesfully.
	 * @throws ApiError
	 */
	public static putMonthlyPayments(data: MonthlyPaymentsData['PutMonthlyPayments']): CancelablePromise<any> {
		const {
                        
                        clientCode,
xDbCatalog,
requestBody
                    } = data;
		return __request(OpenAPI, {
			method: 'PUT',
			url: '/api/v1/MonthlyPayments/{clientCode}',
			path: {
				clientCode
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing PutMonthlyPayments`,
			},
		});
	}

	/**
	 * Delete a montly payment. (Auth)
	 * @returns any Article deleted succesfully.
	 * @throws ApiError
	 */
	public static deleteMonthlyPayments(data: MonthlyPaymentsData['DeleteMonthlyPayments']): CancelablePromise<any> {
		const {
                        
                        clientCode,
xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'DELETE',
			url: '/api/v1/MonthlyPayments/{clientCode}',
			path: {
				clientCode
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing DeleteMonthlyPayments`,
			},
		});
	}

	/**
	 * Create a new montly payment. (Auth)
	 * @returns any Article created succesfully.
	 * @throws ApiError
	 */
	public static postMonthlyPayments(data: MonthlyPaymentsData['PostMonthlyPayments']): CancelablePromise<any> {
		const {
                        
                        xDbCatalog,
xOperationId,
requestBody
                    } = data;
		return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v1/MonthlyPayments',
			headers: {
				'X-DB-Catalog': xDbCatalog, 'X-OPERATION-ID': xOperationId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing PostMonthlyPayments`,
			},
		});
	}

}

export class OperatingFlowsService {

	/**
	 * Returns all operating flows. (Auth)
	 * @returns OperatingFlows Success
	 * @throws ApiError
	 */
	public static getOperatingFlows(data: OperatingFlowsData['GetOperatingFlows']): CancelablePromise<Array<OperatingFlows>> {
		const {
                        
                        xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v1/OperatingFlows',
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing GetOperatingFlows`,
			},
		});
	}

	/**
	 * Create a new operating flow (Auth)
	 * @returns any Operating flow created succesfully.
	 * @throws ApiError
	 */
	public static postOperatingFlows(data: OperatingFlowsData['PostOperatingFlows']): CancelablePromise<any> {
		const {
                        
                        xDbCatalog,
xOperationId,
requestBody
                    } = data;
		return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v1/OperatingFlows',
			headers: {
				'X-DB-Catalog': xDbCatalog, 'X-OPERATION-ID': xOperationId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing PostOperatingFlows`,
			},
		});
	}

	/**
	 * Returns operating flows by flow code. (Auth)
	 * @returns OperatingFlows Success
	 * @throws ApiError
	 */
	public static getOperatingFlowsByFlowCode(data: OperatingFlowsData['GetOperatingFlowsByFlowCode']): CancelablePromise<Array<OperatingFlows>> {
		const {
                        
                        flowCode,
xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v1/OperatingFlows/{flowCode}',
			path: {
				flowCode
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing GetByFlowCode`,
			},
		});
	}

	/**
	 * Update an existing operating flow. (Auth)
	 * @returns any Operating flow updated succesfully.
	 * @throws ApiError
	 */
	public static putOperatingFlows(data: OperatingFlowsData['PutOperatingFlows']): CancelablePromise<any> {
		const {
                        
                        sequential,
xDbCatalog,
requestBody
                    } = data;
		return __request(OpenAPI, {
			method: 'PUT',
			url: '/api/v1/OperatingFlows/{sequential}',
			path: {
				sequential
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing PutOperatingFlows`,
			},
		});
	}

	/**
	 * Delete existing operating flow. (Auth)
	 * @returns any Operating flow deleted succesfully.
	 * @throws ApiError
	 */
	public static deleteOperatingFlows(data: OperatingFlowsData['DeleteOperatingFlows']): CancelablePromise<any> {
		const {
                        
                        sequential,
xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'DELETE',
			url: '/api/v1/OperatingFlows/{sequential}',
			path: {
				sequential
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing DeleteOperatingFlows`,
			},
		});
	}

}

export class OrderItemsService {

	/**
	 * Returns all order items. (Auth)
	 * @returns OrderItems Success
	 * @throws ApiError
	 */
	public static getAllOrderItems(data: OrderItemsData['GetAllOrderItems']): CancelablePromise<Array<OrderItems>> {
		const {
                        
                        xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v1/OrderItems',
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing GetOrderItems`,
			},
		});
	}

	/**
	 * Create a new order item (Auth)
	 * @returns any OrderItem created succesfully.
	 * @throws ApiError
	 */
	public static postOrderItems(data: OrderItemsData['PostOrderItems']): CancelablePromise<any> {
		const {
                        
                        xDbCatalog,
xOperationId,
requestBody
                    } = data;
		return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v1/OrderItems',
			headers: {
				'X-DB-Catalog': xDbCatalog, 'X-OPERATION-ID': xOperationId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing PostOrderItems`,
			},
		});
	}

	/**
	 * Returns order items by client. (Auth)
	 * @returns OrderItems Success
	 * @throws ApiError
	 */
	public static getOrderItemsByClient(data: OrderItemsData['GetOrderItemsByClient']): CancelablePromise<Array<OrderItems>> {
		const {
                        
                        client,
xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v1/OrderItems/GetByClient/{client}',
			path: {
				client
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing GetOrderItems`,
			},
		});
	}

	/**
	 * Update an existing order item. (Auth)
	 * @returns any OrderItem updated succesfully.
	 * @throws ApiError
	 */
	public static putOrderItems(data: OrderItemsData['PutOrderItems']): CancelablePromise<any> {
		const {
                        
                        orderId,
xDbCatalog,
requestBody
                    } = data;
		return __request(OpenAPI, {
			method: 'PUT',
			url: '/api/v1/OrderItems/{orderId}',
			path: {
				orderId
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing PutOrderItems`,
			},
		});
	}

	/**
	 * Delete an existing order item. (Auth)
	 * @returns any OrderItem deleted succesfully.
	 * @throws ApiError
	 */
	public static deleteOrderItems(data: OrderItemsData['DeleteOrderItems']): CancelablePromise<any> {
		const {
                        
                        orderId,
xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'DELETE',
			url: '/api/v1/OrderItems/{orderId}',
			path: {
				orderId
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing DeleteOrderItems`,
			},
		});
	}

}

export class OtherPersonsService {

	/**
	 * Returns others persons by client code. (Auth)
	 * @returns OtherPersons Success
	 * @throws ApiError
	 */
	public static getOtherPersons(data: OtherPersonsData['GetOtherPersons']): CancelablePromise<Array<OtherPersons>> {
		const {
                        
                        clientCode,
xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v1/OtherPersons/{clientCode}',
			path: {
				clientCode
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing GetOtherPersons`,
			},
		});
	}

	/**
	 * Update a other person. (Auth)
	 * @returns any Article updated succesfully.
	 * @throws ApiError
	 */
	public static putOtherPersons(data: OtherPersonsData['PutOtherPersons']): CancelablePromise<any> {
		const {
                        
                        clientCode,
xDbCatalog,
requestBody
                    } = data;
		return __request(OpenAPI, {
			method: 'PUT',
			url: '/api/v1/OtherPersons/{clientCode}',
			path: {
				clientCode
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing PutOtherPersons`,
			},
		});
	}

	/**
	 * Delete a other person. (Auth)
	 * @returns any Article deleted succesfully.
	 * @throws ApiError
	 */
	public static deleteOtherPersons(data: OtherPersonsData['DeleteOtherPersons']): CancelablePromise<any> {
		const {
                        
                        clientCode,
xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'DELETE',
			url: '/api/v1/OtherPersons/{clientCode}',
			path: {
				clientCode
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing DeleteOtherPersons`,
			},
		});
	}

	/**
	 * Create a new other person. (Auth)
	 * @returns any Article created succesfully.
	 * @throws ApiError
	 */
	public static postOtherPersons(data: OtherPersonsData['PostOtherPersons']): CancelablePromise<any> {
		const {
                        
                        xDbCatalog,
xOperationId,
requestBody
                    } = data;
		return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v1/OtherPersons',
			headers: {
				'X-DB-Catalog': xDbCatalog, 'X-OPERATION-ID': xOperationId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing PostOtherPersons`,
			},
		});
	}

}

export class PaymentAgreementsService {

	/**
	 * Returns the payment agreements by client. (Auth)
	 * @returns PaymentAgreements Success
	 * @throws ApiError
	 */
	public static getPaymentAgreements(data: PaymentAgreementsData['GetPaymentAgreements']): CancelablePromise<Array<PaymentAgreements>> {
		const {
                        
                        client,
xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v1/PaymentAgreements/{client}',
			path: {
				client
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing GetPaymentAgreements`,
			},
		});
	}

	/**
	 * Creates a new payment agreement. (Auth)
	 * @returns any Payment agreement created succesfully.
	 * @throws ApiError
	 */
	public static postPaymentAgreement(data: PaymentAgreementsData['PostPaymentAgreement']): CancelablePromise<any> {
		const {
                        
                        xDbCatalog,
xOperationId,
requestBody
                    } = data;
		return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v1/PaymentAgreements',
			headers: {
				'X-DB-Catalog': xDbCatalog, 'X-OPERATION-ID': xOperationId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing PostPaymentAgreement`,
			},
		});
	}

	/**
	 * Update an existing payment agreement. (Auth)
	 * @returns any Payment agreement updated succesfully.
	 * @throws ApiError
	 */
	public static putPaymentAgreement(data: PaymentAgreementsData['PutPaymentAgreement']): CancelablePromise<any> {
		const {
                        
                        sequential,
xDbCatalog,
requestBody
                    } = data;
		return __request(OpenAPI, {
			method: 'PUT',
			url: '/api/v1/PaymentAgreements/{sequential}',
			path: {
				sequential
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing PutPaymentAgreement`,
			},
		});
	}

	/**
	 * Delete an existing payment agreement. (Auth)
	 * @returns any Payment agreement deleted succesfully.
	 * @throws ApiError
	 */
	public static deletePaymentAgreement(data: PaymentAgreementsData['DeletePaymentAgreement']): CancelablePromise<any> {
		const {
                        
                        sequential,
xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'DELETE',
			url: '/api/v1/PaymentAgreements/{sequential}',
			path: {
				sequential
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing DeletePaymentAgreement`,
			},
		});
	}

}

export class PersonalReferencesService {

	/**
	 * Returns personal references by client. (Auth)
	 * @returns PersonalReferences Success
	 * @throws ApiError
	 */
	public static getPersonalReferences(data: PersonalReferencesData['GetPersonalReferences']): CancelablePromise<Array<PersonalReferences>> {
		const {
                        
                        client,
xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v1/PersonalReferences/{client}',
			path: {
				client
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing GetPersonalReferences`,
			},
		});
	}

	/**
	 * Creates a new personal reference. (Auth)
	 * @returns any Personal reference created succesfully.
	 * @throws ApiError
	 */
	public static postPersonalReferences(data: PersonalReferencesData['PostPersonalReferences']): CancelablePromise<any> {
		const {
                        
                        xDbCatalog,
xOperationId,
requestBody
                    } = data;
		return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v1/PersonalReferences',
			headers: {
				'X-DB-Catalog': xDbCatalog, 'X-OPERATION-ID': xOperationId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing PostPersonalReferences`,
			},
		});
	}

	/**
	 * Update an existing personal reference. (Auth)
	 * @returns any Personal reference updated succesfully.
	 * @throws ApiError
	 */
	public static putPersonalReferences(data: PersonalReferencesData['PutPersonalReferences']): CancelablePromise<any> {
		const {
                        
                        sequential,
xDbCatalog,
requestBody
                    } = data;
		return __request(OpenAPI, {
			method: 'PUT',
			url: '/api/v1/PersonalReferences/{sequential}',
			path: {
				sequential
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing PutPersonalReferences`,
			},
		});
	}

	/**
	 * Delete an existing personal reference. (Auth)
	 * @returns any Personal reference deleted succesfully.
	 * @throws ApiError
	 */
	public static deletePersonalReferences(data: PersonalReferencesData['DeletePersonalReferences']): CancelablePromise<any> {
		const {
                        
                        sequential,
xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'DELETE',
			url: '/api/v1/PersonalReferences/{sequential}',
			path: {
				sequential
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing DeletePersonalReferences`,
			},
		});
	}

}

export class PersonsAddendumService {

	/**
	 * Returns persons addendum by client code. (Auth)
	 * @returns PersonsAddendum Success
	 * @throws ApiError
	 */
	public static getPersonsAddendum(data: PersonsAddendumData['GetPersonsAddendum']): CancelablePromise<Array<PersonsAddendum>> {
		const {
                        
                        clientCode,
xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v1/PersonsAddendum/{clientCode}',
			path: {
				clientCode
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing GetPersonsAddendum`,
			},
		});
	}

	/**
	 * Update a persons addendum. (Auth)
	 * @returns any Addendum updated succesfully.
	 * @throws ApiError
	 */
	public static putPersonsAddendum(data: PersonsAddendumData['PutPersonsAddendum']): CancelablePromise<any> {
		const {
                        
                        clientCode,
xDbCatalog,
requestBody
                    } = data;
		return __request(OpenAPI, {
			method: 'PUT',
			url: '/api/v1/PersonsAddendum/{clientCode}',
			path: {
				clientCode
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing PutPersonsAddendum`,
			},
		});
	}

	/**
	 * Delete a persons addendum. (Auth)
	 * @returns any Addendum deleted succesfully.
	 * @throws ApiError
	 */
	public static deletePersonsAddendum(data: PersonsAddendumData['DeletePersonsAddendum']): CancelablePromise<any> {
		const {
                        
                        clientCode,
xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'DELETE',
			url: '/api/v1/PersonsAddendum/{clientCode}',
			path: {
				clientCode
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing DeletePersonsAddendum`,
			},
		});
	}

	/**
	 * Create a new persons addendum. (Auth)
	 * @returns any Addendum created succesfully.
	 * @throws ApiError
	 */
	public static postPersonsAddendum(data: PersonsAddendumData['PostPersonsAddendum']): CancelablePromise<any> {
		const {
                        
                        xDbCatalog,
xOperationId,
requestBody
                    } = data;
		return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v1/PersonsAddendum',
			headers: {
				'X-DB-Catalog': xDbCatalog, 'X-OPERATION-ID': xOperationId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing PostPersonsAddendum`,
			},
		});
	}

}

export class PromisesService {

	/**
	 * Returns promises by client. (Auth)
	 * @returns Promises Success
	 * @throws ApiError
	 */
	public static getPromises(data: PromisesData['GetPromises']): CancelablePromise<Array<Promises>> {
		const {
                        
                        client,
xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v1/Promises/{client}',
			path: {
				client
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing GetPromises`,
			},
		});
	}

	/**
	 * Create a new promise. (Auth)
	 * @returns any Promise created successfully.
	 * @throws ApiError
	 */
	public static createPromise(data: PromisesData['CreatePromise']): CancelablePromise<any> {
		const {
                        
                        xDbCatalog,
xOperationId,
requestBody
                    } = data;
		return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v1/Promises',
			headers: {
				'X-DB-Catalog': xDbCatalog, 'X-OPERATION-ID': xOperationId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing CreatePromise`,
			},
		});
	}

	/**
	 * Update an existing promise. (Auth)
	 * @returns any Promise updated successfully.
	 * @throws ApiError
	 */
	public static updatePromise(data: PromisesData['UpdatePromise']): CancelablePromise<any> {
		const {
                        
                        sequential,
xDbCatalog,
requestBody
                    } = data;
		return __request(OpenAPI, {
			method: 'PUT',
			url: '/api/v1/Promises/{sequential}',
			path: {
				sequential
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing UpdatePromise`,
			},
		});
	}

	/**
	 * Delete an existing promise. (Auth)
	 * @returns any Promise deleted successfully.
	 * @throws ApiError
	 */
	public static deletePromise(data: PromisesData['DeletePromise']): CancelablePromise<any> {
		const {
                        
                        sequential,
xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'DELETE',
			url: '/api/v1/Promises/{sequential}',
			path: {
				sequential
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing DeletePromise`,
			},
		});
	}

}

export class QuotesService {

	/**
	 * Returns quotes by client. (Auth)
	 * @returns Quotes Success
	 * @throws ApiError
	 */
	public static getQuotes(data: QuotesData['GetQuotes']): CancelablePromise<Array<Quotes>> {
		const {
                        
                        client,
xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v1/Quotes/{client}',
			path: {
				client
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing GetQuotes`,
			},
		});
	}

	/**
	 * Returns quotes by client and contract. (Auth)
	 * @returns Quotes Success
	 * @throws ApiError
	 */
	public static getQuotesByClientContract(data: QuotesData['GetQuotesByClientContract']): CancelablePromise<Array<Quotes>> {
		const {
                        
                        client,
contract,
xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v1/Quotes/GetByClientContract/{client}/{contract}',
			path: {
				client, contract
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing GetByClientContract`,
			},
		});
	}

	/**
	 * Create a new quote. (Auth)
	 * @returns any Quotes created succesfully.
	 * @throws ApiError
	 */
	public static postQuotes(data: QuotesData['PostQuotes']): CancelablePromise<any> {
		const {
                        
                        xDbCatalog,
xOperationId,
requestBody
                    } = data;
		return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v1/Quotes',
			headers: {
				'X-DB-Catalog': xDbCatalog, 'X-OPERATION-ID': xOperationId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing PostQuotes`,
			},
		});
	}

	/**
	 * Update an existing quote. (Auth)
	 * @returns any Quotes updated succesfully.
	 * @throws ApiError
	 */
	public static putQuotes(data: QuotesData['PutQuotes']): CancelablePromise<any> {
		const {
                        
                        sequential,
xDbCatalog,
requestBody
                    } = data;
		return __request(OpenAPI, {
			method: 'PUT',
			url: '/api/v1/Quotes/{sequential}',
			path: {
				sequential
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing PutQuotes`,
			},
		});
	}

	/**
	 * Delete an existing quote. (Auth)
	 * @returns any Quotes deleted succesfully.
	 * @throws ApiError
	 */
	public static deleteQuotes(data: QuotesData['DeleteQuotes']): CancelablePromise<any> {
		const {
                        
                        sequential,
xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'DELETE',
			url: '/api/v1/Quotes/{sequential}',
			path: {
				sequential
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing DeleteQuotes`,
			},
		});
	}

}

export class ReschedulesService {

	/**
	 * Returns reschedules by client. (Auth)
	 * @returns Reschedules Success
	 * @throws ApiError
	 */
	public static getReschedules(data: ReschedulesData['GetReschedules']): CancelablePromise<Array<Reschedules>> {
		const {
                        
                        client,
xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v1/Reschedules/{client}',
			path: {
				client
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing GetReschedules`,
			},
		});
	}

	/**
	 * Create a new reschedule. (Auth)
	 * @returns any Reschedule created succesfully.
	 * @throws ApiError
	 */
	public static postReschedules(data: ReschedulesData['PostReschedules']): CancelablePromise<any> {
		const {
                        
                        xDbCatalog,
xOperationId,
requestBody
                    } = data;
		return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v1/Reschedules',
			headers: {
				'X-DB-Catalog': xDbCatalog, 'X-OPERATION-ID': xOperationId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing PostReschedules`,
			},
		});
	}

	/**
	 * Update an existing reschedule. (Auth)
	 * @returns any Reschedule updated succesfully.
	 * @throws ApiError
	 */
	public static putReschedules(data: ReschedulesData['PutReschedules']): CancelablePromise<any> {
		const {
                        
                        sequential,
xDbCatalog,
requestBody
                    } = data;
		return __request(OpenAPI, {
			method: 'PUT',
			url: '/api/v1/Reschedules/{sequential}',
			path: {
				sequential
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing PutReschedules`,
			},
		});
	}

	/**
	 * Delete an existing reschedule. (Auth)
	 * @returns any Reschedule deleted succesfully.
	 * @throws ApiError
	 */
	public static deleteReschedules(data: ReschedulesData['DeleteReschedules']): CancelablePromise<any> {
		const {
                        
                        sequential,
xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'DELETE',
			url: '/api/v1/Reschedules/{sequential}',
			path: {
				sequential
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing DeleteReschedules`,
			},
		});
	}

}

export class RolesService {

	/**
	 * Returns all roles. (Auth)
	 * @returns Roles Success
	 * @throws ApiError
	 */
	public static getAllRoles(data: RolesData['GetAllRoles']): CancelablePromise<Array<Roles>> {
		const {
                        
                        xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v1/Roles',
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing GetAllRoles`,
			},
		});
	}

	/**
	 * Create a new role. (Auth)
	 * @returns any Role created succesfully.
	 * @throws ApiError
	 */
	public static postRole(data: RolesData['PostRole']): CancelablePromise<any> {
		const {
                        
                        xDbCatalog,
xOperationId,
requestBody
                    } = data;
		return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v1/Roles',
			headers: {
				'X-DB-Catalog': xDbCatalog, 'X-OPERATION-ID': xOperationId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing PostRole`,
			},
		});
	}

	/**
	 * Update an existing role. (Auth)
	 * @returns any Role updated succesfully.
	 * @throws ApiError
	 */
	public static putRole(data: RolesData['PutRole']): CancelablePromise<any> {
		const {
                        
                        sequential,
xDbCatalog,
requestBody
                    } = data;
		return __request(OpenAPI, {
			method: 'PUT',
			url: '/api/v1/Roles/{sequential}',
			path: {
				sequential
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing PutRole`,
			},
		});
	}

	/**
	 * Delete an existing role. (Auth)
	 * @returns any Role deleted succesfully.
	 * @throws ApiError
	 */
	public static deleteRole(data: RolesData['DeleteRole']): CancelablePromise<any> {
		const {
                        
                        sequential,
xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'DELETE',
			url: '/api/v1/Roles/{sequential}',
			path: {
				sequential
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing DeleteRole`,
			},
		});
	}

}

export class RolesPermissionsService {

	/**
	 * Returns roles permissions by role id. (Auth)
	 * @returns RolesPermissions Success
	 * @throws ApiError
	 */
	public static getPermissionsByRole(data: RolesPermissionsData['GetPermissionsByRole']): CancelablePromise<Array<RolesPermissions>> {
		const {
                        
                        roleId,
xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v1/RolesPermissions/{roleId}',
			path: {
				roleId
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing GetPermissionsByRole`,
			},
		});
	}

	/**
	 * Create a new role permission. (Auth)
	 * @returns any Role permission created succesfully.
	 * @throws ApiError
	 */
	public static postRolesPermission(data: RolesPermissionsData['PostRolesPermission']): CancelablePromise<any> {
		const {
                        
                        xDbCatalog,
xOperationId,
requestBody
                    } = data;
		return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v1/RolesPermissions',
			headers: {
				'X-DB-Catalog': xDbCatalog, 'X-OPERATION-ID': xOperationId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing PostRolesPermission`,
			},
		});
	}

	/**
	 * Update an existing role permission. (Auth)
	 * @returns any Role permission updated succesfully.
	 * @throws ApiError
	 */
	public static putRolesPermission(data: RolesPermissionsData['PutRolesPermission']): CancelablePromise<any> {
		const {
                        
                        sequential,
xDbCatalog,
requestBody
                    } = data;
		return __request(OpenAPI, {
			method: 'PUT',
			url: '/api/v1/RolesPermissions/{sequential}',
			path: {
				sequential
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing PutRolesPermission`,
			},
		});
	}

	/**
	 * Delete an existing role permission. (Auth)
	 * @returns any Role permission deleted succesfully.
	 * @throws ApiError
	 */
	public static deleteRolesPermission(data: RolesPermissionsData['DeleteRolesPermission']): CancelablePromise<any> {
		const {
                        
                        sequential,
xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'DELETE',
			url: '/api/v1/RolesPermissions/{sequential}',
			path: {
				sequential
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing DeleteRolesPermission`,
			},
		});
	}

}

export class StrategiesService {

	/**
	 * Returns all strategies. (Auth)
	 * @returns Strategies Success
	 * @throws ApiError
	 */
	public static getStrategies(data: StrategiesData['GetStrategies']): CancelablePromise<Array<Strategies>> {
		const {
                        
                        xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v1/Strategies',
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing GetStrategies`,
			},
		});
	}

	/**
	 * Create a new strategy. (Auth)
	 * @returns any Strategy created succesfully.
	 * @throws ApiError
	 */
	public static postStrategy(data: StrategiesData['PostStrategy']): CancelablePromise<any> {
		const {
                        
                        xDbCatalog,
xOperationId,
requestBody
                    } = data;
		return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v1/Strategies',
			headers: {
				'X-DB-Catalog': xDbCatalog, 'X-OPERATION-ID': xOperationId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing PostStrategy`,
			},
		});
	}

	/**
	 * Update an existing strategy. (Auth)
	 * @returns any Strategy updated succesfully.
	 * @throws ApiError
	 */
	public static putStrategy(data: StrategiesData['PutStrategy']): CancelablePromise<any> {
		const {
                        
                        sequential,
xDbCatalog,
requestBody
                    } = data;
		return __request(OpenAPI, {
			method: 'PUT',
			url: '/api/v1/Strategies/{sequential}',
			path: {
				sequential
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing PutStrategy`,
			},
		});
	}

	/**
	 * Delete an existing strategy. (Auth)
	 * @returns any Strategy deleted succesfully.
	 * @throws ApiError
	 */
	public static deleteStrategy(data: StrategiesData['DeleteStrategy']): CancelablePromise<any> {
		const {
                        
                        sequential,
xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'DELETE',
			url: '/api/v1/Strategies/{sequential}',
			path: {
				sequential
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing DeleteStrategy`,
			},
		});
	}

}

export class StrategyFormatsService {

	/**
	 * Returns strategy formats by the code of the strategy. (Auth)
	 * @returns StrategyFormats Success
	 * @throws ApiError
	 */
	public static getStrategyFormats(data: StrategyFormatsData['GetStrategyFormats']): CancelablePromise<Array<StrategyFormats>> {
		const {
                        
                        codeStrategy,
xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v1/StrategyFormats/{codeStrategy}',
			path: {
				codeStrategy
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing GetStrategyFormats`,
			},
		});
	}

	/**
	 * Create a new strategy format. (Auth)
	 * @returns any Strategy format created succesfully.
	 * @throws ApiError
	 */
	public static postStrategyFormats(data: StrategyFormatsData['PostStrategyFormats']): CancelablePromise<any> {
		const {
                        
                        xDbCatalog,
xOperationId,
requestBody
                    } = data;
		return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v1/StrategyFormats',
			headers: {
				'X-DB-Catalog': xDbCatalog, 'X-OPERATION-ID': xOperationId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing PostStrategyFormats`,
			},
		});
	}

	/**
	 * Update an existing strategy format. (Auth)
	 * @returns any Strategy format updated succesfully.
	 * @throws ApiError
	 */
	public static putStrategyFormats(data: StrategyFormatsData['PutStrategyFormats']): CancelablePromise<any> {
		const {
                        
                        sequential,
xDbCatalog,
requestBody
                    } = data;
		return __request(OpenAPI, {
			method: 'PUT',
			url: '/api/v1/StrategyFormats/{sequential}',
			path: {
				sequential
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing PutStrategyFormats`,
			},
		});
	}

	/**
	 * Delete an existing strategy format. (Auth)
	 * @returns any Strategy format deleted succesfully.
	 * @throws ApiError
	 */
	public static deleteStrategyFormats(data: StrategyFormatsData['DeleteStrategyFormats']): CancelablePromise<any> {
		const {
                        
                        sequential,
xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'DELETE',
			url: '/api/v1/StrategyFormats/{sequential}',
			path: {
				sequential
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing DeleteStrategyFormats`,
			},
		});
	}

}

export class UrlsService {

	/**
	 * Returns all urls. (Auth)
	 * @returns Urls Success
	 * @throws ApiError
	 */
	public static getUrls(data: UrlsData['GetUrls']): CancelablePromise<Array<Urls>> {
		const {
                        
                        xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v1/Urls',
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing GetUrls`,
			},
		});
	}

	/**
	 * Create a new url. (Auth)
	 * @returns any Url created succesfully.
	 * @throws ApiError
	 */
	public static postUrls(data: UrlsData['PostUrls']): CancelablePromise<any> {
		const {
                        
                        xDbCatalog,
xOperationId,
requestBody
                    } = data;
		return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v1/Urls',
			headers: {
				'X-DB-Catalog': xDbCatalog, 'X-OPERATION-ID': xOperationId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing PostUrls`,
			},
		});
	}

	/**
	 * Update an existing url. (Auth)
	 * @returns any Url updated succesfully.
	 * @throws ApiError
	 */
	public static putUrls(data: UrlsData['PutUrls']): CancelablePromise<any> {
		const {
                        
                        urlId,
xDbCatalog,
requestBody
                    } = data;
		return __request(OpenAPI, {
			method: 'PUT',
			url: '/api/v1/Urls/{urlId}',
			path: {
				urlId
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing PutUrls`,
			},
		});
	}

	/**
	 * Delete an existing url. (Auth)
	 * @returns any Url deleted succesfully.
	 * @throws ApiError
	 */
	public static deleteUrls(data: UrlsData['DeleteUrls']): CancelablePromise<any> {
		const {
                        
                        urlId,
xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'DELETE',
			url: '/api/v1/Urls/{urlId}',
			path: {
				urlId
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing DeleteUrls`,
			},
		});
	}

}

export class UsersService {

	/**
	 * Returns all users. (Auth)
	 * @returns Users Success
	 * @throws ApiError
	 */
	public static getAllUsers(data: UsersData['GetAllUsers']): CancelablePromise<Array<Users>> {
		const {
                        
                        xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v1/Users',
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing GetAllUsers`,
			},
		});
	}

	/**
	 * Create a new user. (Auth)
	 * @returns any User created succesfully.
	 * @throws ApiError
	 */
	public static postUsersAsync(data: UsersData['PostUsersAsync']): CancelablePromise<any> {
		const {
                        
                        xDbCatalog,
xOperationId,
requestBody
                    } = data;
		return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v1/Users',
			headers: {
				'X-DB-Catalog': xDbCatalog, 'X-OPERATION-ID': xOperationId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing PostUsers`,
			},
		});
	}

	/**
	 * Returns users by role. (Auth)
	 * @returns Users Success
	 * @throws ApiError
	 */
	public static getUsersByRole(data: UsersData['GetUsersByRole']): CancelablePromise<Array<Users>> {
		const {
                        
                        userRole,
xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v1/Users/GetUsersByRole/{userRole}',
			path: {
				userRole
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing GetUsersByRole`,
			},
		});
	}

	/**
	 * Update an existing user. (Auth)
	 * @returns any User updated succesfully.
	 * @throws ApiError
	 */
	public static putUsers(data: UsersData['PutUsers']): CancelablePromise<any> {
		const {
                        
                        sequential,
xDbCatalog,
requestBody
                    } = data;
		return __request(OpenAPI, {
			method: 'PUT',
			url: '/api/v1/Users/{sequential}',
			path: {
				sequential
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing PutUsers`,
			},
		});
	}

	/**
	 * Delete an existing user. (Auth)
	 * @returns any User deleted succesfully.
	 * @throws ApiError
	 */
	public static deleteUsers(data: UsersData['DeleteUsers']): CancelablePromise<any> {
		const {
                        
                        sequential,
xDbCatalog
                    } = data;
		return __request(OpenAPI, {
			method: 'DELETE',
			url: '/api/v1/Users/{sequential}',
			path: {
				sequential
			},
			headers: {
				'X-DB-Catalog': xDbCatalog
			},
			errors: {
				400: `No results found.`,
				404: `An error occurred while executing DeleteUsers`,
			},
		});
	}

}