import { FallbackComponent } from "@/components/FallbackComponent";
import { drawerWidth } from "@/config/theme";
import { useMenuItems } from "@/hooks/useMenuItems";
import { useSidebar } from "@/hooks/useSidebar";
import reactLazyWithRetry from "@fatso83/retry-dynamic-import/react-lazy";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Skeleton from "@mui/material/Skeleton";
import { ErrorBoundary, withProfiler } from "@sentry/react";
import { nanoid } from "nanoid";
import { Suspense, memo } from "react";

const Item = reactLazyWithRetry(async () => {
  return {
    default: (await import("./Item")).Item,
  };
});

const Avatar = reactLazyWithRetry(async () => {
  return {
    default: (await import("./Avatar")).Avatar,
  };
});

const Logo = reactLazyWithRetry(async () => {
  return {
    default: (await import("./Logo")).Logo,
  };
});

const Component = () => {
  const { handleDrawerToggle, showSidebar, matches } = useSidebar();
  const menu = useMenuItems();

  const drawer = (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
      sx={{ backgroundColor: "darkBlue3.main" }}
      color="white.main"
    >
      <Box display="flex" flexDirection="column">
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "avatar");
          }}
          fallback={FallbackComponent}
        >
          <Suspense fallback={<Skeleton height={63} width="100%" />}>
            <Avatar />
          </Suspense>
        </ErrorBoundary>
        <List
          disablePadding={true}
          component="nav"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          {menu.map((item) => (
            <ErrorBoundary
              key={nanoid()}
              beforeCapture={(scope) => {
                scope.setTag("component", "menuItem");
              }}
              fallback={FallbackComponent}
            >
              <Suspense
                fallback={<Skeleton height={29} width="100%" />}
                key={nanoid()}
              >
                <Item item={item} isMultiLevel={false} />
              </Suspense>
            </ErrorBoundary>
          ))}
        </List>
      </Box>
      <ErrorBoundary
        beforeCapture={(scope) => {
          scope.setTag("component", "logo");
        }}
        fallback={FallbackComponent}
      >
        <Suspense fallback={<Skeleton height={60} width="100%" />}>
          <Logo />
        </Suspense>
      </ErrorBoundary>
    </Box>
  );

  return (
    <Box component="nav">
      <Drawer
        variant={matches ? "persistent" : "temporary"}
        open={showSidebar}
        onClose={handleDrawerToggle}
        sx={{
          width: showSidebar ? drawerWidth : 0,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: showSidebar ? drawerWidth : 0,
            backgroundColor: "darkBlue3.main",
          },
        }}
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

Component.displayName = "Sidebar";
export const Sidebar = withProfiler(memo(Component));
