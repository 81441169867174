import { useBreakpoints } from "@/hooks/useBreakpoints";
import type { CustomGridToolbarProps } from "@/types/props";
import Add from "@mui/icons-material/Add";
import Cached from "@mui/icons-material/Cached";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import {
  type GridCsvGetRowsToExportParams,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  gridSortedRowIdsSelector,
} from "@mui/x-data-grid";
import { withProfiler } from "@sentry/react";
import { useTranslation } from "react-i18next";

const getUnfilteredRows = ({ apiRef }: GridCsvGetRowsToExportParams) =>
  gridSortedRowIdsSelector(apiRef);

function Component({
  addProps,
  refreshProps,
  quickFilterProps,
  exportProps,
  showExport = false,
  showFilter = true,
  customSx = {},
  columnsSxProps,
  filterSxProps,
  densitySxProps,
  setFilterButtonEl,
  setColumnsButtonEl,
  ...props
}: CustomGridToolbarProps) {
  const { t } = useTranslation("common");
  const { sx: xsQuickFilterProps, ...restQuickFilterProps } =
    quickFilterProps ?? {
      sx: {},
    };

  const { matches } = useBreakpoints({
    breakpoint: "md",
    option: "down",
  });

  return (
    <GridToolbarContainer
      {...props}
      sx={{
        paddingTop: 0,
        paddingLeft: 1,
        paddingRight: 1,
        rowGap: 0,
        ...customSx,
      }}
    >
      {!matches && (
        <GridToolbarColumnsButton
          ref={setColumnsButtonEl}
          sx={{ p: 0, pl: 0.45, ...columnsSxProps }}
        />
      )}
      {!matches && showFilter && (
        <GridToolbarFilterButton
          ref={setFilterButtonEl}
          componentsProps={{
            button: {
              startIcon: (
                <FilterListRoundedIcon color="inherit" fontSize="inherit" />
              ),
            },
          }}
          sx={{
            p: 0,
            ...filterSxProps,
          }}
        />
      )}
      {!matches && (
        <GridToolbarDensitySelector
          sx={{
            p: 0,
            ...densitySxProps,
          }}
        />
      )}
      {addProps && !matches && (
        <Button variant="text" startIcon={<Add />} sx={{ p: 0 }} {...addProps}>
          {t("addButton")}
        </Button>
      )}
      {addProps && matches && (
        <Tooltip title={t("addButton")}>
          <IconButton {...addProps} color="inherit">
            <Add fontSize="small" color="inherit" />
          </IconButton>
        </Tooltip>
      )}
      {refreshProps && !matches && (
        <Button startIcon={<Cached />} sx={{ p: 0 }} {...refreshProps}>
          {t("refreshButton")}
        </Button>
      )}
      {refreshProps && matches && (
        <Tooltip title={t("refreshButton")}>
          <IconButton {...refreshProps} color="inherit">
            <Cached fontSize="small" color="inherit" />
          </IconButton>
        </Tooltip>
      )}
      {showExport && (
        <GridToolbarExport
          sx={{ p: 0 }}
          csvOptions={{
            getRowsToExport: getUnfilteredRows,
            ...exportProps?.csvOptions,
          }}
          printOptions={{
            getRowsToExport: getUnfilteredRows,
            ...exportProps?.printOptions,
          }}
          {...exportProps}
        />
      )}
      <Box sx={{ flex: 1 }} />
      <GridToolbarQuickFilter
        size="small"
        sx={{
          marginTop: 0.5,
          paddingBottom: 0,
          marginBottom: 0.5,
          ".MuiInputBase-root": {
            paddingLeft: 1,
            paddingRight: 1,
          },
          ".MuiSvgIcon-root": {
            width: "1rem!important",
            height: "1rem!important",
          },
          borderRadius: 1,
          ...xsQuickFilterProps,
        }}
        variant="outlined"
        inputProps={{
          sx: {
            p: 0,
            paddingTop: "0px!important",
            paddingBottom: "0px!important",
          },
        }}
        {...restQuickFilterProps}
      />
    </GridToolbarContainer>
  );
}

Component.displayName = "CustomGridToolbar";
export const CustomGridToolbar = withProfiler(Component);
