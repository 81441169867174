import { atom } from "recoil";

export const openUserMenuState = atom<boolean>({
  key: "openUserMenuState",
  default: false,
});

export const anchorUserMenuState = atom<null | HTMLElement>({
  key: "anchorUserMenuState",
  default: null,
});

export const openLanguageMenuState = atom<boolean>({
  key: "openLanguageMenuState",
  default: false,
});

export const anchorLanguageMenuState = atom<null | HTMLElement>({
  key: "anchorLanguageMenuState",
  default: null,
});

export const anchorNotificationCenterState = atom<null | HTMLElement>({
  key: "anchorNotificationCenter",
  default: null,
});

export const showUnreadOnlyState = atom<boolean>({
  key: "showUnreadOnlyState",
  default: false,
});

export const openNotificationCenterState = atom<boolean>({
  key: "openNotificationCenterState",
  default: false,
});

export const isLogoutState = atom<boolean>({
  key: "isLogoutState",
  default: false,
});
