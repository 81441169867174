import type { CurrencyCode } from "@/utils/currencyCodes";
import { atom } from "recoil";

export const currencyFormatState = atom<CurrencyCode>({
  key: "currencyFormatState",
  default: "PAB",
});

export const currencyLocaleState = atom<{
  label: string;
  value: string;
}>({
  key: "currencyLocaleState",
  default: {
    label: "Panama",
    value: "PA",
  },
});
