import Chip from "@mui/material/Chip";
import { withProfiler } from "@sentry/react";
import { memo } from "react";
import { useLocaleString } from "../hooks/useLocaleString";
import type { MoneyFlagProps } from "../types/props";
import { FlagIcon } from "./FlagIcon";

const Component = memo(
  ({ label, countryCode = "pa", isMoney = true }: MoneyFlagProps) => {
    const { formatLocaleString } = useLocaleString();

    const flagIcon = <FlagIcon countryCode={countryCode} />;

    return (
      <Chip
        sx={{
          backgroundColor: "inherit",
          ".MuiChip-icon": {
            width: "20px",
            height: "20px",
            padding: "0.125rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
        }}
        icon={flagIcon}
        label={isMoney ? formatLocaleString(Number(label ?? 0)) : label}
      />
    );
  },
);

Component.displayName = "MoneyFlag";
export const MoneyFlag = withProfiler(Component);
