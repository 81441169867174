import { useUsersServiceGetAllUsers } from "@/queries";
import { userAtomState as userState } from "@/states/login";
import { useAtomValue } from "jotai";

export const useUsers = () => {
  const user = useAtomValue(userState);
  const { data } = useUsersServiceGetAllUsers({
    xDbCatalog: user.dbCatalog,
  });
  return { data };
};
