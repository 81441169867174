import { i18next } from "@/config/i18n";
import type {
  ActionKeyState,
  ActionMap,
  ActionPostFetch,
  ItemPostFetch,
} from "@/types";
import { toast } from "react-toastify";

export const addNotification = ({
  messageType,
  action,
  item,
  context,
}: {
  messageType: "success" | "error";
  action: ActionPostFetch;
  item: ItemPostFetch;
  context: "female" | "male";
}) => {
  const count = messageType === "success" ? 1 : 5;
  const actionMap: ActionMap = {
    add: i18next.t("common:addedAction", { count }),
    edit: i18next.t("common:editedAction", { count }),
    delete: i18next.t("common:deletedAction", { count }),
    activate: i18next.t("common:activatedAction", { count }),
    inactivate: i18next.t("common:inactivatedAction", { count }),
    mark: i18next.t("common:markAction", { count }),
    notMark: i18next.t("common:notMarkAction", { count }),
    assign: i18next.t("common:assignAction", { count }),
    finalize: i18next.t("common:finalizeAction", { count }),
    save: i18next.t("common:saveAction", { count }),
    download: i18next.t("common:downloadAction", { count }),
    call: i18next.t("common:callAction", { count }),
    pause: i18next.t("common:pauseAction", { count }),
    restart: i18next.t("common:restartAction", { count }),
    consult: i18next.t("common:consultAction", { count }),
    transfer: i18next.t("common:transferAction", { count }),
    conference: i18next.t("common:conferenceAction", { count }),
    onWait: i18next.t("common:onWaitAction", { count }),
    resuming: i18next.t("common:resumingAction", { count }),
    hangup: i18next.t("common:hangupAction", { count }),
    return: i18next.t("common:returnAction", { count }),
    change: i18next.t("common:changeAction", { count }),
    select: i18next.t("common:selectAction", { count }),
  };
  const tKey =
    `${messageType}ActionMessage` satisfies `${ActionKeyState}ActionMessage`;
  const message = i18next.t(`${messageType}ActionMessage`, {
    context,
    how: actionMap[action],
    what: i18next.t(`${item}Item`),
  });
  toast(message, {
    type: messageType,
    data: {
      tKey,
      context,
      how: action,
      what: item,
    },
  });
};
