import { useLoader } from "@/hooks/useLogin";
import LinearProgress from "@mui/material/LinearProgress";

function Component() {
  const { loadingResponse } = useLoader();

  return (
    <>
      {loadingResponse && (
        <LinearProgress
          color="secondary"
          variant="query"
          sx={{ width: "100%" }}
        />
      )}
    </>
  );
}

Component.displayName = "Loader";
export const Loader = Component;
