import "dayjs/locale/en";
import "dayjs/locale/es";
import "./config/i18n";
import "./config/wdyr";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "react-toastify/ReactToastify.min.css";
import "flag-icons/css/flag-icons.min.css";
import "./styles.css";
import { registerSW } from "virtual:pwa-register";
import { generateId } from "@/utils/generateId";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme } from "@mui/material/styles";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  captureConsoleIntegration,
  contextLinesIntegration,
  extraErrorDataIntegration,
  httpClientIntegration,
  reportingObserverIntegration,
} from "@sentry/browser";
import {
  browserTracingIntegration,
  init,
  reactRouterV6BrowserTracingIntegration,
  replayIntegration,
  wrapCreateBrowserRouter,
} from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { type ReactNode, useEffect, useMemo } from "react";
import { createRoot } from "react-dom/client";
import { useTranslation } from "react-i18next";
import {
  RouterProvider,
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { ToastContainer } from "react-toastify";
import { RecoilRoot } from "recoil";
import { DebugObserver } from "./components/DebugObserver";
import { FallbackRouter } from "./components/FallbackRouter";
import { Component as Layout } from "./components/Layout/Default";
import {
  adapterLocaleObj,
  coreLangObj,
  darkTheme,
  datePickerLangObj,
  gridLangObj,
  theme,
} from "./config/theme";
import { useConfiguration } from "./hooks/useConfiguraction";
import { Component as CatalogsMaintenance } from "./modules/catalogs-maintenance/index";
import { Component as FieldsMaintenance } from "./modules/fields-maintenance/index";
import { Component as HolidayMaintenance } from "./modules/holiday-maintenance/index";
import { Component as IntegrationMaintenace } from "./modules/integration-maintenance";
import { Component as PauseMaintenance } from "./modules/pause-maintenance/index";
import { Component as ActiveAssignedFlow } from "./pages/ActiveAssignedFlow";
import { Component as ActiveAssignedFlowJuridical } from "./pages/ActiveAssignedFlowJuridical";
import { Component as AssignLists } from "./pages/AssignLists";
import { Component as AssignedStrategies } from "./pages/AssignedStrategies";
import { Component as ClientFinder } from "./pages/ClientFinder";
import { Component as ClientManagement } from "./pages/ClientManagement";
import { Component as CreateFlow } from "./pages/CreateFlow";
import { Component as ErrorPage } from "./pages/Error";
import { Component as ExecutiveMaintenance } from "./pages/ExecutiveMaintenance";
import { Component as FlowMaintenance } from "./pages/FlowMaintenance";
import { Component as FormatMaintenance } from "./pages/FormatMaintenance";
import { Component as GroupMaintenance } from "./pages/GroupMaintenance";
import { Component as Home } from "./pages/Home";
import { Component as Login } from "./pages/Login";
import { Component as MarkClient } from "./pages/MarkClient";
import { Component as MetricsDashboard } from "./pages/MetricsDashboard";
import { Component as MetricsReport } from "./pages/MetricsReports";
import { Component as Strategies } from "./pages/Strategies";
import { Component as StrategyMaintenance } from "./pages/StrategyMaintenance";
import {
  type ClientFinderSearchB,
  type ClientFinderSearchR,
  type ClientPortfolioR,
  PagePaths,
} from "./types";
import { cacheCheck } from "./utils/cacheCheck";
import {
  getClientPortfolioUrl,
  searchClientFinderUrl,
} from "./utils/endpoints";
import { get, post } from "./utils/fetchApi";

cacheCheck();
registerSW({ immediate: true });

init({
  dsn: "https://b7aaa2cdbddc7f12e8460df2dd37eec3@o4506057370173440.ingest.us.sentry.io/4506057372401664",
  integrations: import.meta.env.DEV
    ? undefined
    : [
        httpClientIntegration({
          failedRequestStatusCodes: [400, 599],
          failedRequestTargets: [/.*/],
        }),
        extraErrorDataIntegration(),
        captureConsoleIntegration(),
        contextLinesIntegration(),
        reportingObserverIntegration(),
        browserTracingIntegration(),
        reactRouterV6BrowserTracingIntegration({
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
        replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
          maskAllInputs: false,
          networkDetailAllowUrls: [
            window.location.origin,
            /^https:\/\/(paymetest|paymedemo)\.azurewebsites\.net/,
            /\.paymesoft\.com/,
            /^https:\/\/(paymetest|paymedemo)\.azurewebsites\.net\/api/,
            /\.paymesoft\.com\/api/,
          ],
        }),
      ],
  enableTracing: true,
  tracesSampleRate: 0.1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  // This option is required for capturing headers and cookies.
  sendDefaultPii: true,
  attachStacktrace: true,
  release: "1.2.15",
  tracePropagationTargets: [
    /^https:\/\/(paymetest|paymedemo)\.azurewebsites\.net/,
    /\.paymesoft\.com/,
    /^https:\/\/(paymetest|paymedemo)\.azurewebsites\.net\/api/,
    /\.paymesoft\.com\/api/,
  ],
  beforeSend: (event, hint) => {
    const exception = hint.originalException;
    // @ts-ignore
    if (exception?.message?.match(/HTTP Client Error with status code: \d+/)) {
      // @ts-ignore
      event.fingerprint = [`${exception.message} ${hint.data.url}`];
    }
    return event;
  },
  ignoreErrors: [
    "antifingerprint not defined yet. will try and handle event after its ready...",
    /^ReportingObserver [deprecation]:/,
  ],
});

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    path: "/",
    element: <Login />,
    loader: () => {
      const body = JSON.parse(import.meta.env.VITE_COMPANY_CATALOG_JSON);
      return body ?? [];
    },
    errorElement: <ErrorPage />,
  },
  {
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: PagePaths.Home,
        element: <Home />,
        loader: async ({ request }: { request: Request }) => {
          const url = new URL(request.url);
          const searchToken = url.searchParams.get("token");
          const jwtToken = JSON.parse(
            secureLocalStorage.getItem("jwtToken") as string,
          );
          const user = JSON.parse(secureLocalStorage.getItem("user") as string);
          const safeToken = searchToken ?? jwtToken;
          // TODO: FIX content type in api
          // const configuration = new Configuration({
          //   accessToken: safeToken,
          //   headers: {
          //     "Content-Type": "application/json",
          //   },
          // });
          // const api = new ReportesApi(configuration);
          // const catalog = await api.getClientPortfolio();
          // return catalog || [];
          const headersJson = new Headers({
            "Content-Type": "application/json",
            // biome-ignore lint/style/useNamingConvention: Inentional
            Authorization: `Bearer ${safeToken}`,
            "X-DB-Catalog": user.dbCatalog,
            "X-OPERATION-ID": generateId(),
          });
          const body = await get<ClientPortfolioR[]>({
            url: getClientPortfolioUrl(),
            headers: headersJson,
          });
          return body ?? [];
        },
        errorElement: <ErrorPage />,
      },
      {
        path: PagePaths.Clients,
      },
      {
        path: PagePaths.ClientFinder,
        element: <ClientFinder />,
        // biome-ignore lint/complexity/noExcessiveCognitiveComplexity: <explanation>
        loader: ({ request }: { request: Request }) => {
          const jwtToken = JSON.parse(
            secureLocalStorage.getItem("jwtToken") as string,
          );
          const user = JSON.parse(secureLocalStorage.getItem("user") as string);
          const headersJson = new Headers({
            "Content-Type": "application/json",
            // biome-ignore lint/style/useNamingConvention: Inentional
            Authorization: `Bearer ${jwtToken}`,
            "X-DB-Catalog": user.dbCatalog,
            "X-OPERATION-ID": generateId(),
          });
          const url = new URL(request.url);
          const filter = url.searchParams.get("filter");
          const parsedSearch = url.searchParams.get("search");
          const search =
            parsedSearch && parsedSearch.length > 0 ? parsedSearch : null;
          const body = post<ClientFinderSearchR[], ClientFinderSearchB>({
            url: searchClientFinderUrl(),
            headers: headersJson,
            body: {
              contractFilter:
                filter === "Contrato" || filter === "Ninguno" ? search : null,
              clientFilter:
                filter === "Cliente" || filter === "Ninguno" ? search : null,
              nameFilter:
                filter === "Nombre Cliente" || filter === "Ninguno"
                  ? search
                  : null,
              lastNameFilter: filter === "Ninguno" ? search : null,
              currentBalanceFilter: filter === "Ninguno" ? search : null,
              overdueBalanceFilter: filter === "Ninguno" ? search : null,
              daysOverdueFilter: filter === "Ninguno" ? search : null,
              productFilter: filter === "Ninguno" ? search : null,
              clientTypeFilter: filter === "Ninguno" ? search : null,
              identificationFilter:
                filter === "Identificacion" || filter === "Ninguno"
                  ? search
                  : null,
              status1Filter:
                filter === "Estado 1" || filter === "Ninguno" ? search : null,
              status2Filter:
                filter === "Estado 2" || filter === "Ninguno" ? search : null,
              phoneNumberFilter:
                filter === "Telefono" || filter === "Ninguno" ? search : null,
            },
          });
          return body ?? [];
        },
        errorElement: <ErrorPage />,
      },
      {
        path: PagePaths.ClientManagement,
        element: <ClientManagement />,
        errorElement: <ErrorPage />,
      },
      {
        path: PagePaths.Strategies,
        element: <Strategies />,
        errorElement: <ErrorPage />,
      },
      {
        path: PagePaths.AssignedStrategies,
        element: <AssignedStrategies />,
        errorElement: <ErrorPage />,
      },
      {
        path: PagePaths.AssignLists,
        element: <AssignLists />,
        errorElement: <ErrorPage />,
      },
      {
        path: PagePaths.MarkClient,
        element: <MarkClient />,
        errorElement: <ErrorPage />,
      },
      {
        path: PagePaths.StrategyMaintenance,
        element: <StrategyMaintenance />,
        errorElement: <ErrorPage />,
      },
      {
        path: PagePaths.FormatMaintenance,
        element: <FormatMaintenance />,
        errorElement: <ErrorPage />,
      },
      {
        path: PagePaths.CatalogsMaintenance,
        element: <CatalogsMaintenance />,
        errorElement: <ErrorPage />,
      },
      {
        path: PagePaths.PauseMaintenance,
        element: <PauseMaintenance />,
        errorElement: <ErrorPage />,
      },
      {
        path: PagePaths.HolidayMaintenance,
        element: <HolidayMaintenance />,
        errorElement: <ErrorPage />,
      },
      {
        path: PagePaths.ExecutiveMaintenance,
        element: <ExecutiveMaintenance />,
        errorElement: <ErrorPage />,
      },
      {
        path: PagePaths.FlowMaintenance,
        element: <FlowMaintenance />,
        errorElement: <ErrorPage />,
      },
      {
        path: PagePaths.GroupMaintenance,
        element: <GroupMaintenance />,
        errorElement: <ErrorPage />,
      },
      {
        path: PagePaths.LoadMaintenance,
        lazy: () => import("./pages/LoadMaintenance"),
        errorElement: <ErrorPage />,
      },
      {
        path: PagePaths.Metrics,
      },
      {
        path: PagePaths.MetricsReports,
        element: <MetricsReport />,
        errorElement: <ErrorPage />,
      },
      {
        path: PagePaths.MetricsDashboard,
        element: <MetricsDashboard />,
        errorElement: <ErrorPage />,
      },
      {
        path: PagePaths.Configuration,
        lazy: () => import("./pages/Configuration"),
        errorElement: <ErrorPage />,
      },
      {
        path: PagePaths.Flows,
      },
      {
        path: PagePaths.ActiveAssignedFlows,
        element: <ActiveAssignedFlow />,
        errorElement: <ErrorPage />,
      },
      {
        path: PagePaths.CreateFlow,
        element: <CreateFlow />,
        errorElement: <ErrorPage />,
      },
      {
        path: PagePaths.Juridical,
      },
      {
        path: PagePaths.ActiveAssignedFlowsJuridical,
        element: <ActiveAssignedFlowJuridical />,
      },
      {
        path: PagePaths.FieldsMaintenance,
        element: <FieldsMaintenance />,
      },
      {
        path: PagePaths.IntegrationMaintenace,
        element: <IntegrationMaintenace />,
      },
    ],
  },
]);

const rootElement = document.getElementById("root") as HTMLElement;
const root = createRoot(rootElement);

const queryClient = new QueryClient();

function App(): ReactNode {
  const { i18n } = useTranslation();
  const { isDarkMode } = useConfiguration();
  const themei18n = useMemo(
    () =>
      createTheme(
        isDarkMode ? darkTheme : theme,
        {},
        coreLangObj[i18n.language],
        gridLangObj[i18n.language],
        datePickerLangObj[i18n.language],
      ),
    [i18n.language, isDarkMode],
  );

  return (
    <ThemeProvider theme={themei18n}>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={adapterLocaleObj[i18n.language.split("-")[0]]}
      >
        <CssBaseline />
        <ToastContainer position="bottom-right" newestOnTop={true} />
        <RouterProvider router={router} fallbackElement={<FallbackRouter />} />
      </LocalizationProvider>
    </ThemeProvider>
  );
}

root.render(
  <QueryClientProvider client={queryClient}>
    <RecoilRoot>
      {import.meta.env.DEV && <DebugObserver />}
      <App />
    </RecoilRoot>
  </QueryClientProvider>,
);
