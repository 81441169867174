import { useCompleteResetPassword } from "@/hooks/useLogin";
import SendIcon from "@mui/icons-material/Send";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

function Component() {
  const {
    loadingResponse,
    showTypeResetPasswordCodePrompt,
    userReset,
    handleTextFieldChange2,
    handleSendResetPasswordCode,
  } = useCompleteResetPassword();
  const { t } = useTranslation("login");

  return (
    <>
      {showTypeResetPasswordCodePrompt && (
        <>
          <Typography
            component="h1"
            variant="h5"
            fontWeight="bold"
            align="left"
          >
            {t("resetPasswordCodeTitle")}
          </Typography>
          <Typography component="p" align="left" mt={1}>
            {t("resetPasswordCodeSubtitle")}
          </Typography>
          <TextField
            disabled={loadingResponse}
            margin="normal"
            required={true}
            fullWidth={true}
            name="token"
            label={t("resetPasswordCodeToken")}
            id="token"
            autoComplete="token"
            value={userReset.token}
            onChange={handleTextFieldChange2("token")}
          />
          <TextField
            disabled={loadingResponse}
            margin="normal"
            required={true}
            fullWidth={true}
            name="newPassword"
            label={t("newPassword")}
            type="password"
            id="newPassword"
            autoComplete="current-password"
            value={userReset.newPassword}
            onChange={handleTextFieldChange2("newPassword")}
          />
          <Button
            disabled={loadingResponse}
            type="button"
            fullWidth={true}
            variant="contained"
            startIcon={<SendIcon />}
            sx={{ mt: 3, mb: 2 }}
            onClick={handleSendResetPasswordCode}
          >
            {t("resetPasswordCodeButton")}
          </Button>
        </>
      )}
    </>
  );
}

Component.displayName = "CompleteResetPassword";
export const CompleteResetPassword = Component;
