import { atom, atomFamily } from "recoil";

export const isMenuOpenState = atomFamily<boolean, { id: string }>({
  key: "isMenuOpen",
  default: false,
});

export const openedMenuValueState = atom<string>({
  key: "openedMenuValue",
  default: "",
});
