import { FallbackComponent } from "@/components/FallbackComponent";
import { Form } from "@/components/Login/Form";
import { Hero } from "@/components/Login/Hero";
import Box from "@mui/material/Box";
import { ErrorBoundary } from "@sentry/react";
import { StrictMode } from "react";

export function Component() {
  return (
    <StrictMode>
      <Box
        alignItems="stretch"
        display="flex"
        sx={{
          flexDirection: {
            xs: "column",
            md: "row",
          },
          height: {
            xs: "auto",
            md: "100vh",
          },
          minHeight: {
            xs: "min-content",
            md: "700px",
          },
          backgroundColor: "white.main",
        }}
      >
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("login", "hero");
          }}
          fallback={FallbackComponent}
        >
          <Hero />
        </ErrorBoundary>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("login", "form");
          }}
          fallback={FallbackComponent}
        >
          <Form />
        </ErrorBoundary>
      </Box>
    </StrictMode>
  );
}

Component.displayName = "Login";
