import type { SearchResultsB } from "@/types";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";

export const useSearchResults = () => {
  const [searchParams, _] = useSearchParams();
  const filter = searchParams.get("filter") ?? "Ninguno";
  const texto = searchParams.get("search") ?? "";
  const [searchResults, setSearchResults] = useState<SearchResultsB>({
    filter,
    texto,
  });

  return { searchResults, setSearchResults };
};
