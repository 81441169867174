import { useAppVariablesByTypeAndUserId } from "@/common/AppVariables/hooks/useAppVariables";
import { FallbackComponent } from "@/components/FallbackComponent";
import { LoadingAccess } from "@/components/LoadingAccess";
import { Unauthorized } from "@/components/Unauthorized";
import { useIntegrationMaintenance } from "@/hooks/usePages";
import type { VitalPbxClickToCallMaintenance } from "@/types/vitalPbx";
import reactLazyWithRetry from "@fatso83/retry-dynamic-import/react-lazy";
import { zodResolver } from "@hookform/resolvers/zod";
import Skeleton from "@mui/material/Skeleton";
import { ErrorBoundary } from "@sentry/react";
import { Suspense, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";
import { useSelect } from "./hooks/useSelect";

const Select = reactLazyWithRetry(async () => {
  return {
    default: (await import("./components/Select")).Select,
  };
});

const VitalPbx = reactLazyWithRetry(async () => {
  return {
    default: (await import("./components/VitalPbx")).VitalPbx,
  };
});

export const schemaVitalPbx = z.object({
  caller: z.coerce.number().nonnegative(),
  // biome-ignore lint/style/useNamingConvention: This is a third-party API
  cos_id: z.coerce.number().nonnegative(),
  // biome-ignore lint/style/useNamingConvention: This is a third-party API
  cid_name: z.string().max(255),
  // biome-ignore lint/style/useNamingConvention: This is a third-party API
  cid_number: z.coerce.number().nonnegative(),
  "X-APP": z.string().max(255),
  "X-CAMPAIGN": z.string().max(255),
  "app-key": z.string().min(1).max(255),
  tenant: z.string().min(1).max(255),
  host: z.string().min(1).max(255),
  action: z.enum(["add", "edit", "delete"]),
});

type Field =
  | "caller"
  | "cos_id"
  | "cid_name"
  | "cid_number"
  | "X-APP"
  | "X-CAMPAIGN"
  | "app-key"
  | "tenant"
  | "host";

const idToFieldMap = {
  1: "caller",
  2: "cid_name",
  3: "cid_number",
  4: "X-APP",
  5: "X-CAMPAIGN",
  6: "app-key",
  7: "tenant",
  8: "host",
  9: "cos_id",
} satisfies Record<string, Field>;

export function Component() {
  const { selectedIntegration, loadingPermission, hasViewPermission } =
    useIntegrationMaintenance();
  const { selectedUser } = useSelect();
  const { data: vitalPbxData } = useAppVariablesByTypeAndUserId(
    "vitalPBX",
    selectedUser,
  );

  const methodsVitalPbx = useForm<VitalPbxClickToCallMaintenance>({
    defaultValues: {
      caller: 507,
      // biome-ignore lint/style/useNamingConvention: This is a third-party API
      cos_id: 0,
      // biome-ignore lint/style/useNamingConvention: This is a third-party API
      cid_name: "",
      // biome-ignore lint/style/useNamingConvention: This is a third-party API
      cid_number: 507,
      "X-APP": "",
      "X-CAMPAIGN": "",
      "app-key": "",
      tenant: "vitalPBX",
      host: "",
      action: "edit",
    },
    resolver: zodResolver(schemaVitalPbx),
  });

  const { setValue } = methodsVitalPbx;

  // biome-ignore lint/complexity/noExcessiveCognitiveComplexity: TODO: Check later
  useEffect(() => {
    if (vitalPbxData) {
      for (const [_id, field] of Object.entries(idToFieldMap)) {
        const item = vitalPbxData.find(
          (item) => item.userId === selectedUser && item.variableName === field,
        )?.variableValue;

        if (
          field === "caller" ||
          field === "cid_number" ||
          field === "cos_id"
        ) {
          setValue(
            field,
            typeof item === "string" && !Number.isNaN(Number.parseInt(item))
              ? Number.parseInt(item)
              : 507,
          );
        } else {
          setValue(field, item ?? "");
        }
      }
    }
  }, [vitalPbxData, setValue, selectedUser]);

  if (loadingPermission) {
    return <LoadingAccess />;
  }

  if (!hasViewPermission) {
    return <Unauthorized />;
  }
  return (
    <>
      <ErrorBoundary
        beforeCapture={(scope) => {
          scope.setTag("component", "select");
        }}
        fallback={FallbackComponent}
      >
        <Suspense
          fallback={<Skeleton variant="rounded" width="100%" height={90} />}
        >
          <Select />
        </Suspense>
      </ErrorBoundary>
      {selectedIntegration === "vitalPBX" && (
        <FormProvider {...methodsVitalPbx}>
          <ErrorBoundary
            beforeCapture={(scope) => {
              scope.setTag("component", "vitalPbx");
            }}
            fallback={FallbackComponent}
          >
            <Suspense
              fallback={<Skeleton variant="rounded" width="100%" height={90} />}
            >
              <VitalPbx />
            </Suspense>
          </ErrorBoundary>
        </FormProvider>
      )}
    </>
  );
}

Component.displayName = "IntegrationMaintenace";
