import { FallbackComponent } from "@/components/FallbackComponent";
import { LoadingAccess } from "@/components/LoadingAccess";
import { Unauthorized } from "@/components/Unauthorized";
import { useFieldsMaintenance } from "@/hooks/usePages";
import reactLazyWithRetry from "@fatso83/retry-dynamic-import/react-lazy";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import { ErrorBoundary } from "@sentry/react";
import { Suspense } from "react";

const FieldsTreeView = reactLazyWithRetry(async () => {
  return {
    default: (await import("./components/FieldsTreeView")).FieldsTreeView,
  };
});

const FieldsModification = reactLazyWithRetry(async () => {
  return {
    default: (await import("./components/FieldsModification"))
      .FieldsModification,
  };
});

export const Component = () => {
  const { loadingPermission, hasViewPermission } = useFieldsMaintenance();
  if (loadingPermission) {
    return <LoadingAccess />;
  }

  if (!hasViewPermission) {
    return <Unauthorized />;
  }

  return (
    <ErrorBoundary
      beforeCapture={(scope) => {
        scope.setTag("component", "fieldsTreeView & fieldsModification");
      }}
      fallback={FallbackComponent}
    >
      <Box
        display="flex"
        gap={2}
        flexDirection={{ md: "column", lg: "row" }}
        maxHeight="100%"
      >
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "fieldsTreeView");
          }}
          fallback={FallbackComponent}
        >
          <Suspense
            fallback={<Skeleton variant="rounded" width="100%" height={90} />}
          >
            <FieldsTreeView />
          </Suspense>
        </ErrorBoundary>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "fieldsModification");
          }}
          fallback={FallbackComponent}
        >
          <Suspense
            fallback={<Skeleton variant="rounded" width="100%" height={90} />}
          >
            <FieldsModification />
          </Suspense>
        </ErrorBoundary>
      </Box>
    </ErrorBoundary>
  );
};
