import { scrollTopState, showSidebarState } from "@/states/layout";
import { useRecoilValue, useSetRecoilState } from "recoil";

export const useLayout = () => {
  const setScrollTop = useSetRecoilState(scrollTopState);
  const showSidebar = useRecoilValue(showSidebarState);
  return {
    setScrollTop,
    showSidebar,
  };
};
