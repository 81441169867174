export const checkUnauthorized = (
  error: unknown,
): error is { response: Response } => {
  return (
    typeof error === "object" &&
    error !== null &&
    "response" in error &&
    error.response instanceof Response &&
    error.response.status === 401
  );
};
