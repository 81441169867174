import { addNotification } from "@/common/utils/notify";
import {
  dateFormatState,
  isDarkModeState,
  modeState,
  resetPasswordLoadingResponseState,
  resetPasswordState,
} from "@/states/configuration";
import {  enableSegmentationV2State
}from "@/states/pages";
import {
  currencyFormatState,
  currencyLocaleState,
} from "@/states/localeString";
import { headersJsonState, userState } from "@/states/login";
import type { PasswordChangeRequest } from "@/types/access";
import type { CurrencyCode } from "@/utils/currencyCodes";
import { accessUrl } from "@/utils/endpoints";
import { postWithoutJsonResponse } from "@/utils/fetchApi";
import type { AutocompleteProps } from "@mui/material/Autocomplete";
import type { SelectProps } from "@mui/material/Select";
import type { MouseEventHandler } from "react";
import type { ChangeEvent } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { useRecoilCallback } from "recoil";

export const useConfiguration = () => {
  const [mode, setMode] = useRecoilState(modeState);
  const isDarkMode = useRecoilValue(isDarkModeState);
  const [dateFormat, setDateFormat] = useRecoilState(dateFormatState);
  const [resetPassword, setResetPassword] = useRecoilState(resetPasswordState);
  const headersJson = useRecoilValue(headersJsonState);
  const [loadingResponse, setLoadingResponse] = useRecoilState(
    resetPasswordLoadingResponseState,
  );
  const [enableSegmentationV2, setEnableSegmentatonV2] = useRecoilState(
    enableSegmentationV2State,
  );

  const handleModeChange: MouseEventHandler<HTMLButtonElement> = (event) => {
    setMode(event.currentTarget.value as typeof mode);
  };

  const handleSegmentationV2Change = (event: ChangeEvent<HTMLInputElement>) => {
    setEnableSegmentatonV2(event.target.checked);
  };

  const [currencyFormat, setCurrencyFormat] =
    useRecoilState(currencyFormatState);
  const [currencyLocale, setCurrencyLocale] =
    useRecoilState(currencyLocaleState);

  const handleCurrencyFormatChange: AutocompleteProps<
    CurrencyCode,
    false,
    false,
    false
  >["onChange"] = (_event, newValue) => {
    if (newValue) {
      setCurrencyFormat(newValue);
    }
  };

  const handleCurrencyLocaleChange: AutocompleteProps<
    { label: string; value: string },
    false,
    false,
    false
  >["onChange"] = (_event, newValue) => {
    if (newValue) {
      setCurrencyLocale(newValue);
    }
  };

  const handleDatePart1Change: SelectProps<string>["onChange"] = (e) => {
    const [_, part2, part3] = dateFormat.split("/");
    setDateFormat(`${e.target.value}/${part2}/${part3}`);
  };

  const handleDatePart2Change: SelectProps<string>["onChange"] = (e) => {
    const [part1, _, part3] = dateFormat.split("/");
    setDateFormat(`${part1}/${e.target.value}/${part3}`);
  };

  const handleDatePart3Change: SelectProps<string>["onChange"] = (e) => {
    const [part1, part2, _] = dateFormat.split("/");
    setDateFormat(`${part1}/${part2}/${e.target.value}`);
  };

  const handleOldPasswordTextField = (event: ChangeEvent<HTMLInputElement>) => {
    setResetPassword((prev) => ({
      ...prev,
      oldPassword: event.target.value,
    }));
  };

  const handleNewPasswordTextField = (event: ChangeEvent<HTMLInputElement>) => {
    setResetPassword((prev) => ({
      ...prev,
      newPassword: event.target.value,
    }));
  };

  const handleResetPasswordButton: MouseEventHandler<HTMLButtonElement> =
    useRecoilCallback(
      ({ snapshot, reset }) =>
        async (e) => {
          e.preventDefault();
          setLoadingResponse(true);
          const user = await snapshot.getPromise(userState);
          const resetPassword = await snapshot.getPromise(resetPasswordState);
          postWithoutJsonResponse<PasswordChangeRequest>({
            url: `${accessUrl()}/change-password`,
            headers: headersJson,
            body: {
              username: user.userName,
              oldPassword: resetPassword.oldPassword,
              newPassword: resetPassword.newPassword,
            },
          }).then((response) => {
            if (response.ok) {
              setLoadingResponse(false);
              reset(resetPasswordState);
              addNotification({
                messageType: "success",
                action: "change",
                item: "password",
                context: "female",
              });
            } else {
              setLoadingResponse(false);
              reset(resetPasswordState);
              addNotification({
                messageType: "error",
                action: "change",
                item: "password",
                context: "female",
              });
            }
          });
        },
      [],
    );

  return {
    mode,
    isDarkMode,
    handleModeChange,
    handleCurrencyFormatChange,
    handleCurrencyLocaleChange,
    currencyFormat,
    currencyLocale,
    dateFormat,
    handleDatePart1Change,
    handleDatePart2Change,
    handleDatePart3Change,
    resetPassword,
    handleOldPasswordTextField,
    handleNewPasswordTextField,
    handleResetPasswordButton,
    loadingResponse,
    enableSegmentationV2,
    handleSegmentationV2Change
  };
};
