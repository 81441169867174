import { FallbackComponent } from "@/components/FallbackComponent";
import { LoadingAccess } from "@/components/LoadingAccess";
import { Unauthorized } from "@/components/Unauthorized";
import { useGroupMaintenance } from "@/hooks/usePages";
import reactLazyWithRetry from "@fatso83/retry-dynamic-import/react-lazy";
import Skeleton from "@mui/material/Skeleton";
import { ErrorBoundary } from "@sentry/react";
import { Suspense, StrictMode } from "react";

const GroupEditor = reactLazyWithRetry(async () => {
  return {
    default: (await import("@/components/GroupMaintenance/GroupEditor"))
      .GroupEditor,
  };
});

const Groups = reactLazyWithRetry(async () => {
  return {
    default: (await import("@/components/GroupMaintenance/Groups")).Groups,
  };
});

export const Component = () => {
  const { hasViewPermission, loadingPermission } = useGroupMaintenance();

  if (loadingPermission) {
    return <LoadingAccess />;
  }

  if (!hasViewPermission) {
    return <Unauthorized />;
  }

  return (
    <>
      <StrictMode>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "groups");
          }}
          fallback={FallbackComponent}
        >
          <Suspense
            fallback={<Skeleton variant="rounded" width="100%" height={341} />}
          >
            <Groups />
          </Suspense>
        </ErrorBoundary>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "groupeditor");
          }}
          fallback={FallbackComponent}
        >
          <Suspense
            fallback={<Skeleton variant="rounded" width="100%" height={125} />}
          >
            <GroupEditor />
          </Suspense>
        </ErrorBoundary>
      </StrictMode>
    </>
  );
};

Component.displayName = "GroupMaintenance";
