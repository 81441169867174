import { useHero } from "@/hooks/useLogin";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { useLoaderData } from "react-router-dom";

const Component = () => {
  const { user } = useHero();
  const companies = useLoaderData() as {
    company: string;
    catalog: string;
  }[];
  const [showCompany, setShowCompany] = useState(false);
  const [bgImage, setBgImage] = useState(
    `https://res.cloudinary.com/dw3j14m2k/image/upload/f_auto,q_auto/${companies?.[0]?.company}`,
  );
  const handleError = () => {
    setBgImage(
      "https://res.cloudinary.com/dw3j14m2k/image/upload/f_auto,q_auto/default",
    );
    setShowCompany(true);
  };
  return (
    <Box
      width="fill-available"
      alignItems="center"
      display="flex"
      justifyContent="center"
      maxWidth={{ xs: "100%", md: "50%" }}
      height={{ xs: "200px", md: "100%" }}
      minHeight={{ xs: "200px", md: "700px" }}
      sx={{
        backgroundImage: `url(${bgImage})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <img
        src={bgImage}
        onError={handleError}
        style={{ display: "none" }}
        alt="bgImage"
      />
      {showCompany && user.company && (
        <Typography
          variant="h1"
          textAlign="center"
          bgcolor="white.main"
          p={3}
          sx={{
            m: {
              xs: 3,
              md: 0,
            },
            fontSize: {
              xs: "calc(2vw + 2vh)",
              md: "3.5rem",
            },
          }}
          borderRadius="4px"
          width="min-content"
        >
          {user.company}
        </Typography>
      )}
    </Box>
  );
};

Component.displayName = "Hero";
export const Hero = Component;
