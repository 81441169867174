import { type MenuItem, PagePaths } from "@/types";
import DonutLarge from "@mui/icons-material/DonutLarge";
import DonutLargeOutlinedIcon from "@mui/icons-material/DonutLargeOutlined";
import GavelIcon from "@mui/icons-material/Gavel";
import GavelOutlinedIcon from "@mui/icons-material/GavelOutlined";
import Home from "@mui/icons-material/Home";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import Person from "@mui/icons-material/Person";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import Psychology from "@mui/icons-material/Psychology";
import PsychologyOutlinedIcon from "@mui/icons-material/PsychologyOutlined";
import {
  default as Speed,
  default as SpeedOutlinedIcon,
} from "@mui/icons-material/SpeedOutlined";
import SupportAgent from "@mui/icons-material/SupportAgent";
import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
import { useTranslation } from "react-i18next";
import { createSearchParams } from "react-router-dom";

export const useMenuItems = () => {
  const { t } = useTranslation("topBar");
  return [
    {
      icon: <Home htmlColor="inherit" fontSize="inherit" />,
      iconOutlined: <HomeOutlinedIcon htmlColor="inherit" fontSize="inherit" />,
      title: t("home"),
      to: PagePaths.Home,
      items: [],
    },
    {
      icon: <Person htmlColor="white.main" fontSize="inherit" />,
      iconOutlined: (
        <PersonOutlineOutlinedIcon htmlColor="white.main" fontSize="inherit" />
      ),
      title: t("clients"),
      to: "#",
      items: [
        {
          title: t("clientManagement"),
          to: `${PagePaths.ClientFinder}?${createSearchParams({
            filter: "Ninguno",
            search: "",
          })}`,
          items: [],
        },
      ],
    },
    {
      icon: <SupportAgent htmlColor="white.main" fontSize="inherit" />,
      iconOutlined: (
        <SupportAgentOutlinedIcon htmlColor="white.main" fontSize="inherit" />
      ),
      title: t("management"),
      to: "#",
      items: [
        {
          title: t("strategies"),
          to: PagePaths.Strategies,
          items: [],
        },
        {
          title: t("assignedStrategies"),
          to: PagePaths.AssignedStrategies,
          items: [],
        },
        {
          title: t("markClient"),
          to: PagePaths.MarkClient,
          items: [],
        },
        {
          title: t("assignLists"),
          to: PagePaths.AssignLists,
          items: [],
        },
      ],
    },
    {
      icon: <GavelIcon htmlColor="white.main" fontSize="inherit" />,
      iconOutlined: (
        <GavelOutlinedIcon htmlColor="white.main" fontSize="inherit" />
      ),
      title: t("juridical"),
      to: "#",
      items: [
        {
          title: t("activeAssignedFlowsJuridical"),
          to: PagePaths.ActiveAssignedFlowsJuridical,
          items: [],
        },
      ],
    },
    {
      icon: <DonutLarge htmlColor="white.main" fontSize="inherit" />,
      iconOutlined: (
        <DonutLargeOutlinedIcon htmlColor="white.main" fontSize="inherit" />
      ),
      title: t("operatingFlows"),
      to: "#",
      items: [
        {
          title: t("activeAssignedFlows"),
          to: PagePaths.ActiveAssignedFlows,
          items: [],
        },
        {
          title: t("createFlow"),
          to: PagePaths.CreateFlow,
          items: [],
        },
      ],
    },
    {
      icon: <Speed htmlColor="white.main" fontSize="inherit" />,
      iconOutlined: (
        <SpeedOutlinedIcon htmlColor="white.main" fontSize="inherit" />
      ),
      title: t("metrics"),
      to: "#",
      items: [
        {
          title: t("metricsReports"),
          to: PagePaths.MetricsReports,
          items: [],
        },
        {
          title: t("metricsDashboard"),
          to: PagePaths.MetricsDashboard,
          items: [],
        },
      ],
    },
    {
      icon: <Psychology htmlColor="white.main" fontSize="inherit" />,
      iconOutlined: (
        <PsychologyOutlinedIcon htmlColor="white.main" fontSize="inherit" />
      ),
      title: t("master"),
      to: "#",
      items: [
        {
          title: t("strategyMaintenance"),
          to: PagePaths.StrategyMaintenance,
          items: [],
        },
        {
          title: t("formatMaintenance"),
          to: PagePaths.FormatMaintenance,
          items: [],
        },
        {
          title: t("catalogsMaintenance"),
          to: PagePaths.CatalogsMaintenance,
          items: [],
        },
        {
          title: t("pauseMaintenance"),
          to: PagePaths.PauseMaintenance,
          items: [],
        },
        {
          title: t("holidayMaintenance"),
          to: PagePaths.HolidayMaintenance,
          items: [],
        },
        {
          title: t("executiveMaintenance"),
          to: PagePaths.ExecutiveMaintenance,
          items: [],
        },
        {
          title: t("flowMaintenance"),
          to: PagePaths.FlowMaintenance,
          items: [],
        },
        {
          title: t("groupMaintenance"),
          to: PagePaths.GroupMaintenance,
          items: [],
        },
        {
          title: t("loadMaintenance"),
          to: PagePaths.LoadMaintenance,
          items: [],
        },
        {
          title: t("fieldsMaintenance"),
          to: PagePaths.FieldsMaintenance,
          items: [],
        },
        {
          title: t("integrationsMaintenance"),
          to: PagePaths.IntegrationMaintenace,
          items: [],
        },
      ],
    },
  ] satisfies MenuItem[];
};
