import { FallbackComponent } from "@/components/FallbackComponent";
import { LoadingAccess } from "@/components/LoadingAccess";
import { Unauthorized } from "@/components/Unauthorized";
import { useClientFinder } from "@/hooks/usePages";
import reactLazyWithRetry from "@fatso83/retry-dynamic-import/react-lazy";
import Skeleton from "@mui/material/Skeleton";
import { ErrorBoundary } from "@sentry/react";
import { Suspense, StrictMode } from "react";

const Search = reactLazyWithRetry(async () => {
  return {
    default: (await import("@/components/ClientFinder/Search")).Search,
  };
});

const SearchResults = reactLazyWithRetry(async () => {
  return {
    default: (await import("@/components/ClientFinder/SearchResults"))
      .SearchResults,
  };
});
export function Component() {
  const { hasViewPermission, loadingPermission } = useClientFinder();

  if (loadingPermission) {
    return <LoadingAccess />;
  }

  if (!hasViewPermission) {
    return <Unauthorized />;
  }
  return (
    <>
      <StrictMode>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "search");
          }}
          fallback={FallbackComponent}
        >
          <Suspense
            fallback={<Skeleton variant="rounded" width="100%" height={90} />}
          >
            <Search />
          </Suspense>
        </ErrorBoundary>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "searchresults");
          }}
          fallback={FallbackComponent}
        >
          <Suspense
            fallback={<Skeleton variant="rounded" width="100%" height={283} />}
          >
            <SearchResults />
          </Suspense>
        </ErrorBoundary>
      </StrictMode>
    </>
  );
}

Component.displayName = "ClientFinder";
