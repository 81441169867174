import type {
  GroupsR,
  OperatingFlowsR,
  RolesPermissionsR,
  RolesR,
  UsersR,
} from "@/types";
import type { OpenContentProps } from "@/types/props";
import {
  groupsUrl,
  operatingFlowsUrl,
  rolesPermissionsUrl,
  rolesUrl,
  usersUrl,
} from "@/utils/endpoints";
import { get } from "@/utils/fetchApi";
import { captureMessage } from "@sentry/react";
import { atom } from "jotai";
import { atomFamily as jotaiAtomFamily } from "jotai/utils";
import { atomFamily, selector } from "recoil";
import { headersJsonState, userState } from "./login";

/**
 * @deprecated use `toggleContentAtom` instead
 */
export const openContentAtom = jotaiAtomFamily(
  ({ open }: OpenContentProps) => atom({ open: open ?? true }),
  (a, b) => a.id === b.id,
);

/**
 * @deprecated use `toggleContentAtom` instead
 */
export const closeContentAtom = jotaiAtomFamily(
  ({ open }: OpenContentProps) => atom({ open: open ?? false }),
  (a, b) => a.id === b.id,
);

export const openContentState = atomFamily<
  boolean,
  {
    id: string;
  }
>({
  key: "openContentCommon",
  default: true,
});

export const confirmationState = atomFamily<
  boolean,
  {
    id: string;
  }
>({
  key: "confirmacionCommon",
  default: false,
});

export const uploadedfileState = atomFamily<
  File | null,
  {
    id: string;
  }
>({
  key: "uploadedfileCommon",
  default: null,
});

export const rolesState = selector<RolesR[]>({
  key: "rolesCommon",
  get: async ({ get: recoilGet }) => {
    const headersJson = recoilGet(headersJsonState);
    const body = await get<RolesR[]>({
      url: rolesUrl(),
      headers: headersJson,
    });
    return body ?? [];
  },
});

export const groupsState = selector<GroupsR[]>({
  key: "groupsCommon",
  get: async ({ get: recoilGet }) => {
    const headersJson = recoilGet(headersJsonState);
    const body = await get<GroupsR[]>({
      url: groupsUrl(),
      headers: headersJson,
    });
    return body ?? [];
  },
});

export const flowsCommonState = selector<OperatingFlowsR[]>({
  key: "flowsCommon",
  get: async ({ get: recoilGet }) => {
    const headersJson = recoilGet(headersJsonState);
    const body = await get<OperatingFlowsR[]>({
      url: operatingFlowsUrl(),
      headers: headersJson,
    });
    return body ?? [];
  },
});

const usersRowsState = selector<UsersR[]>({
  key: "ejecutivosCommon",
  get: async ({ get: recoilGet }) => {
    const headersJson = recoilGet(headersJsonState);
    const body = await get<UsersR[]>({
      url: `${usersUrl()}`,
      headers: headersJson,
    });
    return body ?? [];
  },
});

export const rolePermissionsRowsState = selector<RolesPermissionsR[]>({
  key: "rolePermissionsCommon",
  get: async ({ get: recoilGet }) => {
    const headersJson = recoilGet(headersJsonState);
    const users = recoilGet(usersRowsState);
    const loggedInUser = recoilGet(userState);
    const currentUser = users.find(
      (user) => user.userCode === loggedInUser.userName,
    )?.userRole;
    let joinedResponses: RolesPermissionsR[] = [];
    if (typeof currentUser === "string") {
      const userRoles = currentUser.split(",");
      if (userRoles.length > 0) {
        const responses = await Promise.all(
          userRoles.map(async (userRole) => {
            const body = await get<RolesPermissionsR[]>({
              url: `${rolesPermissionsUrl()}/${userRole.trim()}`,
              headers: headersJson,
            });
            if (!body) {
              captureMessage(
                `User role ${userRole} has no permissions`,
                "error",
              );
            }
            return body;
          }),
        );
        const filteredResponses = responses.filter(
          (response) => response !== null,
        ) as RolesPermissionsR[][];
        joinedResponses = ([] as RolesPermissionsR[]).concat(
          ...filteredResponses,
        );
      }
    }
    return joinedResponses;
  },
});

export const isFirstRenderState = atomFamily<boolean, { id: string }>({
  key: "firstRenderCommon",
  default: true,
});
