import { useUsers } from "@/common/Users/hooks/useHooks";
import { selectedIntegrationState } from "@/states/pages";
import type { AppVariableType } from "@/types";
import type { SelectProps } from "@mui/material/Select";
import { useAtom } from "jotai";
import { selectedIntegrationUserState } from "../atoms";

export const useSelect = () => {
  const filters = [
    {
      label: "Vital PBX",
      value: "vitalPBX" satisfies AppVariableType,
    },
  ];
  const [selectedFilter, setSelectedFilter] = useAtom(selectedIntegrationState);
  const [selectedUser, setSelectedUser] = useAtom(selectedIntegrationUserState);
  const { data: usersData } = useUsers();

  const handleSelectFilterChange: SelectProps<string>["onChange"] = (e) =>
    setSelectedFilter(e.target.value);

  const handleSelectUserChange: SelectProps<number>["onChange"] = (e) =>
    // @ts-expect-error Fix later
    setSelectedUser(e.target.value);

  const users =
    usersData?.map((user) => ({
      // biome-ignore lint/style/noNonNullAssertion: Fix later
      label: user.userName!,
      // biome-ignore lint/style/noNonNullAssertion: Fix later
      value: user.sequential!,
    })) ?? [];

  return {
    filters,
    users,
    selectedFilter,
    handleSelectFilterChange,
    selectedUser,
    handleSelectUserChange,
  };
};
