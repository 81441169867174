import { FallbackComponent } from "@/components/FallbackComponent";
import { LoadingAccess } from "@/components/LoadingAccess";
import { Unauthorized } from "@/components/Unauthorized";
import { useActiveAssignFlow, useActiveAssignFlows } from "@/hooks/usePages";
import reactLazyWithRetry from "@fatso83/retry-dynamic-import/react-lazy";
import Skeleton from "@mui/material/Skeleton";
import { ErrorBoundary } from "@sentry/react";
import { Suspense, StrictMode } from "react";

const id = "Regular";

const SearchOptions = reactLazyWithRetry(async () => {
  return {
    default: (await import("@/components/ActiveAssignedFlow/SearchOptions"))
      .SearchOptions,
  };
});

const SearchViews = reactLazyWithRetry(async () => {
  return {
    default: (await import("@/components/ActiveAssignedFlow/SearchViews"))
      .SearchViews,
  };
});

const OperatingFlowsByState = reactLazyWithRetry(async () => {
  return {
    default: (
      await import("@/components/ActiveAssignedFlow/OperatingFlowByState")
    ).OperatingFlowsByState,
  };
});

const ContractDetails = reactLazyWithRetry(async () => {
  return {
    default: (await import("@/components/ActiveAssignedFlow/ContractDetails"))
      .ContractDetails,
  };
});

const AssignFlow = reactLazyWithRetry(async () => {
  return {
    default: (await import("@/components/ActiveAssignedFlow/AssignFlow"))
      .AssignFlow,
  };
});

const ContractData = reactLazyWithRetry(async () => {
  return {
    default: (await import("@/components/ActiveAssignedFlow/ContractData"))
      .ContractData,
  };
});

const FlowOptions = reactLazyWithRetry(async () => {
  return {
    default: (await import("@/components/ActiveAssignedFlow/FlowOptions"))
      .FlowOptions,
  };
});

const FlowOptionsViews = reactLazyWithRetry(async () => {
  return {
    default: (await import("@/components/ActiveAssignedFlow/FlowOptionsViews"))
      .FlowOptionsViews,
  };
});

export const Component = () => {
  const {
    selectedFlowContractDetailsRow,
    selectedRowFlowByState,
    flowsByStateRows,
  } = useActiveAssignFlows({
    id: "Regular",
  });
  const { hasViewPermission, loadingPermission } = useActiveAssignFlow();

  if (loadingPermission) {
    return <LoadingAccess />;
  }

  if (!hasViewPermission) {
    return <Unauthorized />;
  }

  return (
    <>
      <StrictMode>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "searchOptions");
          }}
          fallback={FallbackComponent}
        >
          <Suspense fallback={<Skeleton height={100} />}>
            <SearchOptions />
          </Suspense>
        </ErrorBoundary>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "searchViews");
          }}
          fallback={FallbackComponent}
        >
          <Suspense fallback={<Skeleton height={100} />}>
            <SearchViews id={id} />
          </Suspense>
        </ErrorBoundary>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "operatingflowsbystate");
          }}
          fallback={FallbackComponent}
        >
          <Suspense fallback={<Skeleton height={191} />}>
            {flowsByStateRows.length > 0 && <OperatingFlowsByState id={id} />}
          </Suspense>
        </ErrorBoundary>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "assignflow");
          }}
          fallback={FallbackComponent}
        >
          <Suspense fallback={<Skeleton height={106} />}>
            {selectedRowFlowByState?.codigoFlujo !== "" && (
              <AssignFlow id={id} />
            )}
          </Suspense>
        </ErrorBoundary>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "contractdetails");
          }}
          fallback={FallbackComponent}
        >
          <Suspense fallback={<Skeleton height={191} />}>
            {selectedRowFlowByState?.codigoFlujo !== "" && (
              <ContractDetails id={id} />
            )}
          </Suspense>
        </ErrorBoundary>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "contractdata");
          }}
          fallback={FallbackComponent}
        >
          <Suspense fallback={<Skeleton height={81} />}>
            {selectedFlowContractDetailsRow.contrato !== "" && (
              <ContractData id={id} />
            )}
          </Suspense>
        </ErrorBoundary>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "flowoptions");
          }}
          fallback={FallbackComponent}
        >
          <Suspense fallback={<Skeleton height={92} />}>
            {selectedFlowContractDetailsRow.contrato !== "" && (
              <FlowOptions id={id} />
            )}
          </Suspense>
        </ErrorBoundary>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "swipeableviews");
          }}
          fallback={FallbackComponent}
        >
          <Suspense fallback={<Skeleton height={92} />}>
            <FlowOptionsViews id={id} />
          </Suspense>
        </ErrorBoundary>
      </StrictMode>
    </>
  );
};
