import {
  alertState,
  jwtTokenState,
  loadingResponseState,
  loggedInState,
  sessionState,
  showOtpState,
  showResetPasswordPromptState,
  showTypeResetPasswordCodePromptState,
  userState,
} from "@/states/login";
import { isLogoutState } from "@/states/topBar";
import type { LocationProps } from "@/types/props";
import { sessionInfoUrl } from "@/utils/endpoints";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { captureException, withProfiler } from "@sentry/react";
import {
  type ReactElement,
  useEffect,
  useLayoutEffect,
  useState,
  useCallback,
} from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";

const Component = ({ children }: { children: ReactElement }): ReactElement => {
  const [loggedIn, setLoggedIn] = useRecoilState(loggedInState);
  const session = useRecoilValue(sessionState);
  const user = useRecoilValue(userState);
  const setShowAlert = useSetRecoilState(alertState);
  const { pathname } = useLocation() satisfies LocationProps;
  const isLogout = useRecoilValue(isLogoutState);
  const [isLoading, setIsLoading] = useState(false);
  const setIsLogout = useSetRecoilState(isLogoutState);
  const resetSesssion = useResetRecoilState(sessionState);
  const resetShowAlert = useResetRecoilState(alertState);
  const resetShowOtp = useResetRecoilState(showOtpState);
  const resetShowResetPassword = useResetRecoilState(
    showResetPasswordPromptState,
  );
  const resetShowTypeResetPasswordCodePrompt = useResetRecoilState(
    showTypeResetPasswordCodePromptState,
  );
  const resetShowLoadingResponse = useResetRecoilState(loadingResponseState);
  const resetJwtToken = useResetRecoilState(jwtTokenState);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useLayoutEffect(() => {
    if (pathname !== "/") {
      setIsLoading(true);
    }
  }, [pathname]);

  const logoutAndShowAlert = useCallback(() => {
    resetSesssion();
    resetShowAlert();
    setIsLogout(true);
    resetShowOtp();
    resetShowResetPassword();
    resetShowTypeResetPasswordCodePrompt();
    resetShowLoadingResponse();
    setLoggedIn(false);
    resetJwtToken();
    setShowAlert((prev) => ({
      ...prev,
      expiredSessionAlert: {
        ...prev.expiredSessionAlert,
        value: true,
      },
      currentAlert: "expiredSessionAlert",
    }));
    navigate("/", { replace: true });
  }, [
    resetSesssion,
    resetShowAlert,
    setIsLogout,
    resetShowOtp,
    resetShowResetPassword,
    resetShowTypeResetPasswordCodePrompt,
    resetShowLoadingResponse,
    setLoggedIn,
    resetJwtToken,
    setShowAlert,
    navigate,
  ]);

  const fetchSessionInfo = useCallback(() => {
    return fetch(
      `${sessionInfoUrl()}?${new URLSearchParams({
        name: user.userName,
      })}`,
    )
      .then(async (response) => {
        if (!response) {
          return null;
        }
        const headers = response.headers;
        const contentType = headers.get("Set-Authorization");
        if (contentType) {
          secureLocalStorage.setItem("jwtToken", JSON.stringify(contentType));
        }
        const data = (await response.json()) as string[] | null;
        if (!data || session[1] !== data[1]) {
          logoutAndShowAlert();
        } else {
          setLoggedIn(true);
        }
      })
      .catch((error) => {
        captureException(error);
        logoutAndShowAlert();
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [user, session, logoutAndShowAlert, setLoggedIn]);

  useEffect(() => {
    if (pathname === "/" || isLogout) {
      return;
    }

    if (!user.userName) {
      logoutAndShowAlert();
      navigate("/", { replace: true });
    }

    fetchSessionInfo();
  }, [
    pathname,
    user,
    navigate,
    isLogout,
    logoutAndShowAlert,
    fetchSessionInfo,
  ]);

  if (isLoading && loggedIn) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="fill-available"
        gap={5}
        flexDirection="column"
      >
        <CircularProgress color="secondary" />
        <Typography variant="h6">{t("loadingSessionLabel")}</Typography>
      </Box>
    );
  }

  return <>{children}</>;
};

Component.displayName = "ProtectedRoute";
export const ProtectedRoute = withProfiler(Component);
