import {
  anchorFiltersMenuState,
  openFiltersMenuState,
} from "@/states/clientFinder";
import { scrollTopState, showSidebarState } from "@/states/layout";
import {
  alertState,
  jwtTokenState,
  loadingResponseState,
  loggedInState,
  sessionState,
  showOtpState,
  showResetPasswordPromptState,
  showTypeResetPasswordCodePromptState,
  userState,
} from "@/states/login";
import {
  anchorLanguageMenuState,
  anchorNotificationCenterState,
  anchorUserMenuState,
  isLogoutState,
  openLanguageMenuState,
  openNotificationCenterState,
  openUserMenuState,
  showUnreadOnlyState,
} from "@/states/topBar";
import type { SearchResultsB } from "@/types";
import type { LocationProps } from "@/types/props";
import { useSearchResults } from "@/utils/useValues";
import type { SelectProps } from "@mui/material/Select";
import type { TextFieldProps } from "@mui/material/TextField";
import { type FormEventHandler, type MouseEvent, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";
import { useBreakpoints } from "./useBreakpoints";

export const useTopBar = () => {
  const scrollTop = useRecoilValue(scrollTopState);
  const trigger = scrollTop > 50;
  const showSidebar = useRecoilValue(showSidebarState);
  const location = useLocation() satisfies LocationProps;

  const isClientFinder = location.pathname === "/client-finder";

  return {
    trigger,
    showSidebar,
    isClientFinder,
  };
};

export const useBreadcumbs = () => {
  const location = useLocation() satisfies LocationProps;
  const pathnames = location.pathname.split("/").filter((x) => x);
  const { matches } = useBreakpoints({
    breakpoint: "md",
  });

  return {
    pathnames,
    matches,
  };
};

export const useForm = () => {
  const { t: tClientFinder } = useTranslation("clientFinder");
  const { searchResults, setSearchResults } = useSearchResults();
  const navigate = useNavigate();

  const handleTextFieldChange =
    <T extends keyof SearchResultsB>(key: T): TextFieldProps["onChange"] =>
    (e) =>
      setSearchResults({
        ...searchResults,
        [key]: e.target.value,
      });

  const handleSelectChange =
    <T extends keyof SearchResultsB>(key: T): SelectProps<string>["onChange"] =>
    (e) =>
      setSearchResults({
        ...searchResults,
        [key]: e.target.value,
      });

  const handleSearchClick: FormEventHandler = useCallback(
    (event) => {
      event.preventDefault();
      navigate({
        pathname: "/client-finder",
        search: createSearchParams({
          filter: searchResults.filter,
          search: searchResults.texto,
        }).toString(),
      });
    },
    [navigate, searchResults],
  );

  const [openFiltersMenu, setOpenFiltersMenu] =
    useRecoilState(openFiltersMenuState);
  const [anchorFiltersMenu, setAnchorFiltersMenu] = useRecoilState(
    anchorFiltersMenuState,
  );

  const handleOpenFiltersMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorFiltersMenu(event.currentTarget);
    setOpenFiltersMenu((prev) => !prev);
  };

  const handleFiltersChange = (filter: string) => {
    setSearchResults({
      ...searchResults,
      filter,
    });
    setOpenFiltersMenu(false);
  };

  const filters = [
    { description: "Cliente", label: tClientFinder("filter1") },
    { description: "Contrato", label: tClientFinder("filter2") },
    {
      description: "Identificacion",
      label: tClientFinder("filter3"),
    },
    { description: "Nombre Cliente", label: tClientFinder("filter4") },
    { description: "Ninguno", label: tClientFinder("filter5") },
  ];

  return {
    handleSearchClick,
    searchResults,
    filters,
    handleTextFieldChange,
    handleSelectChange,
    openFiltersMenu,
    anchorFiltersMenu,
    handleOpenFiltersMenu,
    handleFiltersChange,
  };
};

export const useLanguage = () => {
  const { i18n } = useTranslation();
  const [openLanguageMenu, setOpenLanguageMenu] = useRecoilState(
    openLanguageMenuState,
  );
  const [anchorLanguageMenu, setAnchorLanguageMenu] = useRecoilState(
    anchorLanguageMenuState,
  );

  const handleOpenLanguageMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorLanguageMenu(event.currentTarget);
    setOpenLanguageMenu((prev) => !prev);
  };

  const handleLanguageChange = (language: string) => {
    i18n.changeLanguage(language);
    setOpenLanguageMenu(false);
  };

  return {
    handleOpenLanguageMenu,
    openLanguageMenu,
    anchorLanguageMenu,
    handleLanguageChange,
  };
};

export const useMenu = () => {
  const [showSidebar, setShowSidebar] = useRecoilState(showSidebarState);

  const handleDrawerToggle = () => {
    setShowSidebar((prev) => !prev);
  };

  return {
    handleDrawerToggle,
    showSidebar,
  };
};

export const useNotifications = () => {
  const [anchorNotificationCenter, setAnchorNotificationCenter] =
    useRecoilState(anchorNotificationCenterState);

  const [showUnreadOnly, setShowUnreadOnly] =
    useRecoilState(showUnreadOnlyState);

  const [openNotificationCenter, setOpenNotificationCenter] = useRecoilState(
    openNotificationCenterState,
  );

  const handleOpenNotificationCenter = (
    event: MouseEvent<HTMLButtonElement>,
  ) => {
    setAnchorNotificationCenter(event.currentTarget);
    setOpenNotificationCenter((prev) => !prev);
  };

  const toggleShowUnreadOnly = () => {
    setShowUnreadOnly((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpenNotificationCenter(false);
  };
  return {
    handleOpenNotificationCenter,
    anchorNotificationCenter,
    openNotificationCenter,
    toggleShowUnreadOnly,
    showUnreadOnly,
    handleClickAway,
  };
};

export const useUser = () => {
  const navigate = useNavigate();
  const user = useRecoilValue(userState);
  const [openUserMenu, setOpenUserMenu] = useRecoilState(openUserMenuState);
  const [anchorUserMenu, setAnchorUserMenu] =
    useRecoilState(anchorUserMenuState);
  const resetSesssion = useResetRecoilState(sessionState);
  const resetShowAlert = useResetRecoilState(alertState);
  const setIsLogout = useSetRecoilState(isLogoutState);
  const setLoggedIn = useSetRecoilState(loggedInState);
  const resetShowOtp = useResetRecoilState(showOtpState);
  const resetShowResetPassword = useResetRecoilState(
    showResetPasswordPromptState,
  );
  const resetShowTypeResetPasswordCodePrompt = useResetRecoilState(
    showTypeResetPasswordCodePromptState,
  );
  const resetShowLoadingResponse = useResetRecoilState(loadingResponseState);
  const resetJwtToken = useResetRecoilState(jwtTokenState);

  const handleLogout = () => {
    resetSesssion();
    resetShowAlert();
    setOpenUserMenu(false);
    setIsLogout(true);
    resetShowOtp();
    resetShowResetPassword();
    resetShowTypeResetPasswordCodePrompt();
    resetShowLoadingResponse();
    setLoggedIn(false);
    resetJwtToken();
    navigate("/", { replace: true });
  };

  const handleOpenUserMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorUserMenu(event.currentTarget);
    setOpenUserMenu((prev) => !prev);
  };

  const handleConfigClick = () => {
    navigate("/configuration", { replace: true });
    setOpenUserMenu((prev) => !prev);
  };

  return {
    user,
    handleLogout,
    openUserMenu,
    handleOpenUserMenu,
    anchorUserMenu,
    handleConfigClick,
  };
};
