import { useRequestResetPassword } from "@/hooks/useLogin";
import SendIcon from "@mui/icons-material/Send";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

function Component() {
  const {
    loadingResponse,
    showResetPasswordPrompt,
    handleSendResetPasswordPrompt,
    userReset,
    handleTextFieldChange2,
  } = useRequestResetPassword();
  const { t } = useTranslation("login");

  return (
    <>
      {showResetPasswordPrompt && (
        <>
          <Typography
            component="h1"
            variant="h5"
            fontWeight="bold"
            align="left"
          >
            {t("resetPasswordTitle")}
          </Typography>
          <Typography component="p" align="left">
            {t("resetPasswordSubtitle")}
          </Typography>
          <TextField
            disabled={loadingResponse}
            margin="normal"
            required={true}
            fullWidth={true}
            id="username"
            label={t("username")}
            type="text"
            name="username"
            autoComplete="email"
            value={userReset.username}
            onChange={handleTextFieldChange2("username")}
          />
          <Button
            disabled={loadingResponse}
            type="button"
            fullWidth={true}
            variant="contained"
            startIcon={<SendIcon />}
            sx={{ mt: 3, mb: 2 }}
            onClick={handleSendResetPasswordPrompt}
          >
            {t("resetPasswordButton")}
          </Button>
        </>
      )}
    </>
  );
}

Component.displayName = "RequestResetPassword";
export const RequestResetPassword = Component;
