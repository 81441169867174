// generated with @7nohe/openapi-react-query-codegen@1.1.0 
import * as Common from "./common";
import { useQuery, useSuspenseQuery, useMutation, UseQueryResult, UseQueryOptions, UseMutationOptions, UseMutationResult } from "@tanstack/react-query";
import { AppVariablesService, ArticlesService, AttachedFilesService, CatalogsService, ContactDetailsService, DemographicDataService, DynamicNamesService, EmploymentDataService, FieldsService, FlowDocumentsService, FlowStagesService, FormatsService, GenerateLetterService, GroupsService, GuaranteeOwnersService, GuaranteesService, HolidaysService, ImportService, LiabilitiesService, ManagementService, MonthlyPaymentsService, OperatingFlowsService, OrderItemsService, OtherPersonsService, PaymentAgreementsService, PersonalReferencesService, PersonsAddendumService, PromisesService, QuotesService, ReschedulesService, RolesService, RolesPermissionsService, StrategiesService, StrategyFormatsService, UrlsService, UsersService, AppVariablesData, ArticlesData, AttachedFilesData, CatalogsData, ContactDetailsData, DemographicDataData, DynamicNamesData, EmploymentDataData, FieldsData, FlowDocumentsData, FlowStagesData, FormatsData, GenerateLetterData, GroupsData, GuaranteeOwnersData, GuaranteesData, HolidaysData, ImportData, LiabilitiesData, ManagementData, MonthlyPaymentsData, OperatingFlowsData, OrderItemsData, OtherPersonsData, PaymentAgreementsData, PersonalReferencesData, PersonsAddendumData, PromisesData, QuotesData, ReschedulesData, RolesData, RolesPermissionsData, StrategiesData, StrategyFormatsData, UrlsData, UsersData } from "../requests";
import { AppVariable, AppVariableWithoutId, Article, AttachedFiles, AttachedFilesWithoutSequential, Catalogs, CatalogsWithoutSequential, ContactDetails, ContactDetailsWithoutSequential, DemographicData, DemographicDataWithoutSequential, DynamicNames, DynamicNamesWithoutSequential, EmploymentData, EmploymentDataWithoutSequential, Fields, FieldsWithoutSequential, FlowDocuments, FlowDocumentsWithoutSequential, FlowStages, FlowStagesWithoutSequential, Formats, FormatsWithoutSequential, GenerateLetterB, Groups, GroupsWithoutSequential, GuaranteeOwners, GuaranteeOwnersWithoutSequential, Guarantees, GuaranteesWithoutSequential, Holidays, HolidaysWithoutSequential, Import, Liabilities, LiabilitiesWithoutNumber, Management, ManagementWithoutSequential, MonthlyPayments, MonthlyPaymentsWithoutClientCode, OperatingFlows, OperatingFlowsWithoutSequential, OrderItems, OrderItemsWithoutOrderID, OtherPersons, OtherPersonsWithoutClientCode, ParamData, PaymentAgreements, PaymentAgreementsWithoutSequential, PersonalReferences, PersonalReferencesWithoutSequential, PersonsAddendum, PersonsAddendumWithoutClientCode, Promises, PromisesWithoutSequential, Quotes, QuotesWithoutSequential, Reschedules, ReschedulesWithoutSequential, Roles, RolesPermissions, RolesPermissionsWithoutSequential, RolesWithoutSequential, Strategies, StrategiesWithoutSequential, StrategyFormats, StrategyFormatsWithoutSequential, Urls, UrlsBody, UrlsBodyWithoutUrlID, Users, UsersWithoutSequential } from "../requests/models";
export const useAppVariablesServiceGetAppVariables = <TData = Common.AppVariablesServiceGetAppVariablesDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ id, xDbCatalog }: {
    id: number;
    xDbCatalog: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: [Common.useAppVariablesServiceGetAppVariablesKey, ...(queryKey ?? [{ id, xDbCatalog }])], queryFn: () => AppVariablesService.getAppVariables({ id, xDbCatalog }) as TData, ...options });
export const useAppVariablesServiceGetAppVariablesByTypeAndUserId = <TData = Common.AppVariablesServiceGetAppVariablesByTypeAndUserIdDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ type, userid, xDbCatalog }: {
    type: string;
    userid: number;
    xDbCatalog: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: [Common.useAppVariablesServiceGetAppVariablesByTypeAndUserIdKey, ...(queryKey ?? [{ type, userid, xDbCatalog }])], queryFn: () => AppVariablesService.getAppVariablesByTypeAndUserId({ type, userid, xDbCatalog }) as TData, ...options });
export const useArticlesServiceGetArticles = <TData = Common.ArticlesServiceGetArticlesDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ clientCode, xDbCatalog }: {
    clientCode: number;
    xDbCatalog: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: [Common.useArticlesServiceGetArticlesKey, ...(queryKey ?? [{ clientCode, xDbCatalog }])], queryFn: () => ArticlesService.getArticles({ clientCode, xDbCatalog }) as TData, ...options });
export const useAttachedFilesServiceGetAttachedFiles = <TData = Common.AttachedFilesServiceGetAttachedFilesDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ stage, xDbCatalog }: {
    stage: string;
    xDbCatalog: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: [Common.useAttachedFilesServiceGetAttachedFilesKey, ...(queryKey ?? [{ stage, xDbCatalog }])], queryFn: () => AttachedFilesService.getAttachedFiles({ stage, xDbCatalog }) as TData, ...options });
export const useCatalogsServiceGetCatalogsByStatus = <TData = Common.CatalogsServiceGetCatalogsByStatusDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ status, xDbCatalog }: {
    status: string;
    xDbCatalog: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: [Common.useCatalogsServiceGetCatalogsByStatusKey, ...(queryKey ?? [{ status, xDbCatalog }])], queryFn: () => CatalogsService.getCatalogsByStatus({ status, xDbCatalog }) as TData, ...options });
export const useCatalogsServiceGetCatalogsByDescription = <TData = Common.CatalogsServiceGetCatalogsByDescriptionDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ description, xDbCatalog }: {
    description: string;
    xDbCatalog: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: [Common.useCatalogsServiceGetCatalogsByDescriptionKey, ...(queryKey ?? [{ description, xDbCatalog }])], queryFn: () => CatalogsService.getCatalogsByDescription({ description, xDbCatalog }) as TData, ...options });
export const useCatalogsServiceGetCatalogsGroupByColumn = <TData = Common.CatalogsServiceGetCatalogsGroupByColumnDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ column, xDbCatalog }: {
    column: string;
    xDbCatalog: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: [Common.useCatalogsServiceGetCatalogsGroupByColumnKey, ...(queryKey ?? [{ column, xDbCatalog }])], queryFn: () => CatalogsService.getCatalogsGroupByColumn({ column, xDbCatalog }) as TData, ...options });
export const useCatalogsServiceGetCatalogs = <TData = Common.CatalogsServiceGetCatalogsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ type, xDbCatalog }: {
    type: string;
    xDbCatalog: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: [Common.useCatalogsServiceGetCatalogsKey, ...(queryKey ?? [{ type, xDbCatalog }])], queryFn: () => CatalogsService.getCatalogs({ type, xDbCatalog }) as TData, ...options });
export const useContactDetailsServiceGetContactDetails = <TData = Common.ContactDetailsServiceGetContactDetailsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ client, xDbCatalog }: {
    client: number;
    xDbCatalog: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: [Common.useContactDetailsServiceGetContactDetailsKey, ...(queryKey ?? [{ client, xDbCatalog }])], queryFn: () => ContactDetailsService.getContactDetails({ client, xDbCatalog }) as TData, ...options });
export const useDemographicDataServiceGetDemographicData = <TData = Common.DemographicDataServiceGetDemographicDataDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ client, xDbCatalog }: {
    client: number;
    xDbCatalog: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: [Common.useDemographicDataServiceGetDemographicDataKey, ...(queryKey ?? [{ client, xDbCatalog }])], queryFn: () => DemographicDataService.getDemographicData({ client, xDbCatalog }) as TData, ...options });
export const useDynamicNamesServiceGetDynamicNames = <TData = Common.DynamicNamesServiceGetDynamicNamesDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ key, xDbCatalog }: {
    key: string;
    xDbCatalog: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: [Common.useDynamicNamesServiceGetDynamicNamesKey, ...(queryKey ?? [{ key, xDbCatalog }])], queryFn: () => DynamicNamesService.getDynamicNames({ key, xDbCatalog }) as TData, ...options });
export const useEmploymentDataServiceGetEmploymentData = <TData = Common.EmploymentDataServiceGetEmploymentDataDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ client, xDbCatalog }: {
    client: number;
    xDbCatalog: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: [Common.useEmploymentDataServiceGetEmploymentDataKey, ...(queryKey ?? [{ client, xDbCatalog }])], queryFn: () => EmploymentDataService.getEmploymentData({ client, xDbCatalog }) as TData, ...options });
export const useFieldsServiceGetFields = <TData = Common.FieldsServiceGetFieldsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ xDbCatalog }: {
    xDbCatalog: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: [Common.useFieldsServiceGetFieldsKey, ...(queryKey ?? [{ xDbCatalog }])], queryFn: () => FieldsService.getFields({ xDbCatalog }) as TData, ...options });
export const useFlowDocumentsServiceGetFlowDocuments = <TData = Common.FlowDocumentsServiceGetFlowDocumentsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ flow, xDbCatalog }: {
    flow: string;
    xDbCatalog: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: [Common.useFlowDocumentsServiceGetFlowDocumentsKey, ...(queryKey ?? [{ flow, xDbCatalog }])], queryFn: () => FlowDocumentsService.getFlowDocuments({ flow, xDbCatalog }) as TData, ...options });
export const useFlowStagesServiceGetFlowStages = <TData = Common.FlowStagesServiceGetFlowStagesDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ flowCode, xDbCatalog }: {
    flowCode: string;
    xDbCatalog: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: [Common.useFlowStagesServiceGetFlowStagesKey, ...(queryKey ?? [{ flowCode, xDbCatalog }])], queryFn: () => FlowStagesService.getFlowStages({ flowCode, xDbCatalog }) as TData, ...options });
export const useFormatsServiceGetFormats = <TData = Common.FormatsServiceGetFormatsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ xDbCatalog }: {
    xDbCatalog: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: [Common.useFormatsServiceGetFormatsKey, ...(queryKey ?? [{ xDbCatalog }])], queryFn: () => FormatsService.getFormats({ xDbCatalog }) as TData, ...options });
export const useFormatsServiceGetFormatsByChannel = <TData = Common.FormatsServiceGetFormatsByChannelDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ channel, xDbCatalog }: {
    channel: string;
    xDbCatalog: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: [Common.useFormatsServiceGetFormatsByChannelKey, ...(queryKey ?? [{ channel, xDbCatalog }])], queryFn: () => FormatsService.getFormatsByChannel({ channel, xDbCatalog }) as TData, ...options });
export const useGroupsServiceGetGroups = <TData = Common.GroupsServiceGetGroupsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ xDbCatalog }: {
    xDbCatalog: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: [Common.useGroupsServiceGetGroupsKey, ...(queryKey ?? [{ xDbCatalog }])], queryFn: () => GroupsService.getGroups({ xDbCatalog }) as TData, ...options });
export const useGuaranteeOwnersServiceGetGuaranteeOwners = <TData = Common.GuaranteeOwnersServiceGetGuaranteeOwnersDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ guaranteeNumber, xDbCatalog }: {
    guaranteeNumber: string;
    xDbCatalog: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: [Common.useGuaranteeOwnersServiceGetGuaranteeOwnersKey, ...(queryKey ?? [{ guaranteeNumber, xDbCatalog }])], queryFn: () => GuaranteeOwnersService.getGuaranteeOwners({ guaranteeNumber, xDbCatalog }) as TData, ...options });
export const useGuaranteesServiceGetGuarantees = <TData = Common.GuaranteesServiceGetGuaranteesDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ contract, xDbCatalog }: {
    contract: string;
    xDbCatalog: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: [Common.useGuaranteesServiceGetGuaranteesKey, ...(queryKey ?? [{ contract, xDbCatalog }])], queryFn: () => GuaranteesService.getGuarantees({ contract, xDbCatalog }) as TData, ...options });
export const useHolidaysServiceGetHolidays = <TData = Common.HolidaysServiceGetHolidaysDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ xDbCatalog }: {
    xDbCatalog: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: [Common.useHolidaysServiceGetHolidaysKey, ...(queryKey ?? [{ xDbCatalog }])], queryFn: () => HolidaysService.getHolidays({ xDbCatalog }) as TData, ...options });
export const useLiabilitiesServiceGetLiabilities = <TData = Common.LiabilitiesServiceGetLiabilitiesDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ clientCode, xDbCatalog }: {
    clientCode: number;
    xDbCatalog: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: [Common.useLiabilitiesServiceGetLiabilitiesKey, ...(queryKey ?? [{ clientCode, xDbCatalog }])], queryFn: () => LiabilitiesService.getLiabilities({ clientCode, xDbCatalog }) as TData, ...options });
export const useManagementServiceGetManagementDetails = <TData = Common.ManagementServiceGetManagementDetailsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ client, xDbCatalog }: {
    client: number;
    xDbCatalog: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: [Common.useManagementServiceGetManagementDetailsKey, ...(queryKey ?? [{ client, xDbCatalog }])], queryFn: () => ManagementService.getManagementDetails({ client, xDbCatalog }) as TData, ...options });
export const useMonthlyPaymentsServiceGetMonthlyPayments = <TData = Common.MonthlyPaymentsServiceGetMonthlyPaymentsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ clientCode, xDbCatalog }: {
    clientCode: number;
    xDbCatalog: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: [Common.useMonthlyPaymentsServiceGetMonthlyPaymentsKey, ...(queryKey ?? [{ clientCode, xDbCatalog }])], queryFn: () => MonthlyPaymentsService.getMonthlyPayments({ clientCode, xDbCatalog }) as TData, ...options });
export const useOperatingFlowsServiceGetOperatingFlows = <TData = Common.OperatingFlowsServiceGetOperatingFlowsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ xDbCatalog }: {
    xDbCatalog: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: [Common.useOperatingFlowsServiceGetOperatingFlowsKey, ...(queryKey ?? [{ xDbCatalog }])], queryFn: () => OperatingFlowsService.getOperatingFlows({ xDbCatalog }) as TData, ...options });
export const useOperatingFlowsServiceGetOperatingFlowsByFlowCode = <TData = Common.OperatingFlowsServiceGetOperatingFlowsByFlowCodeDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ flowCode, xDbCatalog }: {
    flowCode: string;
    xDbCatalog: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: [Common.useOperatingFlowsServiceGetOperatingFlowsByFlowCodeKey, ...(queryKey ?? [{ flowCode, xDbCatalog }])], queryFn: () => OperatingFlowsService.getOperatingFlowsByFlowCode({ flowCode, xDbCatalog }) as TData, ...options });
export const useOrderItemsServiceGetAllOrderItems = <TData = Common.OrderItemsServiceGetAllOrderItemsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ xDbCatalog }: {
    xDbCatalog: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: [Common.useOrderItemsServiceGetAllOrderItemsKey, ...(queryKey ?? [{ xDbCatalog }])], queryFn: () => OrderItemsService.getAllOrderItems({ xDbCatalog }) as TData, ...options });
export const useOrderItemsServiceGetOrderItemsByClient = <TData = Common.OrderItemsServiceGetOrderItemsByClientDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ client, xDbCatalog }: {
    client: number;
    xDbCatalog: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: [Common.useOrderItemsServiceGetOrderItemsByClientKey, ...(queryKey ?? [{ client, xDbCatalog }])], queryFn: () => OrderItemsService.getOrderItemsByClient({ client, xDbCatalog }) as TData, ...options });
export const useOtherPersonsServiceGetOtherPersons = <TData = Common.OtherPersonsServiceGetOtherPersonsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ clientCode, xDbCatalog }: {
    clientCode: number;
    xDbCatalog: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: [Common.useOtherPersonsServiceGetOtherPersonsKey, ...(queryKey ?? [{ clientCode, xDbCatalog }])], queryFn: () => OtherPersonsService.getOtherPersons({ clientCode, xDbCatalog }) as TData, ...options });
export const usePaymentAgreementsServiceGetPaymentAgreements = <TData = Common.PaymentAgreementsServiceGetPaymentAgreementsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ client, xDbCatalog }: {
    client: number;
    xDbCatalog: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: [Common.usePaymentAgreementsServiceGetPaymentAgreementsKey, ...(queryKey ?? [{ client, xDbCatalog }])], queryFn: () => PaymentAgreementsService.getPaymentAgreements({ client, xDbCatalog }) as TData, ...options });
export const usePersonalReferencesServiceGetPersonalReferences = <TData = Common.PersonalReferencesServiceGetPersonalReferencesDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ client, xDbCatalog }: {
    client: number;
    xDbCatalog: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: [Common.usePersonalReferencesServiceGetPersonalReferencesKey, ...(queryKey ?? [{ client, xDbCatalog }])], queryFn: () => PersonalReferencesService.getPersonalReferences({ client, xDbCatalog }) as TData, ...options });
export const usePersonsAddendumServiceGetPersonsAddendum = <TData = Common.PersonsAddendumServiceGetPersonsAddendumDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ clientCode, xDbCatalog }: {
    clientCode: number;
    xDbCatalog: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: [Common.usePersonsAddendumServiceGetPersonsAddendumKey, ...(queryKey ?? [{ clientCode, xDbCatalog }])], queryFn: () => PersonsAddendumService.getPersonsAddendum({ clientCode, xDbCatalog }) as TData, ...options });
export const usePromisesServiceGetPromises = <TData = Common.PromisesServiceGetPromisesDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ client, xDbCatalog }: {
    client: number;
    xDbCatalog: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: [Common.usePromisesServiceGetPromisesKey, ...(queryKey ?? [{ client, xDbCatalog }])], queryFn: () => PromisesService.getPromises({ client, xDbCatalog }) as TData, ...options });
export const useQuotesServiceGetQuotes = <TData = Common.QuotesServiceGetQuotesDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ client, xDbCatalog }: {
    client: number;
    xDbCatalog: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: [Common.useQuotesServiceGetQuotesKey, ...(queryKey ?? [{ client, xDbCatalog }])], queryFn: () => QuotesService.getQuotes({ client, xDbCatalog }) as TData, ...options });
export const useQuotesServiceGetQuotesByClientContract = <TData = Common.QuotesServiceGetQuotesByClientContractDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ client, contract, xDbCatalog }: {
    client: number;
    contract: string;
    xDbCatalog: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: [Common.useQuotesServiceGetQuotesByClientContractKey, ...(queryKey ?? [{ client, contract, xDbCatalog }])], queryFn: () => QuotesService.getQuotesByClientContract({ client, contract, xDbCatalog }) as TData, ...options });
export const useReschedulesServiceGetReschedules = <TData = Common.ReschedulesServiceGetReschedulesDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ client, xDbCatalog }: {
    client: number;
    xDbCatalog: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: [Common.useReschedulesServiceGetReschedulesKey, ...(queryKey ?? [{ client, xDbCatalog }])], queryFn: () => ReschedulesService.getReschedules({ client, xDbCatalog }) as TData, ...options });
export const useRolesServiceGetAllRoles = <TData = Common.RolesServiceGetAllRolesDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ xDbCatalog }: {
    xDbCatalog: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: [Common.useRolesServiceGetAllRolesKey, ...(queryKey ?? [{ xDbCatalog }])], queryFn: () => RolesService.getAllRoles({ xDbCatalog }) as TData, ...options });
export const useRolesPermissionsServiceGetPermissionsByRole = <TData = Common.RolesPermissionsServiceGetPermissionsByRoleDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ roleId, xDbCatalog }: {
    roleId: string;
    xDbCatalog: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: [Common.useRolesPermissionsServiceGetPermissionsByRoleKey, ...(queryKey ?? [{ roleId, xDbCatalog }])], queryFn: () => RolesPermissionsService.getPermissionsByRole({ roleId, xDbCatalog }) as TData, ...options });
export const useStrategiesServiceGetStrategies = <TData = Common.StrategiesServiceGetStrategiesDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ xDbCatalog }: {
    xDbCatalog: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: [Common.useStrategiesServiceGetStrategiesKey, ...(queryKey ?? [{ xDbCatalog }])], queryFn: () => StrategiesService.getStrategies({ xDbCatalog }) as TData, ...options });
export const useStrategyFormatsServiceGetStrategyFormats = <TData = Common.StrategyFormatsServiceGetStrategyFormatsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ codeStrategy, xDbCatalog }: {
    codeStrategy: string;
    xDbCatalog: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: [Common.useStrategyFormatsServiceGetStrategyFormatsKey, ...(queryKey ?? [{ codeStrategy, xDbCatalog }])], queryFn: () => StrategyFormatsService.getStrategyFormats({ codeStrategy, xDbCatalog }) as TData, ...options });
export const useUrlsServiceGetUrls = <TData = Common.UrlsServiceGetUrlsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ xDbCatalog }: {
    xDbCatalog: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: [Common.useUrlsServiceGetUrlsKey, ...(queryKey ?? [{ xDbCatalog }])], queryFn: () => UrlsService.getUrls({ xDbCatalog }) as TData, ...options });
export const useUsersServiceGetAllUsers = <TData = Common.UsersServiceGetAllUsersDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ xDbCatalog }: {
    xDbCatalog: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: [Common.useUsersServiceGetAllUsersKey, ...(queryKey ?? [{ xDbCatalog }])], queryFn: () => UsersService.getAllUsers({ xDbCatalog }) as TData, ...options });
export const useUsersServiceGetUsersByRole = <TData = Common.UsersServiceGetUsersByRoleDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ userRole, xDbCatalog }: {
    userRole: string;
    xDbCatalog: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: [Common.useUsersServiceGetUsersByRoleKey, ...(queryKey ?? [{ userRole, xDbCatalog }])], queryFn: () => UsersService.getUsersByRole({ userRole, xDbCatalog }) as TData, ...options });
export const useAppVariablesServicePostAppVariable = <TData = Common.AppVariablesServicePostAppVariableMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    requestBody?: AppVariableWithoutId;
    xDbCatalog: string;
    xOperationId: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    requestBody?: AppVariableWithoutId;
    xDbCatalog: string;
    xOperationId: string;
}, TContext>({ mutationFn: ({ requestBody, xDbCatalog, xOperationId }) => AppVariablesService.postAppVariable({ requestBody, xDbCatalog, xOperationId }) as unknown as Promise<TData>, ...options });
export const useArticlesServicePostArticle = <TData = Common.ArticlesServicePostArticleMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    requestBody?: Article;
    xDbCatalog: string;
    xOperationId: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    requestBody?: Article;
    xDbCatalog: string;
    xOperationId: string;
}, TContext>({ mutationFn: ({ requestBody, xDbCatalog, xOperationId }) => ArticlesService.postArticle({ requestBody, xDbCatalog, xOperationId }) as unknown as Promise<TData>, ...options });
export const useAttachedFilesServicePostAttachedFile = <TData = Common.AttachedFilesServicePostAttachedFileMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    requestBody?: AttachedFilesWithoutSequential;
    xDbCatalog: string;
    xOperationId: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    requestBody?: AttachedFilesWithoutSequential;
    xDbCatalog: string;
    xOperationId: string;
}, TContext>({ mutationFn: ({ requestBody, xDbCatalog, xOperationId }) => AttachedFilesService.postAttachedFile({ requestBody, xDbCatalog, xOperationId }) as unknown as Promise<TData>, ...options });
export const useCatalogsServicePostCatalogs = <TData = Common.CatalogsServicePostCatalogsMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    requestBody?: CatalogsWithoutSequential;
    xDbCatalog: string;
    xOperationId: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    requestBody?: CatalogsWithoutSequential;
    xDbCatalog: string;
    xOperationId: string;
}, TContext>({ mutationFn: ({ requestBody, xDbCatalog, xOperationId }) => CatalogsService.postCatalogs({ requestBody, xDbCatalog, xOperationId }) as unknown as Promise<TData>, ...options });
export const useContactDetailsServicePostContactDetails = <TData = Common.ContactDetailsServicePostContactDetailsMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    requestBody?: ContactDetailsWithoutSequential;
    xDbCatalog: string;
    xOperationId: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    requestBody?: ContactDetailsWithoutSequential;
    xDbCatalog: string;
    xOperationId: string;
}, TContext>({ mutationFn: ({ requestBody, xDbCatalog, xOperationId }) => ContactDetailsService.postContactDetails({ requestBody, xDbCatalog, xOperationId }) as unknown as Promise<TData>, ...options });
export const useDemographicDataServicePostDemographicData = <TData = Common.DemographicDataServicePostDemographicDataMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    requestBody?: DemographicDataWithoutSequential;
    xDbCatalog: string;
    xOperationId: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    requestBody?: DemographicDataWithoutSequential;
    xDbCatalog: string;
    xOperationId: string;
}, TContext>({ mutationFn: ({ requestBody, xDbCatalog, xOperationId }) => DemographicDataService.postDemographicData({ requestBody, xDbCatalog, xOperationId }) as unknown as Promise<TData>, ...options });
export const useDynamicNamesServicePostDynamicNames = <TData = Common.DynamicNamesServicePostDynamicNamesMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    requestBody?: DynamicNamesWithoutSequential;
    xDbCatalog: string;
    xOperationId: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    requestBody?: DynamicNamesWithoutSequential;
    xDbCatalog: string;
    xOperationId: string;
}, TContext>({ mutationFn: ({ requestBody, xDbCatalog, xOperationId }) => DynamicNamesService.postDynamicNames({ requestBody, xDbCatalog, xOperationId }) as unknown as Promise<TData>, ...options });
export const useEmploymentDataServicePostEmploymentData = <TData = Common.EmploymentDataServicePostEmploymentDataMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    requestBody?: EmploymentDataWithoutSequential;
    xDbCatalog: string;
    xOperationId: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    requestBody?: EmploymentDataWithoutSequential;
    xDbCatalog: string;
    xOperationId: string;
}, TContext>({ mutationFn: ({ requestBody, xDbCatalog, xOperationId }) => EmploymentDataService.postEmploymentData({ requestBody, xDbCatalog, xOperationId }) as unknown as Promise<TData>, ...options });
export const useFlowDocumentsServicePostFlowDocuments = <TData = Common.FlowDocumentsServicePostFlowDocumentsMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    requestBody?: FlowDocumentsWithoutSequential;
    xDbCatalog: string;
    xOperationId: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    requestBody?: FlowDocumentsWithoutSequential;
    xDbCatalog: string;
    xOperationId: string;
}, TContext>({ mutationFn: ({ requestBody, xDbCatalog, xOperationId }) => FlowDocumentsService.postFlowDocuments({ requestBody, xDbCatalog, xOperationId }) as unknown as Promise<TData>, ...options });
export const useFlowStagesServicePostFlowStages = <TData = Common.FlowStagesServicePostFlowStagesMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    requestBody?: FlowStagesWithoutSequential;
    xDbCatalog: string;
    xOperationId: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    requestBody?: FlowStagesWithoutSequential;
    xDbCatalog: string;
    xOperationId: string;
}, TContext>({ mutationFn: ({ requestBody, xDbCatalog, xOperationId }) => FlowStagesService.postFlowStages({ requestBody, xDbCatalog, xOperationId }) as unknown as Promise<TData>, ...options });
export const useFormatsServicePostFormats = <TData = Common.FormatsServicePostFormatsMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    requestBody?: FormatsWithoutSequential;
    xDbCatalog: string;
    xOperationId: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    requestBody?: FormatsWithoutSequential;
    xDbCatalog: string;
    xOperationId: string;
}, TContext>({ mutationFn: ({ requestBody, xDbCatalog, xOperationId }) => FormatsService.postFormats({ requestBody, xDbCatalog, xOperationId }) as unknown as Promise<TData>, ...options });
export const useGenerateLetterServicePostGenerateLetter = <TData = Common.GenerateLetterServicePostGenerateLetterMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    requestBody?: GenerateLetterB;
    xDbCatalog: string;
    xOperationId: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    requestBody?: GenerateLetterB;
    xDbCatalog: string;
    xOperationId: string;
}, TContext>({ mutationFn: ({ requestBody, xDbCatalog, xOperationId }) => GenerateLetterService.postGenerateLetter({ requestBody, xDbCatalog, xOperationId }) as unknown as Promise<TData>, ...options });
export const useGroupsServicePostGroups = <TData = Common.GroupsServicePostGroupsMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    requestBody?: GroupsWithoutSequential;
    xDbCatalog: string;
    xOperationId: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    requestBody?: GroupsWithoutSequential;
    xDbCatalog: string;
    xOperationId: string;
}, TContext>({ mutationFn: ({ requestBody, xDbCatalog, xOperationId }) => GroupsService.postGroups({ requestBody, xDbCatalog, xOperationId }) as unknown as Promise<TData>, ...options });
export const useGuaranteeOwnersServicePostGuaranteeOwners = <TData = Common.GuaranteeOwnersServicePostGuaranteeOwnersMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    requestBody?: GuaranteeOwnersWithoutSequential;
    xDbCatalog: string;
    xOperationId: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    requestBody?: GuaranteeOwnersWithoutSequential;
    xDbCatalog: string;
    xOperationId: string;
}, TContext>({ mutationFn: ({ requestBody, xDbCatalog, xOperationId }) => GuaranteeOwnersService.postGuaranteeOwners({ requestBody, xDbCatalog, xOperationId }) as unknown as Promise<TData>, ...options });
export const useGuaranteesServicePostGuarantees = <TData = Common.GuaranteesServicePostGuaranteesMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    requestBody?: GuaranteesWithoutSequential;
    xDbCatalog: string;
    xOperationId: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    requestBody?: GuaranteesWithoutSequential;
    xDbCatalog: string;
    xOperationId: string;
}, TContext>({ mutationFn: ({ requestBody, xDbCatalog, xOperationId }) => GuaranteesService.postGuarantees({ requestBody, xDbCatalog, xOperationId }) as unknown as Promise<TData>, ...options });
export const useHolidaysServicePostHolidays = <TData = Common.HolidaysServicePostHolidaysMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    requestBody?: HolidaysWithoutSequential;
    xDbCatalog: string;
    xOperationId: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    requestBody?: HolidaysWithoutSequential;
    xDbCatalog: string;
    xOperationId: string;
}, TContext>({ mutationFn: ({ requestBody, xDbCatalog, xOperationId }) => HolidaysService.postHolidays({ requestBody, xDbCatalog, xOperationId }) as unknown as Promise<TData>, ...options });
export const useImportServicePostImport = <TData = Common.ImportServicePostImportMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    requestBody?: Import;
    xDbCatalog: string;
    xOperationId: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    requestBody?: Import;
    xDbCatalog: string;
    xOperationId: string;
}, TContext>({ mutationFn: ({ requestBody, xDbCatalog, xOperationId }) => ImportService.postImport({ requestBody, xDbCatalog, xOperationId }) as unknown as Promise<TData>, ...options });
export const useLiabilitiesServicePostLiabilities = <TData = Common.LiabilitiesServicePostLiabilitiesMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    requestBody?: Liabilities;
    xDbCatalog: string;
    xOperationId: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    requestBody?: Liabilities;
    xDbCatalog: string;
    xOperationId: string;
}, TContext>({ mutationFn: ({ requestBody, xDbCatalog, xOperationId }) => LiabilitiesService.postLiabilities({ requestBody, xDbCatalog, xOperationId }) as unknown as Promise<TData>, ...options });
export const useManagementServicePostManagementDetails = <TData = Common.ManagementServicePostManagementDetailsMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    requestBody?: ManagementWithoutSequential;
    xDbCatalog: string;
    xOperationId: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    requestBody?: ManagementWithoutSequential;
    xDbCatalog: string;
    xOperationId: string;
}, TContext>({ mutationFn: ({ requestBody, xDbCatalog, xOperationId }) => ManagementService.postManagementDetails({ requestBody, xDbCatalog, xOperationId }) as unknown as Promise<TData>, ...options });
export const useMonthlyPaymentsServicePostMonthlyPayments = <TData = Common.MonthlyPaymentsServicePostMonthlyPaymentsMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    requestBody?: MonthlyPayments;
    xDbCatalog: string;
    xOperationId: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    requestBody?: MonthlyPayments;
    xDbCatalog: string;
    xOperationId: string;
}, TContext>({ mutationFn: ({ requestBody, xDbCatalog, xOperationId }) => MonthlyPaymentsService.postMonthlyPayments({ requestBody, xDbCatalog, xOperationId }) as unknown as Promise<TData>, ...options });
export const useOperatingFlowsServicePostOperatingFlows = <TData = Common.OperatingFlowsServicePostOperatingFlowsMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    requestBody?: OperatingFlowsWithoutSequential;
    xDbCatalog: string;
    xOperationId: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    requestBody?: OperatingFlowsWithoutSequential;
    xDbCatalog: string;
    xOperationId: string;
}, TContext>({ mutationFn: ({ requestBody, xDbCatalog, xOperationId }) => OperatingFlowsService.postOperatingFlows({ requestBody, xDbCatalog, xOperationId }) as unknown as Promise<TData>, ...options });
export const useOrderItemsServicePostOrderItems = <TData = Common.OrderItemsServicePostOrderItemsMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    requestBody?: OrderItems;
    xDbCatalog: string;
    xOperationId: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    requestBody?: OrderItems;
    xDbCatalog: string;
    xOperationId: string;
}, TContext>({ mutationFn: ({ requestBody, xDbCatalog, xOperationId }) => OrderItemsService.postOrderItems({ requestBody, xDbCatalog, xOperationId }) as unknown as Promise<TData>, ...options });
export const useOtherPersonsServicePostOtherPersons = <TData = Common.OtherPersonsServicePostOtherPersonsMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    requestBody?: OtherPersons;
    xDbCatalog: string;
    xOperationId: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    requestBody?: OtherPersons;
    xDbCatalog: string;
    xOperationId: string;
}, TContext>({ mutationFn: ({ requestBody, xDbCatalog, xOperationId }) => OtherPersonsService.postOtherPersons({ requestBody, xDbCatalog, xOperationId }) as unknown as Promise<TData>, ...options });
export const usePaymentAgreementsServicePostPaymentAgreement = <TData = Common.PaymentAgreementsServicePostPaymentAgreementMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    requestBody?: PaymentAgreementsWithoutSequential;
    xDbCatalog: string;
    xOperationId: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    requestBody?: PaymentAgreementsWithoutSequential;
    xDbCatalog: string;
    xOperationId: string;
}, TContext>({ mutationFn: ({ requestBody, xDbCatalog, xOperationId }) => PaymentAgreementsService.postPaymentAgreement({ requestBody, xDbCatalog, xOperationId }) as unknown as Promise<TData>, ...options });
export const usePersonalReferencesServicePostPersonalReferences = <TData = Common.PersonalReferencesServicePostPersonalReferencesMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    requestBody?: PersonalReferencesWithoutSequential;
    xDbCatalog: string;
    xOperationId: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    requestBody?: PersonalReferencesWithoutSequential;
    xDbCatalog: string;
    xOperationId: string;
}, TContext>({ mutationFn: ({ requestBody, xDbCatalog, xOperationId }) => PersonalReferencesService.postPersonalReferences({ requestBody, xDbCatalog, xOperationId }) as unknown as Promise<TData>, ...options });
export const usePersonsAddendumServicePostPersonsAddendum = <TData = Common.PersonsAddendumServicePostPersonsAddendumMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    requestBody?: PersonsAddendum;
    xDbCatalog: string;
    xOperationId: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    requestBody?: PersonsAddendum;
    xDbCatalog: string;
    xOperationId: string;
}, TContext>({ mutationFn: ({ requestBody, xDbCatalog, xOperationId }) => PersonsAddendumService.postPersonsAddendum({ requestBody, xDbCatalog, xOperationId }) as unknown as Promise<TData>, ...options });
export const usePromisesServiceCreatePromise = <TData = Common.PromisesServiceCreatePromiseMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    requestBody?: PromisesWithoutSequential;
    xDbCatalog: string;
    xOperationId: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    requestBody?: PromisesWithoutSequential;
    xDbCatalog: string;
    xOperationId: string;
}, TContext>({ mutationFn: ({ requestBody, xDbCatalog, xOperationId }) => PromisesService.createPromise({ requestBody, xDbCatalog, xOperationId }) as unknown as Promise<TData>, ...options });
export const useQuotesServicePostQuotes = <TData = Common.QuotesServicePostQuotesMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    requestBody?: QuotesWithoutSequential;
    xDbCatalog: string;
    xOperationId: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    requestBody?: QuotesWithoutSequential;
    xDbCatalog: string;
    xOperationId: string;
}, TContext>({ mutationFn: ({ requestBody, xDbCatalog, xOperationId }) => QuotesService.postQuotes({ requestBody, xDbCatalog, xOperationId }) as unknown as Promise<TData>, ...options });
export const useReschedulesServicePostReschedules = <TData = Common.ReschedulesServicePostReschedulesMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    requestBody?: ReschedulesWithoutSequential;
    xDbCatalog: string;
    xOperationId: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    requestBody?: ReschedulesWithoutSequential;
    xDbCatalog: string;
    xOperationId: string;
}, TContext>({ mutationFn: ({ requestBody, xDbCatalog, xOperationId }) => ReschedulesService.postReschedules({ requestBody, xDbCatalog, xOperationId }) as unknown as Promise<TData>, ...options });
export const useRolesServicePostRole = <TData = Common.RolesServicePostRoleMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    requestBody?: RolesWithoutSequential;
    xDbCatalog: string;
    xOperationId: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    requestBody?: RolesWithoutSequential;
    xDbCatalog: string;
    xOperationId: string;
}, TContext>({ mutationFn: ({ requestBody, xDbCatalog, xOperationId }) => RolesService.postRole({ requestBody, xDbCatalog, xOperationId }) as unknown as Promise<TData>, ...options });
export const useRolesPermissionsServicePostRolesPermission = <TData = Common.RolesPermissionsServicePostRolesPermissionMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    requestBody?: RolesPermissionsWithoutSequential;
    xDbCatalog: string;
    xOperationId: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    requestBody?: RolesPermissionsWithoutSequential;
    xDbCatalog: string;
    xOperationId: string;
}, TContext>({ mutationFn: ({ requestBody, xDbCatalog, xOperationId }) => RolesPermissionsService.postRolesPermission({ requestBody, xDbCatalog, xOperationId }) as unknown as Promise<TData>, ...options });
export const useStrategiesServicePostStrategy = <TData = Common.StrategiesServicePostStrategyMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    requestBody?: StrategiesWithoutSequential;
    xDbCatalog: string;
    xOperationId: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    requestBody?: StrategiesWithoutSequential;
    xDbCatalog: string;
    xOperationId: string;
}, TContext>({ mutationFn: ({ requestBody, xDbCatalog, xOperationId }) => StrategiesService.postStrategy({ requestBody, xDbCatalog, xOperationId }) as unknown as Promise<TData>, ...options });
export const useStrategyFormatsServicePostStrategyFormats = <TData = Common.StrategyFormatsServicePostStrategyFormatsMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    requestBody?: StrategyFormatsWithoutSequential;
    xDbCatalog: string;
    xOperationId: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    requestBody?: StrategyFormatsWithoutSequential;
    xDbCatalog: string;
    xOperationId: string;
}, TContext>({ mutationFn: ({ requestBody, xDbCatalog, xOperationId }) => StrategyFormatsService.postStrategyFormats({ requestBody, xDbCatalog, xOperationId }) as unknown as Promise<TData>, ...options });
export const useUrlsServicePostUrls = <TData = Common.UrlsServicePostUrlsMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    requestBody?: UrlsBody;
    xDbCatalog: string;
    xOperationId: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    requestBody?: UrlsBody;
    xDbCatalog: string;
    xOperationId: string;
}, TContext>({ mutationFn: ({ requestBody, xDbCatalog, xOperationId }) => UrlsService.postUrls({ requestBody, xDbCatalog, xOperationId }) as unknown as Promise<TData>, ...options });
export const useUsersServicePostUsersAsync = <TData = Common.UsersServicePostUsersAsyncMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    requestBody?: UsersWithoutSequential;
    xDbCatalog: string;
    xOperationId: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    requestBody?: UsersWithoutSequential;
    xDbCatalog: string;
    xOperationId: string;
}, TContext>({ mutationFn: ({ requestBody, xDbCatalog, xOperationId }) => UsersService.postUsersAsync({ requestBody, xDbCatalog, xOperationId }) as unknown as Promise<TData>, ...options });
export const useAppVariablesServicePutAppVariable = <TData = Common.AppVariablesServicePutAppVariableMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    id: number;
    requestBody?: AppVariableWithoutId;
    xDbCatalog: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    id: number;
    requestBody?: AppVariableWithoutId;
    xDbCatalog: string;
}, TContext>({ mutationFn: ({ id, requestBody, xDbCatalog }) => AppVariablesService.putAppVariable({ id, requestBody, xDbCatalog }) as unknown as Promise<TData>, ...options });
export const useArticlesServicePutArticle = <TData = Common.ArticlesServicePutArticleMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    articleCode: string;
    requestBody?: Article;
    xDbCatalog: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    articleCode: string;
    requestBody?: Article;
    xDbCatalog: string;
}, TContext>({ mutationFn: ({ articleCode, requestBody, xDbCatalog }) => ArticlesService.putArticle({ articleCode, requestBody, xDbCatalog }) as unknown as Promise<TData>, ...options });
export const useAttachedFilesServicePutAttachedFile = <TData = Common.AttachedFilesServicePutAttachedFileMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    requestBody?: AttachedFilesWithoutSequential;
    sequential: number;
    xDbCatalog: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    requestBody?: AttachedFilesWithoutSequential;
    sequential: number;
    xDbCatalog: string;
}, TContext>({ mutationFn: ({ requestBody, sequential, xDbCatalog }) => AttachedFilesService.putAttachedFile({ requestBody, sequential, xDbCatalog }) as unknown as Promise<TData>, ...options });
export const useCatalogsServicePutCatalogs = <TData = Common.CatalogsServicePutCatalogsMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    requestBody?: CatalogsWithoutSequential;
    sequential: number;
    xDbCatalog: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    requestBody?: CatalogsWithoutSequential;
    sequential: number;
    xDbCatalog: string;
}, TContext>({ mutationFn: ({ requestBody, sequential, xDbCatalog }) => CatalogsService.putCatalogs({ requestBody, sequential, xDbCatalog }) as unknown as Promise<TData>, ...options });
export const useContactDetailsServicePutContactDetails = <TData = Common.ContactDetailsServicePutContactDetailsMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    requestBody?: ContactDetailsWithoutSequential;
    sequential: number;
    xDbCatalog: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    requestBody?: ContactDetailsWithoutSequential;
    sequential: number;
    xDbCatalog: string;
}, TContext>({ mutationFn: ({ requestBody, sequential, xDbCatalog }) => ContactDetailsService.putContactDetails({ requestBody, sequential, xDbCatalog }) as unknown as Promise<TData>, ...options });
export const useDemographicDataServicePutDemographicData = <TData = Common.DemographicDataServicePutDemographicDataMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    requestBody?: DemographicDataWithoutSequential;
    sequential: number;
    xDbCatalog: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    requestBody?: DemographicDataWithoutSequential;
    sequential: number;
    xDbCatalog: string;
}, TContext>({ mutationFn: ({ requestBody, sequential, xDbCatalog }) => DemographicDataService.putDemographicData({ requestBody, sequential, xDbCatalog }) as unknown as Promise<TData>, ...options });
export const useDynamicNamesServicePutDynamicNames = <TData = Common.DynamicNamesServicePutDynamicNamesMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    requestBody?: DynamicNamesWithoutSequential;
    sequential: number;
    xDbCatalog: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    requestBody?: DynamicNamesWithoutSequential;
    sequential: number;
    xDbCatalog: string;
}, TContext>({ mutationFn: ({ requestBody, sequential, xDbCatalog }) => DynamicNamesService.putDynamicNames({ requestBody, sequential, xDbCatalog }) as unknown as Promise<TData>, ...options });
export const useEmploymentDataServicePutEmploymentData = <TData = Common.EmploymentDataServicePutEmploymentDataMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    requestBody?: EmploymentDataWithoutSequential;
    sequential: number;
    xDbCatalog: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    requestBody?: EmploymentDataWithoutSequential;
    sequential: number;
    xDbCatalog: string;
}, TContext>({ mutationFn: ({ requestBody, sequential, xDbCatalog }) => EmploymentDataService.putEmploymentData({ requestBody, sequential, xDbCatalog }) as unknown as Promise<TData>, ...options });
export const useFieldsServicePutFields = <TData = Common.FieldsServicePutFieldsMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    requestBody?: FieldsWithoutSequential;
    sequential: number;
    xDbCatalog: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    requestBody?: FieldsWithoutSequential;
    sequential: number;
    xDbCatalog: string;
}, TContext>({ mutationFn: ({ requestBody, sequential, xDbCatalog }) => FieldsService.putFields({ requestBody, sequential, xDbCatalog }) as unknown as Promise<TData>, ...options });
export const useFlowDocumentsServicePutFlowDocuments = <TData = Common.FlowDocumentsServicePutFlowDocumentsMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    requestBody?: FlowDocumentsWithoutSequential;
    sequential: number;
    xDbCatalog: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    requestBody?: FlowDocumentsWithoutSequential;
    sequential: number;
    xDbCatalog: string;
}, TContext>({ mutationFn: ({ requestBody, sequential, xDbCatalog }) => FlowDocumentsService.putFlowDocuments({ requestBody, sequential, xDbCatalog }) as unknown as Promise<TData>, ...options });
export const useFlowStagesServicePutFlowStages = <TData = Common.FlowStagesServicePutFlowStagesMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    requestBody?: FlowStagesWithoutSequential;
    sequential: number;
    xDbCatalog: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    requestBody?: FlowStagesWithoutSequential;
    sequential: number;
    xDbCatalog: string;
}, TContext>({ mutationFn: ({ requestBody, sequential, xDbCatalog }) => FlowStagesService.putFlowStages({ requestBody, sequential, xDbCatalog }) as unknown as Promise<TData>, ...options });
export const useFormatsServicePutFormats = <TData = Common.FormatsServicePutFormatsMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    requestBody?: FormatsWithoutSequential;
    sequential: number;
    xDbCatalog: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    requestBody?: FormatsWithoutSequential;
    sequential: number;
    xDbCatalog: string;
}, TContext>({ mutationFn: ({ requestBody, sequential, xDbCatalog }) => FormatsService.putFormats({ requestBody, sequential, xDbCatalog }) as unknown as Promise<TData>, ...options });
export const useGroupsServicePutGroups = <TData = Common.GroupsServicePutGroupsMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    requestBody?: GroupsWithoutSequential;
    sequential: number;
    xDbCatalog: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    requestBody?: GroupsWithoutSequential;
    sequential: number;
    xDbCatalog: string;
}, TContext>({ mutationFn: ({ requestBody, sequential, xDbCatalog }) => GroupsService.putGroups({ requestBody, sequential, xDbCatalog }) as unknown as Promise<TData>, ...options });
export const useGuaranteeOwnersServicePutGuaranteeOwners = <TData = Common.GuaranteeOwnersServicePutGuaranteeOwnersMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    requestBody?: GuaranteeOwnersWithoutSequential;
    sequential: number;
    xDbCatalog: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    requestBody?: GuaranteeOwnersWithoutSequential;
    sequential: number;
    xDbCatalog: string;
}, TContext>({ mutationFn: ({ requestBody, sequential, xDbCatalog }) => GuaranteeOwnersService.putGuaranteeOwners({ requestBody, sequential, xDbCatalog }) as unknown as Promise<TData>, ...options });
export const useGuaranteesServicePutGuarantees = <TData = Common.GuaranteesServicePutGuaranteesMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    requestBody?: GuaranteesWithoutSequential;
    sequential: number;
    xDbCatalog: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    requestBody?: GuaranteesWithoutSequential;
    sequential: number;
    xDbCatalog: string;
}, TContext>({ mutationFn: ({ requestBody, sequential, xDbCatalog }) => GuaranteesService.putGuarantees({ requestBody, sequential, xDbCatalog }) as unknown as Promise<TData>, ...options });
export const useHolidaysServicePutHolidays = <TData = Common.HolidaysServicePutHolidaysMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    requestBody?: HolidaysWithoutSequential;
    sequential: number;
    xDbCatalog: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    requestBody?: HolidaysWithoutSequential;
    sequential: number;
    xDbCatalog: string;
}, TContext>({ mutationFn: ({ requestBody, sequential, xDbCatalog }) => HolidaysService.putHolidays({ requestBody, sequential, xDbCatalog }) as unknown as Promise<TData>, ...options });
export const useLiabilitiesServicePutLiabilities = <TData = Common.LiabilitiesServicePutLiabilitiesMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    number: number;
    requestBody?: LiabilitiesWithoutNumber;
    xDbCatalog: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    number: number;
    requestBody?: LiabilitiesWithoutNumber;
    xDbCatalog: string;
}, TContext>({ mutationFn: ({ number, requestBody, xDbCatalog }) => LiabilitiesService.putLiabilities({ number, requestBody, xDbCatalog }) as unknown as Promise<TData>, ...options });
export const useManagementServicePutManagementDetails = <TData = Common.ManagementServicePutManagementDetailsMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    requestBody?: ManagementWithoutSequential;
    sequential: number;
    xDbCatalog: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    requestBody?: ManagementWithoutSequential;
    sequential: number;
    xDbCatalog: string;
}, TContext>({ mutationFn: ({ requestBody, sequential, xDbCatalog }) => ManagementService.putManagementDetails({ requestBody, sequential, xDbCatalog }) as unknown as Promise<TData>, ...options });
export const useMonthlyPaymentsServicePutMonthlyPayments = <TData = Common.MonthlyPaymentsServicePutMonthlyPaymentsMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    clientCode: number;
    requestBody?: MonthlyPaymentsWithoutClientCode;
    xDbCatalog: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    clientCode: number;
    requestBody?: MonthlyPaymentsWithoutClientCode;
    xDbCatalog: string;
}, TContext>({ mutationFn: ({ clientCode, requestBody, xDbCatalog }) => MonthlyPaymentsService.putMonthlyPayments({ clientCode, requestBody, xDbCatalog }) as unknown as Promise<TData>, ...options });
export const useOperatingFlowsServicePutOperatingFlows = <TData = Common.OperatingFlowsServicePutOperatingFlowsMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    requestBody?: OperatingFlowsWithoutSequential;
    sequential: number;
    xDbCatalog: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    requestBody?: OperatingFlowsWithoutSequential;
    sequential: number;
    xDbCatalog: string;
}, TContext>({ mutationFn: ({ requestBody, sequential, xDbCatalog }) => OperatingFlowsService.putOperatingFlows({ requestBody, sequential, xDbCatalog }) as unknown as Promise<TData>, ...options });
export const useOrderItemsServicePutOrderItems = <TData = Common.OrderItemsServicePutOrderItemsMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    orderId: number;
    requestBody?: OrderItemsWithoutOrderID;
    xDbCatalog: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    orderId: number;
    requestBody?: OrderItemsWithoutOrderID;
    xDbCatalog: string;
}, TContext>({ mutationFn: ({ orderId, requestBody, xDbCatalog }) => OrderItemsService.putOrderItems({ orderId, requestBody, xDbCatalog }) as unknown as Promise<TData>, ...options });
export const useOtherPersonsServicePutOtherPersons = <TData = Common.OtherPersonsServicePutOtherPersonsMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    clientCode: number;
    requestBody?: OtherPersonsWithoutClientCode;
    xDbCatalog: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    clientCode: number;
    requestBody?: OtherPersonsWithoutClientCode;
    xDbCatalog: string;
}, TContext>({ mutationFn: ({ clientCode, requestBody, xDbCatalog }) => OtherPersonsService.putOtherPersons({ clientCode, requestBody, xDbCatalog }) as unknown as Promise<TData>, ...options });
export const usePaymentAgreementsServicePutPaymentAgreement = <TData = Common.PaymentAgreementsServicePutPaymentAgreementMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    requestBody?: PaymentAgreementsWithoutSequential;
    sequential: number;
    xDbCatalog: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    requestBody?: PaymentAgreementsWithoutSequential;
    sequential: number;
    xDbCatalog: string;
}, TContext>({ mutationFn: ({ requestBody, sequential, xDbCatalog }) => PaymentAgreementsService.putPaymentAgreement({ requestBody, sequential, xDbCatalog }) as unknown as Promise<TData>, ...options });
export const usePersonalReferencesServicePutPersonalReferences = <TData = Common.PersonalReferencesServicePutPersonalReferencesMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    requestBody?: PersonalReferencesWithoutSequential;
    sequential: number;
    xDbCatalog: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    requestBody?: PersonalReferencesWithoutSequential;
    sequential: number;
    xDbCatalog: string;
}, TContext>({ mutationFn: ({ requestBody, sequential, xDbCatalog }) => PersonalReferencesService.putPersonalReferences({ requestBody, sequential, xDbCatalog }) as unknown as Promise<TData>, ...options });
export const usePersonsAddendumServicePutPersonsAddendum = <TData = Common.PersonsAddendumServicePutPersonsAddendumMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    clientCode: number;
    requestBody?: PersonsAddendumWithoutClientCode;
    xDbCatalog: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    clientCode: number;
    requestBody?: PersonsAddendumWithoutClientCode;
    xDbCatalog: string;
}, TContext>({ mutationFn: ({ clientCode, requestBody, xDbCatalog }) => PersonsAddendumService.putPersonsAddendum({ clientCode, requestBody, xDbCatalog }) as unknown as Promise<TData>, ...options });
export const usePromisesServiceUpdatePromise = <TData = Common.PromisesServiceUpdatePromiseMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    requestBody?: PromisesWithoutSequential;
    sequential: number;
    xDbCatalog: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    requestBody?: PromisesWithoutSequential;
    sequential: number;
    xDbCatalog: string;
}, TContext>({ mutationFn: ({ requestBody, sequential, xDbCatalog }) => PromisesService.updatePromise({ requestBody, sequential, xDbCatalog }) as unknown as Promise<TData>, ...options });
export const useQuotesServicePutQuotes = <TData = Common.QuotesServicePutQuotesMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    requestBody?: QuotesWithoutSequential;
    sequential: number;
    xDbCatalog: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    requestBody?: QuotesWithoutSequential;
    sequential: number;
    xDbCatalog: string;
}, TContext>({ mutationFn: ({ requestBody, sequential, xDbCatalog }) => QuotesService.putQuotes({ requestBody, sequential, xDbCatalog }) as unknown as Promise<TData>, ...options });
export const useReschedulesServicePutReschedules = <TData = Common.ReschedulesServicePutReschedulesMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    requestBody?: ReschedulesWithoutSequential;
    sequential: number;
    xDbCatalog: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    requestBody?: ReschedulesWithoutSequential;
    sequential: number;
    xDbCatalog: string;
}, TContext>({ mutationFn: ({ requestBody, sequential, xDbCatalog }) => ReschedulesService.putReschedules({ requestBody, sequential, xDbCatalog }) as unknown as Promise<TData>, ...options });
export const useRolesServicePutRole = <TData = Common.RolesServicePutRoleMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    requestBody?: RolesWithoutSequential;
    sequential: number;
    xDbCatalog: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    requestBody?: RolesWithoutSequential;
    sequential: number;
    xDbCatalog: string;
}, TContext>({ mutationFn: ({ requestBody, sequential, xDbCatalog }) => RolesService.putRole({ requestBody, sequential, xDbCatalog }) as unknown as Promise<TData>, ...options });
export const useRolesPermissionsServicePutRolesPermission = <TData = Common.RolesPermissionsServicePutRolesPermissionMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    requestBody?: RolesPermissionsWithoutSequential;
    sequential: number;
    xDbCatalog: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    requestBody?: RolesPermissionsWithoutSequential;
    sequential: number;
    xDbCatalog: string;
}, TContext>({ mutationFn: ({ requestBody, sequential, xDbCatalog }) => RolesPermissionsService.putRolesPermission({ requestBody, sequential, xDbCatalog }) as unknown as Promise<TData>, ...options });
export const useStrategiesServicePutStrategy = <TData = Common.StrategiesServicePutStrategyMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    requestBody?: StrategiesWithoutSequential;
    sequential: number;
    xDbCatalog: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    requestBody?: StrategiesWithoutSequential;
    sequential: number;
    xDbCatalog: string;
}, TContext>({ mutationFn: ({ requestBody, sequential, xDbCatalog }) => StrategiesService.putStrategy({ requestBody, sequential, xDbCatalog }) as unknown as Promise<TData>, ...options });
export const useStrategyFormatsServicePutStrategyFormats = <TData = Common.StrategyFormatsServicePutStrategyFormatsMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    requestBody?: StrategyFormatsWithoutSequential;
    sequential: number;
    xDbCatalog: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    requestBody?: StrategyFormatsWithoutSequential;
    sequential: number;
    xDbCatalog: string;
}, TContext>({ mutationFn: ({ requestBody, sequential, xDbCatalog }) => StrategyFormatsService.putStrategyFormats({ requestBody, sequential, xDbCatalog }) as unknown as Promise<TData>, ...options });
export const useUrlsServicePutUrls = <TData = Common.UrlsServicePutUrlsMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    requestBody?: UrlsBodyWithoutUrlID;
    urlId: number;
    xDbCatalog: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    requestBody?: UrlsBodyWithoutUrlID;
    urlId: number;
    xDbCatalog: string;
}, TContext>({ mutationFn: ({ requestBody, urlId, xDbCatalog }) => UrlsService.putUrls({ requestBody, urlId, xDbCatalog }) as unknown as Promise<TData>, ...options });
export const useUsersServicePutUsers = <TData = Common.UsersServicePutUsersMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    requestBody?: UsersWithoutSequential;
    sequential: number;
    xDbCatalog: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    requestBody?: UsersWithoutSequential;
    sequential: number;
    xDbCatalog: string;
}, TContext>({ mutationFn: ({ requestBody, sequential, xDbCatalog }) => UsersService.putUsers({ requestBody, sequential, xDbCatalog }) as unknown as Promise<TData>, ...options });
export const useAppVariablesServiceDeleteAppVariable = <TData = Common.AppVariablesServiceDeleteAppVariableMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    id: number;
    xDbCatalog: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    id: number;
    xDbCatalog: string;
}, TContext>({ mutationFn: ({ id, xDbCatalog }) => AppVariablesService.deleteAppVariable({ id, xDbCatalog }) as unknown as Promise<TData>, ...options });
export const useArticlesServiceDeleteArticle = <TData = Common.ArticlesServiceDeleteArticleMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    articleCode: string;
    xDbCatalog: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    articleCode: string;
    xDbCatalog: string;
}, TContext>({ mutationFn: ({ articleCode, xDbCatalog }) => ArticlesService.deleteArticle({ articleCode, xDbCatalog }) as unknown as Promise<TData>, ...options });
export const useAttachedFilesServiceDeleteAttachedFile = <TData = Common.AttachedFilesServiceDeleteAttachedFileMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    sequential: number;
    xDbCatalog: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    sequential: number;
    xDbCatalog: string;
}, TContext>({ mutationFn: ({ sequential, xDbCatalog }) => AttachedFilesService.deleteAttachedFile({ sequential, xDbCatalog }) as unknown as Promise<TData>, ...options });
export const useCatalogsServiceDeleteCatalogs = <TData = Common.CatalogsServiceDeleteCatalogsMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    sequential: number;
    xDbCatalog: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    sequential: number;
    xDbCatalog: string;
}, TContext>({ mutationFn: ({ sequential, xDbCatalog }) => CatalogsService.deleteCatalogs({ sequential, xDbCatalog }) as unknown as Promise<TData>, ...options });
export const useContactDetailsServiceDeleteContactDetails = <TData = Common.ContactDetailsServiceDeleteContactDetailsMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    sequential: number;
    xDbCatalog: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    sequential: number;
    xDbCatalog: string;
}, TContext>({ mutationFn: ({ sequential, xDbCatalog }) => ContactDetailsService.deleteContactDetails({ sequential, xDbCatalog }) as unknown as Promise<TData>, ...options });
export const useDemographicDataServiceDeleteDemographicData = <TData = Common.DemographicDataServiceDeleteDemographicDataMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    sequential: number;
    xDbCatalog: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    sequential: number;
    xDbCatalog: string;
}, TContext>({ mutationFn: ({ sequential, xDbCatalog }) => DemographicDataService.deleteDemographicData({ sequential, xDbCatalog }) as unknown as Promise<TData>, ...options });
export const useDynamicNamesServiceDeleteDynamicNames = <TData = Common.DynamicNamesServiceDeleteDynamicNamesMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    sequential: number;
    xDbCatalog: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    sequential: number;
    xDbCatalog: string;
}, TContext>({ mutationFn: ({ sequential, xDbCatalog }) => DynamicNamesService.deleteDynamicNames({ sequential, xDbCatalog }) as unknown as Promise<TData>, ...options });
export const useEmploymentDataServiceDeleteEmploymentData = <TData = Common.EmploymentDataServiceDeleteEmploymentDataMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    sequential: number;
    xDbCatalog: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    sequential: number;
    xDbCatalog: string;
}, TContext>({ mutationFn: ({ sequential, xDbCatalog }) => EmploymentDataService.deleteEmploymentData({ sequential, xDbCatalog }) as unknown as Promise<TData>, ...options });
export const useFlowDocumentsServiceDeleteFlowDocuments = <TData = Common.FlowDocumentsServiceDeleteFlowDocumentsMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    sequential: number;
    xDbCatalog: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    sequential: number;
    xDbCatalog: string;
}, TContext>({ mutationFn: ({ sequential, xDbCatalog }) => FlowDocumentsService.deleteFlowDocuments({ sequential, xDbCatalog }) as unknown as Promise<TData>, ...options });
export const useFlowStagesServiceDeleteFlowStages = <TData = Common.FlowStagesServiceDeleteFlowStagesMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    sequential: number;
    xDbCatalog: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    sequential: number;
    xDbCatalog: string;
}, TContext>({ mutationFn: ({ sequential, xDbCatalog }) => FlowStagesService.deleteFlowStages({ sequential, xDbCatalog }) as unknown as Promise<TData>, ...options });
export const useFormatsServiceDeleteFormats = <TData = Common.FormatsServiceDeleteFormatsMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    sequential: number;
    xDbCatalog: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    sequential: number;
    xDbCatalog: string;
}, TContext>({ mutationFn: ({ sequential, xDbCatalog }) => FormatsService.deleteFormats({ sequential, xDbCatalog }) as unknown as Promise<TData>, ...options });
export const useGroupsServiceDeleteGroups = <TData = Common.GroupsServiceDeleteGroupsMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    sequential: number;
    xDbCatalog: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    sequential: number;
    xDbCatalog: string;
}, TContext>({ mutationFn: ({ sequential, xDbCatalog }) => GroupsService.deleteGroups({ sequential, xDbCatalog }) as unknown as Promise<TData>, ...options });
export const useGuaranteeOwnersServiceDeleteGuaranteeOwners = <TData = Common.GuaranteeOwnersServiceDeleteGuaranteeOwnersMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    sequential: number;
    xDbCatalog: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    sequential: number;
    xDbCatalog: string;
}, TContext>({ mutationFn: ({ sequential, xDbCatalog }) => GuaranteeOwnersService.deleteGuaranteeOwners({ sequential, xDbCatalog }) as unknown as Promise<TData>, ...options });
export const useGuaranteesServiceDeleteGuarantees = <TData = Common.GuaranteesServiceDeleteGuaranteesMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    sequential: number;
    xDbCatalog: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    sequential: number;
    xDbCatalog: string;
}, TContext>({ mutationFn: ({ sequential, xDbCatalog }) => GuaranteesService.deleteGuarantees({ sequential, xDbCatalog }) as unknown as Promise<TData>, ...options });
export const useHolidaysServiceDeleteHolidays = <TData = Common.HolidaysServiceDeleteHolidaysMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    sequential: number;
    xDbCatalog: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    sequential: number;
    xDbCatalog: string;
}, TContext>({ mutationFn: ({ sequential, xDbCatalog }) => HolidaysService.deleteHolidays({ sequential, xDbCatalog }) as unknown as Promise<TData>, ...options });
export const useLiabilitiesServiceDeleteLiabilities = <TData = Common.LiabilitiesServiceDeleteLiabilitiesMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    number: number;
    xDbCatalog: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    number: number;
    xDbCatalog: string;
}, TContext>({ mutationFn: ({ number, xDbCatalog }) => LiabilitiesService.deleteLiabilities({ number, xDbCatalog }) as unknown as Promise<TData>, ...options });
export const useManagementServiceDeleteManagementDetails = <TData = Common.ManagementServiceDeleteManagementDetailsMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    sequential: number;
    xDbCatalog: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    sequential: number;
    xDbCatalog: string;
}, TContext>({ mutationFn: ({ sequential, xDbCatalog }) => ManagementService.deleteManagementDetails({ sequential, xDbCatalog }) as unknown as Promise<TData>, ...options });
export const useMonthlyPaymentsServiceDeleteMonthlyPayments = <TData = Common.MonthlyPaymentsServiceDeleteMonthlyPaymentsMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    clientCode: number;
    xDbCatalog: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    clientCode: number;
    xDbCatalog: string;
}, TContext>({ mutationFn: ({ clientCode, xDbCatalog }) => MonthlyPaymentsService.deleteMonthlyPayments({ clientCode, xDbCatalog }) as unknown as Promise<TData>, ...options });
export const useOperatingFlowsServiceDeleteOperatingFlows = <TData = Common.OperatingFlowsServiceDeleteOperatingFlowsMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    sequential: number;
    xDbCatalog: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    sequential: number;
    xDbCatalog: string;
}, TContext>({ mutationFn: ({ sequential, xDbCatalog }) => OperatingFlowsService.deleteOperatingFlows({ sequential, xDbCatalog }) as unknown as Promise<TData>, ...options });
export const useOrderItemsServiceDeleteOrderItems = <TData = Common.OrderItemsServiceDeleteOrderItemsMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    orderId: number;
    xDbCatalog: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    orderId: number;
    xDbCatalog: string;
}, TContext>({ mutationFn: ({ orderId, xDbCatalog }) => OrderItemsService.deleteOrderItems({ orderId, xDbCatalog }) as unknown as Promise<TData>, ...options });
export const useOtherPersonsServiceDeleteOtherPersons = <TData = Common.OtherPersonsServiceDeleteOtherPersonsMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    clientCode: number;
    xDbCatalog: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    clientCode: number;
    xDbCatalog: string;
}, TContext>({ mutationFn: ({ clientCode, xDbCatalog }) => OtherPersonsService.deleteOtherPersons({ clientCode, xDbCatalog }) as unknown as Promise<TData>, ...options });
export const usePaymentAgreementsServiceDeletePaymentAgreement = <TData = Common.PaymentAgreementsServiceDeletePaymentAgreementMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    sequential: number;
    xDbCatalog: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    sequential: number;
    xDbCatalog: string;
}, TContext>({ mutationFn: ({ sequential, xDbCatalog }) => PaymentAgreementsService.deletePaymentAgreement({ sequential, xDbCatalog }) as unknown as Promise<TData>, ...options });
export const usePersonalReferencesServiceDeletePersonalReferences = <TData = Common.PersonalReferencesServiceDeletePersonalReferencesMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    sequential: number;
    xDbCatalog: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    sequential: number;
    xDbCatalog: string;
}, TContext>({ mutationFn: ({ sequential, xDbCatalog }) => PersonalReferencesService.deletePersonalReferences({ sequential, xDbCatalog }) as unknown as Promise<TData>, ...options });
export const usePersonsAddendumServiceDeletePersonsAddendum = <TData = Common.PersonsAddendumServiceDeletePersonsAddendumMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    clientCode: number;
    xDbCatalog: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    clientCode: number;
    xDbCatalog: string;
}, TContext>({ mutationFn: ({ clientCode, xDbCatalog }) => PersonsAddendumService.deletePersonsAddendum({ clientCode, xDbCatalog }) as unknown as Promise<TData>, ...options });
export const usePromisesServiceDeletePromise = <TData = Common.PromisesServiceDeletePromiseMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    sequential: number;
    xDbCatalog: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    sequential: number;
    xDbCatalog: string;
}, TContext>({ mutationFn: ({ sequential, xDbCatalog }) => PromisesService.deletePromise({ sequential, xDbCatalog }) as unknown as Promise<TData>, ...options });
export const useQuotesServiceDeleteQuotes = <TData = Common.QuotesServiceDeleteQuotesMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    sequential: number;
    xDbCatalog: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    sequential: number;
    xDbCatalog: string;
}, TContext>({ mutationFn: ({ sequential, xDbCatalog }) => QuotesService.deleteQuotes({ sequential, xDbCatalog }) as unknown as Promise<TData>, ...options });
export const useReschedulesServiceDeleteReschedules = <TData = Common.ReschedulesServiceDeleteReschedulesMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    sequential: number;
    xDbCatalog: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    sequential: number;
    xDbCatalog: string;
}, TContext>({ mutationFn: ({ sequential, xDbCatalog }) => ReschedulesService.deleteReschedules({ sequential, xDbCatalog }) as unknown as Promise<TData>, ...options });
export const useRolesServiceDeleteRole = <TData = Common.RolesServiceDeleteRoleMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    sequential: number;
    xDbCatalog: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    sequential: number;
    xDbCatalog: string;
}, TContext>({ mutationFn: ({ sequential, xDbCatalog }) => RolesService.deleteRole({ sequential, xDbCatalog }) as unknown as Promise<TData>, ...options });
export const useRolesPermissionsServiceDeleteRolesPermission = <TData = Common.RolesPermissionsServiceDeleteRolesPermissionMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    sequential: number;
    xDbCatalog: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    sequential: number;
    xDbCatalog: string;
}, TContext>({ mutationFn: ({ sequential, xDbCatalog }) => RolesPermissionsService.deleteRolesPermission({ sequential, xDbCatalog }) as unknown as Promise<TData>, ...options });
export const useStrategiesServiceDeleteStrategy = <TData = Common.StrategiesServiceDeleteStrategyMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    sequential: number;
    xDbCatalog: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    sequential: number;
    xDbCatalog: string;
}, TContext>({ mutationFn: ({ sequential, xDbCatalog }) => StrategiesService.deleteStrategy({ sequential, xDbCatalog }) as unknown as Promise<TData>, ...options });
export const useStrategyFormatsServiceDeleteStrategyFormats = <TData = Common.StrategyFormatsServiceDeleteStrategyFormatsMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    sequential: number;
    xDbCatalog: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    sequential: number;
    xDbCatalog: string;
}, TContext>({ mutationFn: ({ sequential, xDbCatalog }) => StrategyFormatsService.deleteStrategyFormats({ sequential, xDbCatalog }) as unknown as Promise<TData>, ...options });
export const useUrlsServiceDeleteUrls = <TData = Common.UrlsServiceDeleteUrlsMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    urlId: number;
    xDbCatalog: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    urlId: number;
    xDbCatalog: string;
}, TContext>({ mutationFn: ({ urlId, xDbCatalog }) => UrlsService.deleteUrls({ urlId, xDbCatalog }) as unknown as Promise<TData>, ...options });
export const useUsersServiceDeleteUsers = <TData = Common.UsersServiceDeleteUsersMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
    sequential: number;
    xDbCatalog: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
    sequential: number;
    xDbCatalog: string;
}, TContext>({ mutationFn: ({ sequential, xDbCatalog }) => UsersService.deleteUsers({ sequential, xDbCatalog }) as unknown as Promise<TData>, ...options });
