import packageJson from "@/../package.json";

export function cacheCheck() {
  const currentVersion = packageJson.version;
  const storedVersion = localStorage.getItem("appVersion");
  if (storedVersion !== currentVersion) {
    localStorage.clear();
    localStorage.setItem("appVersion", currentVersion);
  }
}
