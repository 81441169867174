import { FallbackComponent } from "@/components/FallbackComponent";
import { LoadingAccess } from "@/components/LoadingAccess";
import { Unauthorized } from "@/components/Unauthorized";
import { useExecutiveMaintenance } from "@/hooks/usePages";
import reactLazyWithRetry from "@fatso83/retry-dynamic-import/react-lazy";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import { ErrorBoundary } from "@sentry/react";
import { Suspense, StrictMode } from "react";

const Roles = reactLazyWithRetry(async () => {
  return {
    default: (await import("@/components/ExecutiveMaintenance/Roles")).Roles,
  };
});

const Executives = reactLazyWithRetry(async () => {
  return {
    default: (await import("@/components/ExecutiveMaintenance/Executives"))
      .Executives,
  };
});

const ExecutiveEditor = reactLazyWithRetry(async () => {
  return {
    default: (await import("@/components/ExecutiveMaintenance/ExecutiveEditor"))
      .ExecutiveEditor,
  };
});

const RoleEditor = reactLazyWithRetry(async () => {
  return {
    default: (await import("@/components/ExecutiveMaintenance/RoleEditor"))
      .RoleEditor,
  };
});

export const Component = () => {
  const { showRoleEditor, hasViewPermission, loadingPermission } =
    useExecutiveMaintenance();

  if (loadingPermission) {
    return <LoadingAccess />;
  }

  if (!hasViewPermission) {
    return <Unauthorized />;
  }

  return (
    <>
      <StrictMode>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "roles & roleeditor");
          }}
          fallback={FallbackComponent}
        >
          <Box
            display="flex"
            gap={2}
            flexDirection={{ md: "column", lg: "row" }}
            maxHeight={500}
          >
            <ErrorBoundary
              beforeCapture={(scope) => {
                scope.setTag("component", "roles");
              }}
              fallback={FallbackComponent}
            >
              <Suspense
                fallback={
                  <Skeleton variant="rounded" width="100%" height={341} />
                }
              >
                <Roles />
              </Suspense>
            </ErrorBoundary>
            <ErrorBoundary
              beforeCapture={(scope) => {
                scope.setTag("component", "roleeditor");
              }}
              fallback={FallbackComponent}
            >
              <Suspense
                fallback={
                  <Skeleton variant="rounded" width="100%" height={341} />
                }
              >
                {showRoleEditor && <RoleEditor />}
              </Suspense>
            </ErrorBoundary>
          </Box>
        </ErrorBoundary>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "executives");
          }}
          fallback={FallbackComponent}
        >
          <Suspense
            fallback={<Skeleton variant="rounded" width="100%" height={341} />}
          >
            <Executives />
          </Suspense>
        </ErrorBoundary>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "executiveeditor");
          }}
          fallback={FallbackComponent}
        >
          <Suspense
            fallback={<Skeleton variant="rounded" width="100%" height={272} />}
          >
            <ExecutiveEditor />
          </Suspense>
        </ErrorBoundary>
      </StrictMode>
    </>
  );
};

Component.displayName = "ExecutiveMaintenance";
