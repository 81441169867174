import { FallbackComponent } from "@/components/FallbackComponent";
import { LoadingAccess } from "@/components/LoadingAccess";
import { Unauthorized } from "@/components/Unauthorized";
import { useStrategyMaintenance } from "@/hooks/usePages";
import reactLazyWithRetry from "@fatso83/retry-dynamic-import/react-lazy";
import Modal from "@mui/material/Modal";
import Skeleton from "@mui/material/Skeleton";
import { ErrorBoundary } from "@sentry/react";
import { Suspense, StrictMode } from "react";

const RuleBuilder = reactLazyWithRetry(async () => {
  return {
    default: (await import("@/components/StrategyMaintenance/RuleBuilder"))
      .RuleBuilder,
  };
});

const RuleBuilderV2 = reactLazyWithRetry(async () => {
  return {
    default: (await import("@/components/StrategyMaintenance/RuleBuilderV2"))
      .RuleBuilderV2,
  };
});


const Strategies = reactLazyWithRetry(async () => {
  return {
    default: (await import("@/components/StrategyMaintenance/Strategies"))
      .Strategies,
  };
});

const StrategyEditor = reactLazyWithRetry(async () => {
  return {
    default: (await import("@/components/StrategyMaintenance/StrategyEditor"))
      .StrategyEditor,
  };
});

const Formats = reactLazyWithRetry(async () => {
  return {
    default: (await import("@/components/StrategyMaintenance/Formats")).Formats,
  };
});

const DynamicFieldData = reactLazyWithRetry(async () => {
  return {
    default: (await import("@/components/DynamicDataFields")).DynamicDataFields,
  };
});

const SpreadSheet = reactLazyWithRetry(async () => {
  return {
    default: (await import("@/components/StrategyMaintenance/SpreadSheet"))
      .SpreadSheet,
  };
});

export const Component = () => {
  const {
    showDynamicFieldDataGrid,
    showFormats,
    hasViewPermission,
    loadingPermission,
    enableSegmentationV2
  } = useStrategyMaintenance();

  if (loadingPermission) {
    return <LoadingAccess />;
  }

  if (!hasViewPermission) {
    return <Unauthorized />;
  }
  return (
    <>
      <StrictMode>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "strategies");
          }}
          fallback={FallbackComponent}
        >
          <Suspense fallback={<Skeleton height={341} />}>
            <Strategies />
          </Suspense>
        </ErrorBoundary>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "rulebuilder");
          }}
          fallback={FallbackComponent}
        >
          <Suspense fallback={<Skeleton height={95} />}>
            {enableSegmentationV2 ? <RuleBuilderV2 /> :<RuleBuilder />}
          </Suspense>
        </ErrorBoundary>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "strategyeditor");
          }}
          fallback={FallbackComponent}
        >
          <Suspense fallback={<Skeleton height={405} />}>
            <StrategyEditor />
          </Suspense>
        </ErrorBoundary>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "spreadsheet");
          }}
          fallback={FallbackComponent}
        >
          <Suspense fallback={<Skeleton height={405} />}>
            <SpreadSheet />
          </Suspense>
        </ErrorBoundary>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "dynamicfielddata");
          }}
          fallback={FallbackComponent}
        >
          <Suspense
            fallback={
              <Modal open={true} sx={{ mx: 2 }}>
                <Skeleton
                  height={280}
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: {
                      xs: "calc(100% - 32px)",
                      md: "100%",
                    },
                    maxWidth: 671,
                  }}
                />
              </Modal>
            }
          >
            {showDynamicFieldDataGrid && <DynamicFieldData />}
          </Suspense>
        </ErrorBoundary>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "formats");
          }}
          fallback={FallbackComponent}
        >
          <Suspense
            fallback={
              <Modal open={true} sx={{ mx: 2 }}>
                <Skeleton
                  height={380}
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: {
                      xs: "calc(100% - 32px)",
                      md: "100%",
                    },
                    maxWidth: 671,
                  }}
                />
              </Modal>
            }
          >
            {showFormats && <Formats />}
          </Suspense>
        </ErrorBoundary>
      </StrictMode>
    </>
  );
};

Component.displayName = "StrategyMaintenance";
