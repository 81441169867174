import { atom } from "jotai";
import secureLocalStorage from "react-secure-storage";

export const atomWithLocalStorage = <T>(key: string, initialValue: T) => {
  const getInitialValue = (): T => {
    try {
      const item = secureLocalStorage.getItem(key);
      if (item !== null) {
        return JSON.parse(item as string);
      }
      return initialValue;
    } catch (_e) {
      return initialValue;
    }
  };
  const baseAtom = atom(getInitialValue());
  const derivedAtom = atom(
    (get) => get(baseAtom),
    (get, set, update) => {
      const nextValue =
        typeof update === "function" ? update(get(baseAtom)) : update;
      set(baseAtom, nextValue);
      try {
        secureLocalStorage.setItem(key, JSON.stringify(nextValue));
      } catch (_error) {
        secureLocalStorage.removeItem(key);
      }
    },
  );
  return derivedAtom;
};
