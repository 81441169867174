import { atom } from "recoil";

export const scrollTopState = atom({
  key: "scrollTop",
  default: 0,
});

export const showSidebarState = atom({
  key: "showSidebar",
  default: window.innerWidth > 900,
});
