import { MoneyFlag } from "@/components/MoneyFlag";
import type {
  ClientPortfolioR,
  ColumnFieldName,
  FilterStrategies,
  MonthDay,
  Months,
} from "@/types";
import { getClientPortfolioUrl } from "@/utils/endpoints";
import { get } from "@/utils/fetchApi";
import { localStorageEffect } from "@/utils/localStorage";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import Chip from "@mui/material/Chip";
import type {
  DefaultizedPieValueType,
  PieItemIdentifier,
} from "@mui/x-charts/models/seriesType";
import type { GridRowSelectionModel } from "@mui/x-data-grid/models";
import { capitalCase } from "change-case";
import dayjs from "dayjs";
import { useLoaderData } from "react-router-dom";
import { atom, selector } from "recoil";
import { headersJsonState } from "./login";

export const columnFieldsState = atom<ColumnFieldName<ClientPortfolioR>[]>({
  key: "homeColumnFields",
  default: [
    {
      field: "rangoMora",
      headerName: "Rango de Mora",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <Chip
          sx={{
            backgroundColor: "inherit",
            fontWeight: "medium",
            ".MuiChip-icon": {
              marginRight: "-2px",
            },
          }}
          icon={<AccountBalanceWalletIcon fontSize="small" color="inherit" />}
          label={params.row.rangoMora}
        />
      ),
    },
    {
      field: "saldoTotal",
      headerName: "Saldo Total",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return <MoneyFlag label={params.row.saldoTotal} />;
      },
      type: "number",
    },
    {
      field: "cantidad",
      headerName: "Cantidad",
      flex: 1,
      minWidth: 150,
      type: "number",
    },
    {
      field: "gestionesTotales",
      headerName: "Gestiones Totales",
      flex: 1,
      minWidth: 150,
      type: "number",
    },
  ],
});

// TODO: Convert to atomFamily and use it in all charts
export const pieChartActiveItemState = atom<
  (DefaultizedPieValueType & PieItemIdentifier) | null
>({
  key: "homePieChartActiveIndex",
  default: null,
  effects: [localStorageEffect("pieChartActiveItemHomeKey")],
});

export const rowSelectionModelState = atom({
  key: "homeRowSelectionModel",
  default: selector<GridRowSelectionModel>({
    key: "homeRowSelectionModelSelector",
    get: () => {
      const rows = useLoaderData() as ClientPortfolioR[];
      return rows.map((data) => data.orden);
    },
    set: ({ set }, newValue) => {
      set(rowSelectionModelState, newValue);
    },
  }),
  effects: [localStorageEffect("rowSelectionHomeKey")],
});

// TODO: Convert to atomFamily and use it in all charts
export const chartKeysState = atom<{
  numericValues: { key: string; label: string }[];
  stringValues: { key: string; label: string }[];
}>({
  key: "chartKeysHome",
  default: selector({
    key: "chartKeysHomeSelector",
    get: async ({ get: recoilGet }) => {
      const headersJson = recoilGet(headersJsonState);
      const columns = recoilGet(columnFieldsState);
      const rows = await get<ClientPortfolioR[]>({
        url: getClientPortfolioUrl(),
        headers: headersJson,
      });
      if (rows?.length === 0 || rows === null) {
        return {
          numericValues: [
            {
              key: "",
              label: "",
            },
            {
              key: "",
              label: "",
            },
          ],
          stringValues: [
            {
              key: "",
              label: "",
            },
            {
              key: "",
              label: "",
            },
          ],
        };
      }

      function getKeysByType(type: "number" | "string") {
        if (!rows) {
          return [];
        }
        return Object.keys(rows[0])
          .map((key) => {
            if (
              (type === "number" &&
                typeof rows[0][key as never] === "number") ||
              (type === "string" && typeof rows[0][key as never] === "string")
            ) {
              const label = columns.find(
                (item) => item.field === key,
              )?.headerName;
              return {
                key,
                // FIXME: labels are not translated and add locale to capital case
                label: capitalCase(label ?? key),
              };
            }
          })
          .filter(Boolean);
      }

      const numericKeys = getKeysByType("number");
      const stringKeys = getKeysByType("string");

      if (numericKeys.length === 0 || stringKeys.length === 0) {
        return {
          numericValues: [
            {
              key: "",
              label: "",
            },
            {
              key: "",
              label: "",
            },
          ],
          stringValues: [
            {
              key: "",
              label: "",
            },
            {
              key: "",
              label: "",
            },
          ],
        };
      }
      return {
        numericValues: numericKeys as { key: string; label: string }[],
        stringValues: stringKeys as { key: string; label: string }[],
      };
    },
    set: ({ set }, newValue) => {
      set(chartKeysState, newValue);
    },
  }),
  effects: [localStorageEffect("chartKeysHome")],
});

// TODO: Convert to atomFamily and use it in all charts
export const selectedChartLabelState = atom({
  key: "selectedChartDataConfig",
  default: {
    key: "rangoMora",
    // FIXME: "Rango de Mora" is not translated
    label: "Rango de Mora",
  },
  effects: [localStorageEffect("selectedChartLabelHome")],
});

// TODO: Convert to atomFamily
export const selectedChartKeysState = atom({
  key: "selectedChartDatakeys",
  default: [
    {
      key: "saldoTotal",
      // FIXME: "Saldo Total" is not translated
      label: "Saldo Total",
    },
  ],
  effects: [localStorageEffect("selectedChartKeysHome")],
});

export const strategyFilterState = atom<FilterStrategies>({
  key: "strategyFilter",
  default: {
    year: dayjs().year(),
    month: dayjs().month() + 1,
    day: dayjs().date(),
  },
});

const monthsOfYear = Array.from({ length: 12 }, (_, i) => {
  const date = new Date(0, i);
  return {
    name: date.toLocaleString("es-Es", { month: "long" }),
    number: i + 1,
  };
});

export const monthsState = atom<Months[]>({
  key: "months",
  default: monthsOfYear,
});

const daysInMonth = (month: number, year: number): number => {
  return new Date(year, month, 0).getDate();
};

export const daysOfMonthState = selector<MonthDay[]>({
  key: "daysOfMonth",
  get: ({ get }) => {
    const filtro = get(strategyFilterState);
    if (filtro.month === null || filtro.year === null) {
      return [];
    }
    const numDays = daysInMonth(filtro.month, filtro.year);
    return Array.from({ length: numDays }, (_, i) => ({ number: i + 1 }));
  },
});

const currentYear = new Date().getFullYear();

const years = Array.from({ length: 30 }, (_, i) => currentYear - i);

export const yearsState = atom<number[]>({
  key: "years",
  default: years,
});
