import { drawerWidth } from "@/config/theme";
import { useLayout } from "@/hooks/useLayout";
import Box from "@mui/material/Box";
import type { SxProps, Theme } from "@mui/material/styles";
import { ErrorBoundary } from "@sentry/react";
import { useEffect } from "react";
import { Outlet, useNavigation } from "react-router-dom";
import { FallbackComponent } from "../FallbackComponent";
import { ProtectedRoute } from "./ProtectedRoute";
import { Sidebar } from "./Sidebar/Sidebar";
import { TopBar } from "./Topbar/TopBar";

export function Component() {
  const { setScrollTop, showSidebar } = useLayout();
  const navigation = useNavigation();
  const handleScroll = () => {
    setScrollTop(document.body.scrollTop);
  };
  useEffect(() => {
    document.body.addEventListener("scroll", handleScroll);
    return () => document.body.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  const loadingStyles: SxProps<Theme> = {
    opacity: 0.25,
    transition: "opacity 200ms",
    transitionDelay: "200ms",
  };

  return (
    <>
      <ErrorBoundary
        beforeCapture={(scope) => {
          scope.setTag("component", "TopBar");
        }}
        fallback={FallbackComponent}
      >
        <TopBar />
      </ErrorBoundary>
      <>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "SideBar");
          }}
          fallback={FallbackComponent}
        >
          <Sidebar />
        </ErrorBoundary>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "Outlet");
          }}
          fallback={FallbackComponent}
        >
          <Sidebar />
          <Box
            height="fit-content"
            sx={{
              ...(navigation.state === "loading" && loadingStyles),
              width: {
                xs: "calc(100% - 32px - 0px)",
                sm: "calc(100% - 32px - 0px)",
                md: `calc(100% - 32px - ${showSidebar ? drawerWidth : 0}px)`,
              },
              mt: {
                xs: 8,
                sm: 10,
                md: 10,
              },
              ml: {
                xs: 2,
                sm: 2,
                md: showSidebar ? `calc(${drawerWidth}px + 16px)` : 2,
              },
            }}
            display="flex"
            flexDirection="column"
            m={2}
            gap={2}
          >
            <ProtectedRoute>
              <Outlet />
            </ProtectedRoute>
          </Box>
        </ErrorBoundary>
      </>
    </>
  );
}
