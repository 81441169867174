import { FallbackComponent } from "@/components/FallbackComponent";
import { LoadingAccess } from "@/components/LoadingAccess";
import { Unauthorized } from "@/components/Unauthorized";
import { useHome } from "@/hooks/usePages";
import reactLazyWithRetry from "@fatso83/retry-dynamic-import/react-lazy";
import Skeleton from "@mui/material/Skeleton";
import { ErrorBoundary } from "@sentry/react";
import { Suspense, StrictMode } from "react";

const DataGrid = reactLazyWithRetry(async () => {
  return {
    default: (await import("@/components/Home/DataGrid")).DataGrid,
  };
});

const Chart = reactLazyWithRetry(async () => {
  return {
    default: (await import("@/components/Home/Chart")).Chart,
  };
});

const Cards = reactLazyWithRetry(async () => {
  return {
    default: (await import("@/components/Home/Cards")).Cards,
  };
});

export function Component() {
  const { hasViewPermission, loadingPermission } = useHome();

  if (loadingPermission) {
    return <LoadingAccess />;
  }

  if (!(hasViewPermission || loadingPermission)) {
    return <Unauthorized />;
  }

  return (
    <>
      <StrictMode>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "management");
          }}
          fallback={FallbackComponent}
        >
          <Suspense fallback={<Skeleton height={81} />}>
            <Cards />
          </Suspense>
        </ErrorBoundary>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "datagrid");
          }}
          fallback={FallbackComponent}
        >
          <Suspense
            fallback={<Skeleton variant="rounded" width="100%" height={341} />}
          >
            <DataGrid />
          </Suspense>
        </ErrorBoundary>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "chart");
          }}
          fallback={FallbackComponent}
        >
          <Suspense
            fallback={<Skeleton variant="rounded" width="100%" height={449} />}
          >
            <Chart />
          </Suspense>
        </ErrorBoundary>
      </StrictMode>
    </>
  );
}

Component.displayName = "Home";
