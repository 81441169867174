import { useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import { type RecoilValueReadOnly, useRecoilValueLoadable } from "recoil";

export const useLazyRecoilValue = <T,>(
  initialState: T,
  recoilState: RecoilValueReadOnly<T>,
  storageKey: string,
) => {
  const [state, setState] = useState<T>(() => {
    const storedState = secureLocalStorage.getItem(storageKey);
    if (storedState) {
      try {
        return JSON.parse(storedState as string);
      } catch (_error) {
        return initialState;
      }
    }
    return storedState ? JSON.parse(storedState as string) : initialState;
  });
  const loadable = useRecoilValueLoadable(recoilState);

  // biome-ignore lint/complexity/noExcessiveCognitiveComplexity: <explanation>
  useEffect(() => {
    if (loadable.state === "hasValue") {
      if (Array.isArray(loadable.contents) && loadable.contents.length > 0) {
        if (
          !Array.isArray(state) ||
          JSON.stringify(loadable.contents) !== JSON.stringify(state)
        ) {
          setState(loadable.contents);
        }
      } else if (typeof loadable.contents === "object") {
        if (
          typeof state === "object" &&
          JSON.stringify(loadable.contents) !== JSON.stringify(state)
        ) {
          setState(loadable.contents);
        }
      }
    }
  }, [loadable, state]);

  useEffect(() => {
    try {
      secureLocalStorage.setItem(storageKey, JSON.stringify(state));
    } catch (_error) {
      secureLocalStorage.removeItem(storageKey);
    }
  }, [state, storageKey]);

  const loading = loadable.state === "loading";

  return { state, loading };
};
