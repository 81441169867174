import type {
  Action,
  AmountExecutiveB,
  AmountExecutiveR,
  ClientFinderSearchR,
  ContractDetails,
  FlowContractDetailsR,
  FlowsByStateR,
  ManagementExecutiveB,
  ManagementExecutiveR,
  OperatingFlowR,
  OperatingFlowsR,
  PromisesExecutiveB,
  PromisesExecutiveR,
  SelectsMetricsReportR,
} from "@/types";
import {
  getSelectsMetricsReportUrl,
  postAmountExecutiveUrl,
  postManagementExecutiveUrl,
  postPromisesExecutiveUrl,
} from "@/utils/endpoints";
import { get, post } from "@/utils/fetchApi";
import dayjs from "dayjs";
import { atom as jotaiAtom } from "jotai";
import { atom, atomFamily, selector } from "recoil";
import { strategyFilterState } from "./home";
import { headersJsonState, userState } from "./login";

export const flowsByStateRowsState = atomFamily<
  FlowsByStateR[],
  { id: string }
>({
  key: "flowsByStateFlowActiveAssignedFlow",
  default: [],
});

export const selectedFlowContractDetailsRowState = atomFamily<
  FlowContractDetailsR,
  { id: string }
>({
  key: "selectedFlowContractDetailsRowFlowActiveAssignedFlow",
  default: {
    id: "",
    contrato: "",
    cliente: 0,
    fechaFlujo: dayjs().format("YYYY-MM-DDTHH:mm:ss") as unknown as Date,
    codigoEtapa: "",
    nombreCliente: "",
    numeroTareas: 0,
    tiempo: 0,
    secuencial: 0,
    usuarioAsignado: "",
  },
});

export const selectedRowFlowsByStateState = atomFamily<
  FlowsByStateR,
  { id: string }
>({
  key: "selectedRowFlowsByStateFlowActiveAssignedFlow",
  default: {
    id: "",
    codigoFlujo: "",
    nombreFlujo: "",
    estado: "",
    cantidad: 0,
  },
});

export const rowsContractsState = atom<ClientFinderSearchR[]>({
  key: "rowsContractsCreateFlow",
  default: [],
});

export const selectedContractRowState = atom<ClientFinderSearchR>({
  key: "selectedContractRowCreateFlow",
  default: {
    contrato: "",
    cliente: "",
    nombreCliente: "",
    identificacion: "",
    saldoActual: 0,
    saldoMoroso: 0,
    diasAtraso: "",
    producto: "",
    estado1: "",
    estado2: "",
    tipoCliente: "",
    telefono: "",
    activa: false,
  },
});

export const selectedFlowDetailRowState = atom<OperatingFlowR>({
  key: "selectedFlowDetailRowCreateFlow",
  default: {
    id: "",
    codigoFlujo: "",
    nombreFlujo: "",
    contrato: "",
    fecha: "",
    usuario: "",
    usuarioAsignado: "",
    estado: "",
  },
});

export const selectedFilterState = jotaiAtom<string>("");

export const selectedIntegrationState = jotaiAtom<string>("");

export const selectedValueState = atom<OperatingFlowsR>({
  key: "selectedValueFlowMaintenance",
  default: {
    flowCode: "",
    operationCode: "",
    flowName: "",
    stageNumber: 0,
    group: "",
    sequential: 0,
  },
});

export const tasksListState = atom<
  {
    dataType: number;
    taskName: string;
    id: number;
  }[]
>({
  key: "tasksListFlowMaintenance",
  default: [],
});

export const selectionChannelState = atom<string>({
  key: "selectionChannel",
  default: "",
});

export const managementExecutiveState = atom<ManagementExecutiveR[]>({
  key: "managementExecutive",
  default: selector({
    key: "managementExecutiveSelector",
    get: async ({ get: recoilGet }) => {
      const headersJson = recoilGet(headersJsonState);
      const strategyFilter = recoilGet(strategyFilterState);
      const user = recoilGet(userState);
      const body = await post<ManagementExecutiveR[], ManagementExecutiveB>({
        url: postManagementExecutiveUrl(),
        headers: headersJson,
        body: {
          username: user.userName,
          year: strategyFilter.year,
          month: strategyFilter.month,
          day: strategyFilter.day,
        },
      });
      return body ?? [];
    },
  }),
});

export const promisesExecutiveState = atom<PromisesExecutiveR[]>({
  key: "promisesExecutive",
  default: selector({
    key: "promisesExecutiveSelector",
    get: async ({ get: recoilGet }) => {
      const headersJson = recoilGet(headersJsonState);
      const strategyFilter = recoilGet(strategyFilterState);
      const user = recoilGet(userState);
      const body = await post<PromisesExecutiveR[], PromisesExecutiveB>({
        url: postPromisesExecutiveUrl(),
        headers: headersJson,
        body: {
          username: user.userName,
          year: strategyFilter.year,
          month: strategyFilter.month,
          day: strategyFilter.day,
        },
      });
      return body ?? [];
    },
  }),
});

export const amountExecutiveState = atom<AmountExecutiveR[]>({
  key: "amountExecutive",
  default: selector({
    key: "amountExecutiveSelector",
    get: async ({ get: recoilGet }) => {
      const headersJson = recoilGet(headersJsonState);
      const strategyFilter = recoilGet(strategyFilterState);
      const user = recoilGet(userState);
      const body = await post<AmountExecutiveR[], AmountExecutiveB>({
        url: postAmountExecutiveUrl(),
        headers: headersJson,
        body: {
          username: user.userName,
          year: strategyFilter.year,
          month: strategyFilter.month,
          day: strategyFilter.day,
        },
      });
      return body ?? [];
    },
  }),
});

export const loadingState = atom({
  key: "loadingMetricsReport",
  default: false,
});

export const selectsReportsState = selector({
  key: "selectsReportsMetricsReport",
  get: async ({ get: recoilGet }) => {
    const headersJson = recoilGet(headersJsonState);
    const body = await get<SelectsMetricsReportR[]>({
      url: getSelectsMetricsReportUrl(),
      headers: headersJson,
    });
    return body ?? [];
  },
});

export const visualizeButtonCounterState = atom({
  key: "visualizeButtonCounterMetricsReport",
  default: 0,
});

export const openDialogState = atomFamily<
  boolean,
  {
    id: string;
  }
>({
  key: "openDialogCommon",
  default: false,
});

export const flowOptionsValueState = atomFamily<
  number,
  {
    id: string;
  }
>({
  key: "flowOptionsValueActiveAssignedFlow",
  default: 0,
});

export const selectedContractDetailsState = atom<
  ContractDetails & { action?: Action }
>({
  key: "selectedRowContractDetails",
  default: {
    producto: "",
    subproducto: "",
    contrato: "",
    saldoActual: "",
    diasMorosidad: "",
    estado: "",
    formaPago: "",
    saldoMorosidad: "",
    existeGarantia: false,
    cliente: 0,
    action: "",
  },
});

export const closeContentState = atomFamily<
  boolean,
  {
    id: string;
  }
>({
  key: "closeContentCommon",
  default: false,
});


export const enableSegmentationV2State = atom<boolean>({
  key: "enableSegmentationV2State",
  default: false,
});