import { checkUnauthorized } from "@/common/utils/checkUnauthorized";
import { invalidateQuery } from "@/common/utils/invalidate";
import { addNotification } from "@/common/utils/notify";
import { saveJwtToken } from "@/common/utils/saveJwT";
import {
  useAppVariablesServiceGetAppVariablesByTypeAndUserId,
  useAppVariablesServiceGetAppVariablesByTypeAndUserIdKey,
  useAppVariablesServiceGetAppVariablesKey,
  useAppVariablesServicePutAppVariable,
} from "@/queries";
import { userAtomState as userState } from "@/states/login";
import type { AppVariableType, AppVariablesWithAction } from "@/types";
import type { AppVariableManipulationProps } from "@/types/props";
import { captureException } from "@sentry/react";
import { useQueryClient } from "@tanstack/react-query";
import { useAtomValue } from "jotai";
import { useCallback } from "react";
import type { SubmitHandler } from "react-hook-form";
import { redirect } from "react-router-dom";

export const useAppVariablesByTypeAndUserId = (
  type: AppVariableType,
  userid: number,
) => {
  const user = useAtomValue(userState);
  const { data } = useAppVariablesServiceGetAppVariablesByTypeAndUserId({
    type,
    userid,
    xDbCatalog: user.dbCatalog,
  });
  return { data };
};

const useAppVariableUpdateService = () => {
  const queryClient = useQueryClient();
  return useAppVariablesServicePutAppVariable({
    onSuccess: (response) => {
      saveJwtToken(response);
      invalidateQuery(queryClient, [useAppVariablesServiceGetAppVariablesKey]);
    },
    onError: (error) => {
      if (checkUnauthorized(error)) {
        redirect("/");
      } else {
        addNotification({
          messageType: "error",
          action: "edit",
          item: "appVariable",
          context: "female",
        });
        captureException(error);
      }
    },
  });
};

export const useEditAppVariables = (_params: AppVariableManipulationProps) => {
  const queryClient = useQueryClient();
  const { mutate, isPending } = useAppVariableUpdateService();
  const user = useAtomValue(userState);

  const mutateEdit = useCallback<SubmitHandler<AppVariablesWithAction[]>>(
    async (data) => {
      const mutationPromises = data.map((variable) => {
        return mutate({
          id: variable.id as number,
          xDbCatalog: user.dbCatalog,
          requestBody: {
            variableName: variable.variableName ?? "",
            variableValue: variable.variableValue ?? "",
            variableType: variable.variableType ?? "",
            userId: variable.userId ?? 0,
          },
        });
      });

      try {
        await Promise.all(mutationPromises);
        invalidateQuery(queryClient, [
          useAppVariablesServiceGetAppVariablesByTypeAndUserIdKey,
        ]);
        //TODO: Check later
        // reset();
      } catch (_error) {
        addNotification({
          messageType: "error",
          action: "edit",
          item: "appVariable",
          context: "female",
        });
      }
    },
    [mutate, user.dbCatalog, queryClient],
  );

  return { mutateEdit, isPending };
};
