import {
  currencyFormatState,
  currencyLocaleState,
} from "@/states/localeString";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";

export function useLocaleString() {
  const currencyFormat = useRecoilValue(currencyFormatState);
  const currencyLocale = useRecoilValue(currencyLocaleState);
  const { i18n } = useTranslation();

  const formatLocaleString = (num: number | null) => {
    if (num === null) {
      return "";
    }

    return num.toLocaleString(
      `${i18n.language.split("-")[0]}-${currencyLocale.value}`,
      {
        style: "currency",
        currency: currencyFormat,
      },
    );
  };

  return {
    formatLocaleString,
  };
}

export function useLocaleNumberString() {
  const { i18n } = useTranslation();
  const currencyLocale = useRecoilValue(currencyLocaleState);

  const formatLocaleNumberString = (num: number | null) => {
    if (num === null) {
      return "";
    }

    return num.toLocaleString(
      `${i18n.language.split("-")[0]}-${currencyLocale.value}`,
    );
  };

  return {
    formatLocaleNumberString,
  };
}
