import secureLocalStorage from "react-secure-storage";
import type { AtomEffect } from "recoil";

export const localStorageEffect: <T>(key: string) => AtomEffect<T> =
  (key: string) =>
  ({ setSelf, onSet }) => {
    const savedValue = secureLocalStorage.getItem(key);
    if (savedValue != null) {
      try {
        setSelf(JSON.parse(savedValue as string));
      } catch (_error) {
        setSelf(null as never);
      }
    }

    onSet((newValue) => {
      try {
        secureLocalStorage.setItem(key, JSON.stringify(newValue));
      } catch (_error) {
        secureLocalStorage.removeItem(key);
      }
    });
  };
