import { selectedAssignedStrategyListState } from "@/states/assignedStrategies";
import { isFirstRenderState, rolePermissionsRowsState } from "@/states/common";
import { scrollTopState } from "@/states/layout";
import {
  closeContentState,
  flowOptionsValueState,
  flowsByStateRowsState,
  loadingState,
  openDialogState,
  rowsContractsState,
  selectedContractDetailsState,
  selectedContractRowState,
  selectedFilterState,
  selectedFlowContractDetailsRowState,
  selectedFlowDetailRowState,
  selectedIntegrationState,
  selectedRowFlowsByStateState,
  selectedValueState,
  selectionChannelState,
  selectsReportsState,
  tasksListState,
  visualizeButtonCounterState,
  enableSegmentationV2State
} from "@/states/pages";
import type { RolesPermissionsR } from "@/types";
import { useLazyRecoilValue } from "@/utils/recoil";
import { useAtomValue } from "jotai";
import { useEffect } from "react";
import secureLocalStorage from "react-secure-storage";
import { useRecoilState, useRecoilValue, useRecoilValueLoadable } from "recoil";
import { useBreakpoints } from "./useBreakpoints";

export const useActiveAssignFlows = ({ id }: { id: string }) => {
  const selectedFlowContractDetailsRow = useRecoilValue(
    selectedFlowContractDetailsRowState({ id }),
  );
  const selectedRowFlowByState = useRecoilValue(
    selectedRowFlowsByStateState({ id }),
  );
  const flowsByStateRows = useRecoilValue(flowsByStateRowsState({ id }));

  return {
    selectedFlowContractDetailsRow,
    selectedRowFlowByState,
    flowsByStateRows,
  };
};

export const useActiveAssignFlowJuridical = () => {
  const [isFirstRender, setIsFirstRender] = useRecoilState(
    isFirstRenderState({ id: "activeAssignFlowJuridical" }),
  );
  const { state: permissions, loading: loadingPermissions } =
    useLazyRecoilValue([], rolePermissionsRowsState, "rolePermissionsRowsKey");

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    }
  }, [isFirstRender, setIsFirstRender]);

  const loadingPermission = loadingPermissions && isFirstRender;
  const hasViewPermission = permissions.some(
    (permission) => permission?.permissionId === 43,
  );

  return {
    loadingPermission,
    hasViewPermission,
  };
};

export const useActiveAssignFlow = () => {
  const [isFirstRender, setIsFirstRender] = useRecoilState(
    isFirstRenderState({ id: "activeAssignFlow" }),
  );
  const { state: permissions, loading: loadingPermissions } =
    useLazyRecoilValue([], rolePermissionsRowsState, "rolePermissionsRowsKey");

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    }
  }, [isFirstRender, setIsFirstRender]);

  const loadingPermission = loadingPermissions && isFirstRender;
  const hasViewPermission = permissions.some(
    (permission) => permission?.permissionId === 44,
  );

  return {
    loadingPermission,
    hasViewPermission,
  };
};

export const useStrategies = () => {
  const [isFirstRender, setIsFirstRender] = useRecoilState(
    isFirstRenderState({ id: "strategies" }),
  );
  const { state: permissions, loading: loadingPermissions } =
    useLazyRecoilValue([], rolePermissionsRowsState, "rolePermissionsRowsKey");

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    }
  }, [isFirstRender, setIsFirstRender]);

  const loadingPermission = loadingPermissions && isFirstRender;
  const hasViewPermission = permissions.some(
    (permission) => permission?.permissionId === 40,
  );

  return {
    loadingPermission,
    hasViewPermission,
  };
};

export const useAssignedStrategies = () => {
  const selectedList = useRecoilValue(selectedAssignedStrategyListState);
  const [isFirstRender, setIsFirstRender] = useRecoilState(
    isFirstRenderState({ id: "assignedStrategies" }),
  );
  const { state: permissions, loading: loadingPermissions } =
    useLazyRecoilValue([], rolePermissionsRowsState, "rolePermissionsRowsKey");

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    }
  }, [isFirstRender, setIsFirstRender]);

  const loadingPermission = loadingPermissions && isFirstRender;
  const hasViewPermission = permissions.some(
    (permission) => permission?.permissionId === 41,
  );

  return {
    selectedList,
    loadingPermission,
    hasViewPermission,
  };
};

export const useMarkClient = () => {
  const [isFirstRender, setIsFirstRender] = useRecoilState(
    isFirstRenderState({ id: "markClient" }),
  );
  const { state: permissions, loading: loadingPermissions } =
    useLazyRecoilValue([], rolePermissionsRowsState, "rolePermissionsRowsKey");

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    }
  }, [isFirstRender, setIsFirstRender]);

  const loadingPermission = loadingPermissions && isFirstRender;
  const hasViewPermission = permissions.some(
    (permission) => permission?.permissionId === 42,
  );

  return {
    loadingPermission,
    hasViewPermission,
  };
};

export const useCatalogMaintenance = () => {
  const selectedFilter = useAtomValue(selectedFilterState);
  const [isFirstRender, setIsFirstRender] = useRecoilState(
    isFirstRenderState({ id: "catalogMaintenance" }),
  );
  const { state: permissions, loading: loadingPermissions } =
    useLazyRecoilValue([], rolePermissionsRowsState, "rolePermissionsRowsKey");

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    }
  }, [isFirstRender, setIsFirstRender]);

  const loadingPermission = loadingPermissions && isFirstRender;
  const hasViewPermission = permissions.some(
    (permission) => permission?.permissionId === 50,
  );

  return {
    selectedFilter,
    loadingPermission,
    hasViewPermission,
  };
};

export const useIntegrationMaintenance = () => {
  const selectedIntegration = useAtomValue(selectedIntegrationState);
  const [isFirstRender, setIsFirstRender] = useRecoilState(
    isFirstRenderState({ id: "integrationMaintenance" }),
  );
  const { state: permissions, loading: loadingPermissions } =
    useLazyRecoilValue([], rolePermissionsRowsState, "rolePermissionsRowsKey");

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    }
  }, [isFirstRender, setIsFirstRender]);

  const loadingPermission = loadingPermissions && isFirstRender;
  const hasViewPermission = permissions.some(
    (permission) => permission?.permissionId === 79,
  );

  return {
    selectedIntegration,
    loadingPermission,
    hasViewPermission,
  };
};

export const usePauseMaintenance = () => {
  const [isFirstRender, setIsFirstRender] = useRecoilState(
    isFirstRenderState({ id: "pauseMaintenance" }),
  );
  const { state: permissions, loading: loadingPermissions } =
    useLazyRecoilValue([], rolePermissionsRowsState, "rolePermissionsRowsKey");

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    }
  }, [isFirstRender, setIsFirstRender]);

  const loadingPermission = loadingPermissions && isFirstRender;
  const hasViewPermission = permissions.some(
    (permission) => permission?.permissionId === 51,
  );

  return {
    loadingPermission,
    hasViewPermission,
  };
};

export const useHolidayMaintenance = () => {
  const [isFirstRender, setIsFirstRender] = useRecoilState(
    isFirstRenderState({ id: "holidayMaintenance" }),
  );
  const { state: permissions, loading: loadingPermissions } =
    useLazyRecoilValue([], rolePermissionsRowsState, "rolePermissionsRowsKey");

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    }
  }, [isFirstRender, setIsFirstRender]);

  const loadingPermission = loadingPermissions && isFirstRender;
  const hasViewPermission = permissions.some(
    (permission) => permission?.permissionId === 52,
  );

  return {
    loadingPermission,
    hasViewPermission,
  };
};

export const useCreateFlow = () => {
  const selectedFlowDetailRow = useRecoilValue(selectedFlowDetailRowState);
  const selectedContractRow = useRecoilValue(selectedContractRowState);
  const contracts = useRecoilValue(rowsContractsState);
  const [isFirstRender, setIsFirstRender] = useRecoilState(
    isFirstRenderState({ id: "createFlow" }),
  );
  const { state: permissions, loading: loadingPermissions } =
    useLazyRecoilValue([], rolePermissionsRowsState, "rolePermissionsRowsKey");

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    }
  }, [isFirstRender, setIsFirstRender]);

  const loadingPermission = loadingPermissions && isFirstRender;
  const hasViewPermission = permissions.some(
    (permission) => permission?.permissionId === 45,
  );

  return {
    selectedFlowDetailRow,
    selectedContractRow,
    contracts,
    loadingPermission,
    hasViewPermission,
  };
};

export const useFlowMaintenance = () => {
  const selectedValue = useRecoilValue(selectedValueState);
  const tasksList = useRecoilValue(tasksListState);
  const [isFirstRender, setIsFirstRender] = useRecoilState(
    isFirstRenderState({ id: "flowMaintenance" }),
  );
  const { state: permissions, loading: loadingPermissions } =
    useLazyRecoilValue([], rolePermissionsRowsState, "rolePermissionsRowsKey");

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    }
  }, [isFirstRender, setIsFirstRender]);

  const loadingPermission = loadingPermissions && isFirstRender;
  const hasViewPermission = permissions.some(
    (permission) => permission?.permissionId === 54,
  );
  return { selectedValue, tasksList, loadingPermission, hasViewPermission };
};

export const useGroupMaintenance = () => {
  const [isFirstRender, setIsFirstRender] = useRecoilState(
    isFirstRenderState({ id: "groupMaintenance" }),
  );
  const { state: permissions, loading: loadingPermissions } =
    useLazyRecoilValue([], rolePermissionsRowsState, "rolePermissionsRowsKey");

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    }
  }, [isFirstRender, setIsFirstRender]);

  const loadingPermission = loadingPermissions && isFirstRender;
  const hasViewPermission = permissions.some(
    (permission) => permission?.permissionId === 55,
  );

  return {
    loadingPermission,
    hasViewPermission,
  };
};

export const useFormatMaintenace = () => {
  const selectedChannel = useRecoilValue(selectionChannelState);
  const showDynamicFieldDataGrid = useRecoilValue(
    openDialogState({ id: "showDataGridDynamicDataFields" }),
  );
  const [isFirstRender, setIsFirstRender] = useRecoilState(
    isFirstRenderState({ id: "formateMaintenance" }),
  );
  const { state: permissions, loading: loadingPermissions } =
    useLazyRecoilValue([], rolePermissionsRowsState, "rolePermissionsRowsKey");

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    }
  }, [isFirstRender, setIsFirstRender]);

  const loadingPermission = loadingPermissions && isFirstRender;
  const hasViewPermission = permissions.some(
    (permission) => permission?.permissionId === 49,
  );

  return {
    selectedChannel,
    showDynamicFieldDataGrid,
    loadingPermission,
    hasViewPermission,
  };
};
export const useMetricsReports = () => {
  const { loading: loadingSelectsReports } = useLazyRecoilValue(
    [],
    selectsReportsState,
    "selectsMetricsReportKey",
  );
  const loadingData = useRecoilValue(loadingState);
  const visualizeButtonCounter = useRecoilValue(visualizeButtonCounterState);
  const [isFirstRender, setIsFirstRender] = useRecoilState(
    isFirstRenderState({ id: "metricsReport" }),
  );
  const { state: permissions, loading: loadingPermissions } =
    useLazyRecoilValue([], rolePermissionsRowsState, "rolePermissionsRowsKey");

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    }
  }, [isFirstRender, setIsFirstRender]);

  const loadingPermission = loadingPermissions && isFirstRender;
  const hasViewPermission = permissions.some(
    (permission) => permission?.permissionId === 46,
  );

  return {
    loadingData,
    loadingSelectsReports,
    visualizeButtonCounter,
    loadingPermission,
    hasViewPermission,
  };
};

export const useMetricsDashboard = () => {
  const [isFirstRender, setIsFirstRender] = useRecoilState(
    isFirstRenderState({ id: "metricsDashboard" }),
  );
  const { state: permissions, loading: loadingPermissions } =
    useLazyRecoilValue([], rolePermissionsRowsState, "rolePermissionsRowsKey");

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    }
  }, [isFirstRender, setIsFirstRender]);

  const loadingPermission = loadingPermissions && isFirstRender;
  const hasViewPermission = permissions.some(
    (permission) => permission?.permissionId === 47,
  );

  return {
    loadingPermission,
    hasViewPermission,
  };
};

export const useStrategyMaintenance = () => {
  const showDynamicFieldDataGrid = useRecoilValue(
    openDialogState({ id: "showDataGridDynamicDataFields" }),
  );
  const showFormats = useRecoilValue(
    openDialogState({ id: "showDataGridFormatsStrategyMaintenance" }),
  );
  const [isFirstRender, setIsFirstRender] = useRecoilState(
    isFirstRenderState({ id: "strategyMaintenance" }),
  );
  const { state: permissions, loading: loadingPermissions } =
    useLazyRecoilValue([], rolePermissionsRowsState, "rolePermissionsRowsKey");
    const enableSegmentationV2 = useRecoilValue(
      enableSegmentationV2State,
    );

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    }
  }, [isFirstRender, setIsFirstRender]);

  const loadingPermission = loadingPermissions && isFirstRender;
  const hasViewPermission = permissions.some(
    (permission) => permission?.permissionId === 48,
  );
  return {
    showDynamicFieldDataGrid,
    showFormats,
    loadingPermission,
    hasViewPermission,
    enableSegmentationV2
  };
};

export const useSwipeableViews = ({ id }: { id: string }) => {
  const flowOptionsValue = useRecoilValue(flowOptionsValueState({ id }));
  const selectedFlowContractDetailsRow = useRecoilValue(
    selectedFlowContractDetailsRowState({ id }),
  );

  return {
    flowOptionsValue,
    selectedFlowContractDetailsRow,
  };
};

export const useClientManagement = () => {
  const scrollTop = useRecoilValue(scrollTopState);
  const trigger = scrollTop > 50;
  const [showWaChatBot, setShowWaChatBot] = useRecoilState(
    closeContentState({ id: "waChatBot" }),
  );
  const selectedContract = useRecoilValue(selectedContractDetailsState);
  const showPaymentsDataGrid = useRecoilValue(
    openDialogState({ id: "showDataGridPayments" }),
  );
  const showGuaranteesDataGrid = useRecoilValue(
    openDialogState({ id: "showDataGridGuarantees" }),
  );
  const showQuotesDataGrid = useRecoilValue(
    openDialogState({ id: "showDataGridQuotes" }),
  );
  const showDebtDistributionDataGrid = useRecoilValue(
    openDialogState({ id: "showDataGridDebtDistribution" }),
  );
  const showOrderItemsDataGrid = useRecoilValue(
    openDialogState({ id: "showDataGridOrderItems" }),
  );
  const showCardAccountStatement = useRecoilValue(
    openDialogState({ id: "showCardAccountStatement" }),
  );
  const showArticlesCard = useRecoilValue(
    openDialogState({ id: "showCardArticles" }),
  );
  const showMonthlyPaymentsCard = useRecoilValue(
    openDialogState({ id: "showCardMonthlyPayments" }),
  );
  const showExplanationsCard = useRecoilValue(
    openDialogState({ id: "showCardExplanations" }),
  );
  const showAddendumCard = useRecoilValue(
    openDialogState({ id: "showCardAddendum" }),
  );
  const [isFirstRender, setIsFirstRender] = useRecoilState(
    isFirstRenderState({ id: "clientManagement" }),
  );
  const { state: permissions, loading: loadingPermissions } =
    useLazyRecoilValue([], rolePermissionsRowsState, "rolePermissionsRowsKey");

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    }
  }, [isFirstRender, setIsFirstRender]);

  const loadingPermission = loadingPermissions && isFirstRender;

  const handleShowWaChatBot = () => {
    setShowWaChatBot((prev) => !prev);
  };

  const { matches } = useBreakpoints({
    breakpoint: "lg",
  });

  const hasViewPermission = permissions.some(
    (permission) => permission?.permissionId === 4,
  );

  const hasViewPermissionContractDetails = permissions.some(
    (permission) => permission?.permissionId === 64,
  );

  const hasViewPermissionContractData = permissions.some(
    (permission) => permission?.permissionId === 65,
  );

  const hadQuanticButtonsViewPermission = permissions.some(
    (permission) => permission.permissionId === 77,
  );

  return {
    trigger,
    selectedContract,
    showWaChatBot,
    handleShowWaChatBot,
    showGuaranteesDataGrid,
    showQuotesDataGrid,
    showPaymentsDataGrid,
    showDebtDistributionDataGrid,
    showOrderItemsDataGrid,
    showCardAccountStatement,
    showArticlesCard,
    showMonthlyPaymentsCard,
    showExplanationsCard,
    showAddendumCard,
    matches,
    hasViewPermission,
    loadingPermission,
    hasViewPermissionContractDetails,
    hasViewPermissionContractData,
    hadQuanticButtonsViewPermission,
  };
};

export const useExecutiveMaintenance = () => {
  const showRoleEditor = useRecoilValue(
    closeContentState({ id: "showRoleEditorExecutiveMaintenance" }),
  );
  const [isFirstRender, setIsFirstRender] = useRecoilState(
    isFirstRenderState({ id: "executiveMaintenance" }),
  );
  const { state: permissions, loading: loadingPermissions } =
    useLazyRecoilValue([], rolePermissionsRowsState, "rolePermissionsRowsKey");

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    }
  }, [isFirstRender, setIsFirstRender]);

  const loadingPermission = loadingPermissions && isFirstRender;
  const hasViewPermission = permissions.some(
    (permission) => permission?.permissionId === 53,
  );

  return {
    showRoleEditor,
    loadingPermission,
    hasViewPermission,
  };
};

export const useHome = () => {
  const permissionsState = useRecoilValueLoadable(rolePermissionsRowsState);

  const loadingPermission = permissionsState.state === "loading";

  let permissions = [] as RolesPermissionsR[];

  if (permissionsState.state === "hasValue") {
    permissions = permissionsState.contents;
    secureLocalStorage.setItem(
      "rolePermissionsRowsKey",
      JSON.stringify(permissions),
    );
  }

  const hasViewPermission = permissions.some(
    (permission) => permission?.permissionId === 1,
  );

  return {
    hasViewPermission,
    loadingPermission,
  };
};

export const useClientFinder = () => {
  const [isFirstRender, setIsFirstRender] = useRecoilState(
    isFirstRenderState({ id: "clientFinder" }),
  );
  const { state: permissions, loading: loadingPermissions } =
    useLazyRecoilValue([], rolePermissionsRowsState, "rolePermissionsRowsKey");

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    }
  }, [isFirstRender, setIsFirstRender]);

  const loadingPermission = loadingPermissions && isFirstRender;

  const hasViewPermission = permissions.some(
    (permission) => permission?.permissionId === 4,
  );

  return {
    hasViewPermission,
    loadingPermission,
  };
};

export const useFieldsMaintenance = () => {
  const [isFirstRender, setIsFirstRender] = useRecoilState(
    isFirstRenderState({ id: "fieldsMaintenance" }),
  );
  const { state: permissions, loading: loadingPermissions } =
    useLazyRecoilValue([], rolePermissionsRowsState, "rolePermissionsRowsKey");

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    }
  }, [isFirstRender, setIsFirstRender]);

  const loadingPermission = loadingPermissions && isFirstRender;
  const hasViewPermission = permissions.some(
    (permission) => permission?.permissionId === 63,
  );

  return {
    loadingPermission,
    hasViewPermission,
  };
};
