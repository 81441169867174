export const apiPrefix = import.meta.env.API_URL ?? "";
const companiesJson =
  import.meta.env.VITE_COMPANY_CATALOG_JSON ??
  '[{"company": "Green Tree Consulting S.A.", "catalog": "paymedb"}]';

export let apiRoute = "api/v1/";

export const fuente = "Cliente";
export const source = "Cliente";

// Parse the companies JSON string
const companies = JSON.parse(companiesJson) as {
  company: string;
  catalog: string;
}[];

// Check if any company matches "Creditos Mundiales"
const companyMatchCm = companies.find(
  (company) => company.company === "Creditos Mundiales",
);

// If a match is found, use version "v1.1"
if (companyMatchCm) {
  apiRoute = "api/v1.1/";
}
