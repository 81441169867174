import { FallbackComponent } from "@/components/FallbackComponent";
import { drawerWidth } from "@/config/theme";
import { useTopBar } from "@/hooks/useTopBar";
import reactLazyWithRetry from "@fatso83/retry-dynamic-import/react-lazy";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import Slide from "@mui/material/Slide/Slide";
import Toolbar from "@mui/material/Toolbar";
import { ErrorBoundary, withProfiler } from "@sentry/react";
import { Suspense, memo } from "react";

const Breadcrumbs = reactLazyWithRetry(async () => {
  return {
    default: (await import("./Breadcrumbs")).Breadcrumbs,
  };
});

const Form = reactLazyWithRetry(async () => {
  return {
    default: (await import("./Form")).Form,
  };
});

const Menu = reactLazyWithRetry(async () => {
  return {
    default: (await import("./Menu")).Menu,
  };
});

const Notifications = reactLazyWithRetry(async () => {
  return {
    default: (await import("./Notifications")).Notifications,
  };
});

const Language = reactLazyWithRetry(async () => {
  return {
    default: (await import("./Language")).Language,
  };
});

const User = reactLazyWithRetry(async () => {
  return {
    default: (await import("./User")).User,
  };
});

function Component() {
  const { trigger, showSidebar, isClientFinder } = useTopBar();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      <AppBar
        sx={(theme) => ({
          boxShadow: "none",
          backgroundColor:
            theme.palette.mode === "light" ? "primary.main" : "darkBlue2.main",
        })}
      >
        <Toolbar
          sx={{
            height: {
              xs: "auto",
              md: "40px",
            },
            display: "flex",
            justifyContent: "space-between",
            marginLeft: {
              xs: 0.5,
              md: `${showSidebar ? `${drawerWidth}px` : 1}`,
            },
            marginRight: {
              xs: 0.5,
              md: 1,
            },
            minHeight: {
              md: "auto",
            },
            marginTop: {
              md: 1.5,
            },
            marginBottom: {
              md: 1.5,
            },
            paddingLeft: {
              xs: 1.75,
              md: 2,
            },
            paddingRight: {
              xs: 1.75,
              md: 1.25,
            },
          }}
        >
          <Box display="flex" gap={2} alignItems="center" width="min-content">
            <ErrorBoundary
              beforeCapture={(scope) => {
                scope.setTag("component", "menu");
              }}
              fallback={FallbackComponent}
            >
              <Suspense fallback={<Skeleton width={32} height={32} />}>
                <Menu />
              </Suspense>
            </ErrorBoundary>
            <ErrorBoundary
              beforeCapture={(scope) => {
                scope.setTag("component", "breadcrumbs");
              }}
              fallback={FallbackComponent}
            >
              <Suspense
                fallback={
                  <Skeleton
                    width={"fill-available"}
                    height={40}
                    color="primary"
                  />
                }
              >
                <Breadcrumbs />
              </Suspense>
            </ErrorBoundary>
          </Box>
          <Paper
            sx={{
              display: "flex",
              gap: 3,
              borderRadius: 2.5,
              backgroundColor: "inherit",
            }}
            elevation={0}
          >
            {!isClientFinder && (
              <ErrorBoundary
                beforeCapture={(scope) => {
                  scope.setTag("component", "form");
                }}
                fallback={FallbackComponent}
              >
                <Suspense
                  fallback={
                    <Skeleton width="100%" height={40} color="primary" />
                  }
                >
                  <Form />
                </Suspense>
              </ErrorBoundary>
            )}
            <Box
              width="auto"
              justifyContent="flex-end"
              alignItems="center"
              color="white.main"
              sx={{
                borderRadius: "0.5rem",
                display: "flex",
              }}
            >
              <Box display="flex" alignItems="center" gap={2}>
                <ErrorBoundary
                  beforeCapture={(scope) => {
                    scope.setTag("component", "notifications");
                  }}
                  fallback={FallbackComponent}
                >
                  <Suspense fallback={<Skeleton width={32} height={32} />}>
                    <Notifications />
                  </Suspense>
                </ErrorBoundary>
                <ErrorBoundary
                  beforeCapture={(scope) => {
                    scope.setTag("component", "language");
                  }}
                  fallback={FallbackComponent}
                >
                  <Suspense fallback={<Skeleton width={32} height={32} />}>
                    <Language />
                  </Suspense>
                </ErrorBoundary>
                <ErrorBoundary
                  beforeCapture={(scope) => {
                    scope.setTag("component", "user");
                  }}
                  fallback={FallbackComponent}
                >
                  <Suspense fallback={<Skeleton width={32} height={32} />}>
                    <User />
                  </Suspense>
                </ErrorBoundary>
              </Box>
            </Box>
          </Paper>
        </Toolbar>
      </AppBar>
    </Slide>
  );
}

Component.displayName = "TopBar";
export const TopBar = withProfiler(memo(Component));
