import type { GetParams, HttpMethod, PostParams } from "@/types";
import { captureException } from "@sentry/react";
import { redirect } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

export class FetchError extends Error {
  public response: Response;
  public status: number;
  public cause?: string;

  constructor(
    response: Response,
    status: number,
    cause?: string,
    ...params: string[]
  ) {
    super(params.length > 0 ? params[0] : cause || "Unknown error");
    this.name = "FetchError";
    this.response = response;
    this.status = status;
    this.cause = cause;
  }
}

async function request<Tresponse>(
  url: string,
  config: RequestInit,
): Promise<Tresponse | null> {
  try {
    const response = await fetch(url, config);
    const responseHeaders = response.headers;
    const contentType = responseHeaders.get("Set-Authorization");
    if (contentType) {
      secureLocalStorage.setItem("jwtToken", JSON.stringify(contentType));
    }
    if (!response.ok) {
      if (response.status === 401) {
        redirect("/");
        return null;
      }
      const data = await response.text();
      let result = "";
      try {
        const parsedData = JSON.parse(data);
        result = JSON.stringify(parsedData);
      } catch (_error) {
        result = data;
      }
      const error = new FetchError(response, response.status, result);
      captureException(error, {
        extra: {
          status: response.status,
          statusText: response.statusText || "No status text",
          responseHeaders: response.headers,
          responseBody: result,
          url: response.url,
          requestBody: config.body,
          requestHeaders: config.headers,
          requestMethod: config.method,
        },
      });
      throw error;
    }

    return response.json() satisfies Promise<Tresponse>;
  } catch (_error) {
    return null;
  }
}

async function requestWithoutJsonResponse(
  url: string,
  config: RequestInit,
): Promise<Response> {
  const response = await fetch(url, config);
  return response;
}

export const get = <Tresponse,>({ url, headers }: GetParams) =>
  request<Tresponse>(url, {
    method: "GET" satisfies HttpMethod,
    headers: headers,
  });

export const post = <Tresponse, Tbody>({
  url,
  headers,
  body,
}: PostParams<Tbody>) =>
  request<Tresponse>(url, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(body),
  });

export const postFormData = <Tbody,>({
  url,
  headers,
  body,
}: PostParams<Tbody>) =>
  requestWithoutJsonResponse(url, {
    method: "POST",
    headers: headers,
    body: body as FormData,
  });

export const postWithoutJsonResponse = <Tbody,>({
  url,
  headers,
  body,
  isJson = true,
}: PostParams<Tbody>) =>
  requestWithoutJsonResponse(url, {
    method: "POST",
    headers: headers,
    body: isJson ? JSON.stringify(body) : (body as string),
  });

export const httpPut = <Tbody,>({
  url,
  headers,
  body,
  isJson = true,
}: PostParams<Tbody>) =>
  requestWithoutJsonResponse(url, {
    method: "PUT",
    headers: headers,
    body: isJson ? JSON.stringify(body) : (body as string),
  });

export const httpDelete = <Tbody,>({ url, headers }: PostParams<Tbody>) =>
  requestWithoutJsonResponse(url, {
    method: "DELETE",
    headers: headers,
  });
